import { addTextInsideShape } from "./handleTextInsideShape";
import { fetchLetterBoxCount } from "./fetchLetterBoxCounts";
import { getSummaryData } from "./getSummaryData";
import { getBusinessLocations } from "./getBusinessLocations";
import { handlePolygonEdit } from "./handlePolygonEdit";
import { PolygonMouseEvent } from "../types";
import { showPolygonActionsModal } from "./showPolygonActionsModal";

const circleCompleteHandler = (
  circle: any,
  dispatch: any,
  map: any,
  bounds: any
) => {
  let numSides = 32;
  let center = circle.getCenter();
  let radius = circle.getRadius();
  let points = [],
    degreeStep = 360 / numSides;
  for (let i = 0; i < numSides; i++) {
    let gpos = google.maps.geometry.spherical.computeOffset(
      center,
      radius,
      degreeStep * i
    );
    points.push({ lng: gpos.lng(), lat: gpos.lat() });
    bounds?.extend({ lng: gpos.lng(), lat: gpos.lat() });
  }
  // map.fitBounds(bounds);
  // Duplicate the last point to close the geojson ring
  points.push(points[0]);
  let polygon: any = new google.maps.Polygon({
    paths: points,
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
  });
  let newPolygonEncodedPath = google.maps.geometry.encoding.encodePath(
    polygon.getPath()
  );
  polygon.id = newPolygonEncodedPath;
  dispatch({ type: "showSummarBar", payload: true });
  // fetchLetterBoxCount(newPolygonEncodedPath, dispatch);

  // code for fetching & adding letterbox count inside polygon.
  fetchLetterBoxCount(newPolygonEncodedPath, dispatch, polygon.id)
    .then((response: any) => {
      if (response) {
        addTextInsideShape(
          polygon,
          center,
          map,
          response["Residential count"] + response["Business exact count"]
        );
      }
      return;
    })
    .catch((err) => console.log("error in fetching summary data", err));

  return polygon;
};

const drawCircleDisplayingRadius = (
  center: any,
  setCircleActive: any,
  map: any,
  reset: any,
  drawingManager: any,
  polygons: any[],
  dispatch: any,
  state: any
) => {
  let polygon: any;
  let bounds = new google.maps.LatLngBounds();

  let newMarker = new google.maps.Marker({
    position: { lat: -33.88, lng: 151.15 },
    label: "",
    map: map,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 0,
    },
  });

  const distanceBetweenPoints = (p1: any, p2: any) => {
    if (!p1 || !p2) {
      return 0;
    }
    let R = 6371;
    let dLat = ((p2.lat() - p1.lat()) * Math.PI) / 180;
    let dLon = ((p2.lng() - p1.lng()) * Math.PI) / 180;
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((p1.lat() * Math.PI) / 180) *
        Math.cos((p2.lat() * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return d;
  };

  map.setOptions({
    draggable: false,
  });

  let circle = new google.maps.Circle({
    fillColor: "#000",
    fillOpacity: 0.3,
    strokeWeight: 2,
    clickable: false,
    editable: false,
    map: map,
    radius: 1,
    center: center.latLng,
    zIndex: 1,
  });

  let onMouseMove = map.addListener("mousemove", function (border: any) {
    let radius = 1000 * distanceBetweenPoints(center.latLng, border.latLng);
    circle.setRadius(radius);
    newMarker.setPosition(circle.getCenter());
    if (radius > 1000) {
      let radiusKm = (radius / 1000).toFixed(2) + "km";
      newMarker.setLabel(radiusKm);
    } else {
      let radiusM = radius.toFixed() + "m";
      newMarker.setLabel(radiusM);
    }
  });

  google.maps.event.addListenerOnce(map, "mouseup", function () {
    google.maps.event.removeListener(onMouseMove);
    circle.setEditable(false);
    newMarker.setLabel("");
    reset();
    polygon = circleCompleteHandler(circle, dispatch, map, bounds);
    window.polygons.push(polygon);
    // polygon.setEditable(false);
    polygon.setMap(map);
    drawingManager.setDrawingMode(null);
    circle.setMap(null);
    setCircleActive(false);
    dispatch({ type: "setPolygons", payload: polygon });
    // dispatch({ type: "addPolygon", payload: polygon });

    polygon.addListener("click", () => {
      if (polygon.editable) {
        handlePolygonEdit(polygon, map, dispatch, state);
      }
      return;
    });

    polygon.addListener("contextmenu", (e: PolygonMouseEvent) => {
      showPolygonActionsModal(polygon, dispatch, state, e.latLng!, state.map);
    });

    let allPolygons: any = google.maps.geometry.encoding.encodePath(
      polygon.getPath()
    );
    polygons.forEach(async function (pol: any) {
      allPolygons += ",";
      allPolygons += google.maps.geometry.encoding.encodePath(pol.getPath());
    });

    getSummaryData(polygon, allPolygons, map, dispatch, state);
    getBusinessLocations(allPolygons, map, dispatch, state);
    // polygonCompleteHandler(polygon, map, drawingManager, dispatch);
  });
};

export default drawCircleDisplayingRadius;
