import { useEffect, useState } from "react";
import {
  Modal,
  Group,
  Box,
  Text,
  Checkbox,
  Button,
  Stack,
  ScrollArea,
  createStyles,
  Tabs,
  Divider,
  Paper,
  Flex,
  Anchor,
} from "@mantine/core";

interface Filter {
  title: string;
  options: string[];
}

type FilterList = Filter[];

interface FiltersMap {
  [category: string]: FilterList;
}

interface FilterBuilderModalProps {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = createStyles((theme) => ({

  filterselectorbutton: {

  },

  filterpanel: {
  },

  filterOptionsPage: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px",
    gap: "20px",
  },

  tabList: {
    alignSelf: 'stretch',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row', // Adjust if you prefer vertical layout
    alignItems: 'center',
    justifyContent: 'space-between', // Adjust based on layout direction
    padding: '10px',
    gap: '20px',
    color: '#000',
  },
  tab: {
    alignSelf: "stretch",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    gap: "20px",
  },

}));


const FilterBuilderModal: React.FC<FilterBuilderModalProps> = ({ opened, setOpened }) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [filters, setFilters] = useState<FiltersMap>({});
  const { classes } = useStyles();


  const getAvailableFilters = () => {
    const computedFilters = {
      "income and work": [
        {
          title: "Participation in the labour force",
          options: [
            "In the labour force",
            "Not in the labour force"
          ],
        },
        {
          title: "Employment Status",
          options: [
            "Employed",
            "Unemployed",
            "Not in the labour force"
          ],
        },
        {
          title: "Employment, hours worked",
          options: [
            "Full-time",
            "Part-time",
            "Not in the labour force"
          ],
        }
      ],
      "education and training": [
        {
          title: "Highest level of education",
          options: [
            "Primary school",
            "Secondary school",
            "Tertiary diploma",
            "Bachelor's degree",
            "Master's degree",
            "Doctorate or higher"
          ],
        },
        {
          title: "Currently enrolled",
          options: [
            "Yes",
            "No"
          ],
        },
        {
          title: "Field of Study",
          options: [
            "Arts and Humanities",
            "Business and Law",
            "Education",
            "Engineering",
            "Health",
            "Science and Mathematics"
          ],
        }
      ],
      "family and community": [
        {
          title: "Marital status",
          options: [
            "Never married",
            "Married",
            "Separated",
            "Divorced",
            "Widowed"
          ],
        },
        {
          title: "Number of children",
          options: [
            "None",
            "1 child",
            "2 children",
            "3 children",
            "4 or more children"
          ],
        },
        {
          title: "Community engagement",
          options: [
            "Volunteer work",
            "Group activities",
            "No engagement"
          ],
        }
      ],
      "health and wellbeing": [
        {
          title: "General health status",
          options: [
            "Excellent",
            "Very good",
            "Good",
            "Fair",
            "Poor"
          ],
        },
        {
          title: "Disability status",
          options: [
            "No reported disability",
            "Mild disability",
            "Moderate disability",
            "Severe disability"
          ],
        },
        {
          title: "Mental health status",
          options: [
            "Good",
            "Fair",
            "Poor"
          ],
        }
      ],
      "housing and environment": [
        {
          title: "Type of dwelling",
          options: [
            "Detached house",
            "Townhouse",
            "Apartment",
            "Other"
          ],
        },
        {
          title: "Housing affordability",
          options: [
            "Owner occupied",
            "Rented – affordable",
            "Rented – unaffordable"
          ],
        },
        {
          title: "Access to green spaces",
          options: [
            "High",
            "Moderate",
            "Low"
          ],
        }
      ]
    };

    // Simulate fetching data by just setting the state directly
    setFilters(computedFilters);
  };

  // Effect to fetch filters when the component mounts
  useEffect(() => {
    getAvailableFilters();
  }, []);

  // Don't render anything if the modal is not opened
  if (!opened) return null;

  const filterOptionsBuilder = (filter: Filter) => {
    return (
      <Flex direction="column" gap="md" style={{
        alignSelf: "stretch",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 10px",
        gap: "20px",
      }}>

        <Text
          style={{
            fontSize: "16px",
            fontWeight: 700,
            color: "black",
            fontFamily: "'DIN 2014'",
            lineHeight: 1.5, // Adjust based on your design
          }}
        > {filter.title} </Text>


        {/* Utilize CheckboxGroup for managing a group of related checkboxes */}
        <Checkbox.Group
          defaultValue={[]} // Set default checked checkboxes if needed <-- might be useful for pre-selecting filters
          style={{
            alignSelf: "stretch",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "10px 0px",
          }}
        >
          {filter.options.map((option, idx) => (
            <Checkbox key={idx} value={option} label={option} styles={
              {
                label: {
                  fontSize: "14px",
                  color: "black",
                  fontFamily: "'DIN 2014'",
                  lineHeight: 1.5,
                }
              }
            } />
          ))}
        </Checkbox.Group>
      </Flex>
    );
  };

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Filter Builder"
      size="52em"
      overlayProps={{
        blur: 1,
      }}

    >
      <Paper

        style={{
          alignSelf: "stretch",
          maxWidth: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          fontSize: "14px",
          color: "#fd7e14",
          fontFamily: "'DIN 2014'",
          lineHeight: 1.5, // Adjust based on your design
        }}
      >
        <ScrollArea style={{ flexGrow: 1 }}>
          <Tabs
            defaultValue="income and work"
            orientation="vertical"
            unstyled
            styles={{
              root: {
                display: 'flex',
                flexDirection: 'row',
              },

              tabsList: {
                alignSelf: 'stretch',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column', 
                gap: '5px', // Adjust the space between each tab

                borderRight: '1px solid #000', // Add a border to separate the tabs from the panel
              },
              tab: {
                // Base styles for each Tab
                alignSelf: 'stretch',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                padding: '10px 20px', // Adjust padding as needed
                border: 'none',
                backgroundColor: 'transparent',
                color: '#000', // Tab text color
                fontSize: '14px', // Adjust font size
                lineHeight: '155%',
                fontFamily: "'DIN 2014'", // Ensure you have loaded or defined this font

                '&:hover': {
                  backgroundColor: '#f0f0f0', // Hover background color
                },

                '&[data-active]': {
                  fontWeight: 'bold',
                  borderLeft: '2px solid #fd7e14', // Active tab indicator
                  backgroundColor: '#f3f3f3', // Active tab background color
                },

              },
              panel: {
                flex: '1', // Fill the available space
              },
            }}
          >
            <Tabs.List>
              {Object.keys(filters).map(category => (
                <Tabs.Tab value={category} key={category}>
                  {category}
                </Tabs.Tab>
              ))}
            </Tabs.List>

            {Object.entries(filters).map(([category, filterOptions]) => (
              <Tabs.Panel value={category} key={category} classNames={classes.filterOptionsPage}>
                <div
                  style={{
                    flex: "1",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "0px 25px 0px 40px",
                    boxSizing: "border-box",
                    gap: "10px 0px",
                    minWidth: "377px",
                    maxWidth: "100%",
                    fontSize: "20px",
                    color: "#000",
                  }}>

                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px 0px",
                      gap: "20px",
                    }}
                  >

                    <Text
                      style={{
                        margin: "0",
                        position: "relative",
                        fontSize: "inherit",
                        lineHeight: "155%",
                        fontWeight: "700",
                        fontFamily: "inherit",
                      }}
                      size="xl"
                      weight={700}
                    >
                      {category}
                    </Text>

                    <Button variant="subtle">
                      Clear
                    </Button>
                  </div>

                  {filterOptions.map((filter, index) => (
                    <div key={index}>
                      {filterOptionsBuilder(filter)}
                    </div>
                  ))}
                </div>
              </Tabs.Panel>
            ))}
          </Tabs>
        </ScrollArea>
      </Paper>
      <Divider my="xs" color="black" />
      <Group
        position="right"
        style={{
          alignSelf: "stretch",
          backgroundColor: "#fff",
          overflow: "hidden",
          padding: "10px 25px",
          minHeight: "70px",
        }}
      >
        <Button
          variant="outline"
          onClick={() => {
            setOpened(false);
          }}
        >
          Cancel
        </Button>
        <Button>Apply Filter</Button>
      </Group>
    </Modal>
  );
};

export default FilterBuilderModal;
