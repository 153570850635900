import {
  Badge,
  Grid,
  Text,
} from "@mantine/core";
import React, { useContext, useState } from "react";
import {
  ArrowNarrowDown,
  ArrowNarrowUp,
} from "tabler-icons-react";

import storeContext from "../store/store";

function ValueAndComparisonToAverage(props: any) {
  const { state } = useContext<any>(storeContext);
  const { title, value, stateAverageDiff, nationalAverageDiff, prefixChar, suffixChar } = props;
  const stateDiffPercentage = Math.round((value/(value - stateAverageDiff) - 1) * 100);
  const nationalDiffPercentage = Math.round((value/(value - nationalAverageDiff) - 1) * 100);

  return (
    <Grid.Col span={12}>
      <Grid>
        <Grid.Col span={6}>
          <Text size="sm">{title}</Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Grid justify="flex-end" m={0}>
            <Text weight={700}>{prefixChar}{value}{suffixChar}</Text>
            <span>

              {(state.benchmark === "state") && (
              <Badge
                variant="filled"
                sx={{
                  paddingLeft: 4,
                  marginLeft: 14,
                  lineHeight: "inherit",
                  // should be updated when actual value is available.
                  backgroundColor:
                    stateAverageDiff > 0
                      ? "#D3F9D8"
                      : "#FF653257",
                  color:
                  stateAverageDiff > 0
                      ? "#2B8A3E"
                      : "#FF6532",
                }}
                leftSection={
                  stateAverageDiff > 0 ? (
                    <ArrowNarrowUp
                      size={11}
                      style={{ display: "flex" }}
                      strokeWidth={2}
                      color="#2B8A3E"
                    />
                  ) : (
                    <ArrowNarrowDown
                      size={11}
                      style={{ display: "flex" }}
                      strokeWidth={2}
                      color="#FF6532"
                    />
                  )
                }
              >
                {stateDiffPercentage || 0}%
              </Badge>
              )}
              {(state.benchmark === "national") && (
              <Badge
                variant="filled"
                sx={{
                  paddingLeft: 4,
                  marginLeft: 14,
                  lineHeight: "inherit",
                  // should be updated when actual value is available.
                  backgroundColor:
                  nationalAverageDiff > 0
                      ? "#D3F9D8"
                      : "#FF653257",
                  color:
                  nationalAverageDiff > 0
                      ? "#2B8A3E"
                      : "#FF6532",
                }}
                leftSection={
                  nationalAverageDiff > 0 ? (
                    <ArrowNarrowUp
                      size={11}
                      style={{ display: "flex" }}
                      strokeWidth={2}
                      color="#2B8A3E"
                    />
                  ) : (
                    <ArrowNarrowDown
                      size={11}
                      style={{ display: "flex" }}
                      strokeWidth={2}
                      color="#FF6532"
                    />
                  )
                }
              >
                {nationalDiffPercentage || 0}%
              </Badge>
              )}
            </span>
          </Grid>
        </Grid.Col>
      </Grid>
    </Grid.Col>
);
}

export default ValueAndComparisonToAverage;
