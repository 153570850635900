export const setFilters = (
  mapPolygons: any,
  filterDataAlternative: any,
  dispatch: any
) => {
  let sa1_portions: any[] = [];
  let polygonsEnabled = 0;
  mapPolygons.forEach((p: any) => {
    p.selected = true;
    for (const [key, value] of Object.entries(filterDataAlternative)) {
      if(p.data[`${key}`] !== undefined && (p.data[`${key}`] < filterDataAlternative[`${key}`].minValue || p.data[`${key}`] > filterDataAlternative[`${key}`].maxValue))
        p.selected = false;
    }
    if (p.selected) {
      p.polygon.setOptions({ fillColor: "green" });
      sa1_portions.push([p["data"]["sa1_code"], p["intersected_percentage"]]);
      polygonsEnabled++;
    }
    else
      p.polygon.setOptions({ fillColor: "#2F4F4F" });
    p.polygon.setOptions({ fillOpacity: 0.7 });
  });
};
