import { Navbar, Title, Divider, Group, Button, Tooltip, Collapse, Grid, Col, Stack } from "@mantine/core";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import storeContext from "../store/store";
import { setFilters } from "../map-utils/setFilters";
import { polygonValidOrRemove } from "../map-utils/polygonValidator";

import SliderGroup from "./filterbar-utils/SliderGroup";
import { Language, TravelMethod } from "../types";
import useElementSize from "../hooks/useElementSize";
import { fetchLetterBoxCount } from "../map-utils/fetchLetterBoxCounts";
import { getSummaryData } from "../map-utils/getSummaryData";
import { getBusinessLocations } from "../map-utils/getBusinessLocations";
import { handlePolygonDelete } from "../map-utils/handlePolygonDelete";
import { addTextInsideShape } from "../map-utils/handleTextInsideShape";
import { ChevronRight, ChevronDown } from "tabler-icons-react";
import FilterBuilderModal from "./filterbar-utils/FilterBuilderModal";

type Props = {
  openFiltersBar?: boolean;
  openHeatMap?: boolean;
  disableApplyFilters?: boolean;
};
const FiltersBar: FC<Props> = ({ openFiltersBar, openHeatMap, disableApplyFilters }: Props) => {
  const { state, dispatch } = useContext<any>(storeContext);
  const [reloadHandlers, setReloadHandlers] = useState<boolean>(false);
  const { height } = useElementSize("header-main");
  const [isPeopleOpened, setPeopleOpen] = useState(true);
  const [isDwellOpened, setDwellOpen] = useState(true);
  const [isLangOpened, setLangOpen] = useState(true);
  const [isTravOpened, setTravOpen] = useState(true);
  const [tooManySA1s, setTooManySA1s] = useState(false);
  const [openFilterBuilderModal, setOpenFilterBuilderModal] = useState(false);
  const maximumNumberOfPolygonsToFilter = 30;

  useEffect(() => {
    if (openFiltersBar || openHeatMap) {
      setReloadHandlers(true);
    } else {
      setReloadHandlers(false);
    }
  }, [state.jobId, openFiltersBar, openHeatMap]);

  useEffect(() => {
    if (openFiltersBar && !openHeatMap)
      setFilters(state.mapPolygons, state.filterDataAlternative, dispatch);
  }, [state.filterDataVersion, openFiltersBar, openHeatMap, dispatch, state.mapPolygons]);

  useEffect(() => {
    let filteredPolygonsCount = 0;
    state.mapPolygons.forEach((p: any) => {
      if (p.selected)
        filteredPolygonsCount++;
    });
    if (filteredPolygonsCount > maximumNumberOfPolygonsToFilter)
      setTooManySA1s(true);
    else
      setTooManySA1s(false);
  }, [state.mapPolygons, state.filtersDataToSave]);

  useEffect(() => {
    if (reloadHandlers) {
      state?.mapPolygons?.forEach((p: any) => p.polygon.setMap(null));
      state?.polygons?.forEach((p: any) => {
        state?.mapPolygons.forEach((p: any) => p.polygon.setMap(state.map));
        p.setMap(null);
        p.setEditable(false);
      });
    } else {
      state?.mapPolygons.forEach((p: any) => p.polygon.setMap(null));
      state?.polygons.forEach((p: any) => {
        p.setEditable(false);
        p.setMap(state.map);
      });
    }
  }, [state.map, state.mapPolygons, state.polygons, reloadHandlers]);

  const resetFiltersHandler = async () => {
    document.querySelectorAll('input[type=checkbox]').forEach((el) => {
      const checkbox = el as HTMLInputElement;
      if (checkbox.checked)
        checkbox.click();
    });
    dispatch({
      type: "setFilterDataEntire",
      payload: {}
    });
  }

  const applyFiltersHandler = async () => {
    const accepted = window.confirm(`Are you sure you want to apply these filters, this action is irreversible.`);
    if (!accepted) return;
    state?.polygons?.forEach((p: any) => {
      handlePolygonDelete(p, dispatch, state, state.map);
    });
    const filteredPolygons = state.mapPolygons.filter((polygon: any) => polygon.selected);
    // const validFilteredPolygons = filteredPolygons.filter((polygon: any) => polygonValidOrRemove(polygon.polygon));
    const validFilteredPolygons = filteredPolygons;
    dispatch({ type: 'setPolygons', payload: null });
    let allPolygons: string = '';
    validFilteredPolygons.forEach((polygon: any) => {
      let gmPolygon = polygon.polygon;
      let path = gmPolygon.getPath();
      if(path === undefined)
        return;
      let encodedPolygon = google.maps.geometry.encoding.encodePath(gmPolygon.getPath());
      allPolygons += encodedPolygon + ',';
      gmPolygon.id = encodedPolygon;
      dispatch({ type: 'setPolygons', payload: gmPolygon });
      var polygonBounds = new google.maps.LatLngBounds();
      for (let i = 0; i < polygon.polygon.getPath().getLength(); i++) {
        polygonBounds.extend(polygon.polygon.getPath().getAt(i));
      }
      fetchLetterBoxCount(encodedPolygon, dispatch, encodedPolygon)
        .then((response: any) => {
          if (response) {
            addTextInsideShape(
              polygon.polygon,
              polygonBounds.getCenter(),
              state.map,
              response["Residential count"] + response["Business exact count"]
            );
          }
          return;
        })
        .catch((err) => console.log("error in fetching summary data", err));
    });
    const polygonsToDelete = state.mapPolygons.filter((polygon: any) => !polygon.selected);
    polygonsToDelete.forEach((polygon: any) => {
      polygon.polygon.setMap(null);
    });
    allPolygons = allPolygons.slice(0, -1)
    if (validFilteredPolygons.length) {
      getSummaryData(validFilteredPolygons[0].polygon, allPolygons, state.map, dispatch, state);
      getBusinessLocations(allPolygons, state.map, dispatch, state);
    }
    dispatch({ type: 'showSummarBar', payload: true });
  }

  function handleExport(): void {
    console.log('handleExport');
    dispatch({ type: "setViewExportMapModal", payload: { visible: true, type: "filter" } });
  }

  return (
    <div style={openFiltersBar ? {} : { display: "none" }}>

      <FilterBuilderModal
        opened={openFilterBuilderModal}
        setOpened={setOpenFilterBuilderModal}
      />

      <>
        <Navbar
          hiddenBreakpoint="sm"
          fixed={false}
          width={{ xs: 300 }}
          sx={(theme) => ({
            height: `calc(100vh  - ${height}px)`,
            top: `${height}px)`,
            overflowY: "auto",
            overflowX: "hidden",
            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
              position: "fixed",
              top: `${height}px !important`,
              maxWidth: "300px",
            },
            [`@media (max-width: 425px)`]: {
              maxWidth: "250px",
            },
          })}
        >
          {state.filterData.setCheck ? (
            <>
              <Grid grow>
                <Col span={6}>
                  <Title
                    order={6}
                    p="lg"
                    align="left"
                    style={{ textTransform: 'uppercase' }}>
                    Filters
                  </Title>
                </Col>

                <Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Tooltip
                    label="Currently in Beta"
                    position="right"
                    withArrow
                  >
                    <Button
                      variant="outline"
                      color="orange"
                      compact
                      type="submit"
                      onClick={() => handleExport()}>
                      Export
                    </Button>
                  </Tooltip>
                </Col>
              </Grid>
              <Divider />
              <Button onClick={() => setPeopleOpen(!isPeopleOpened)} variant="white"
                sx={(theme) => ({
                  color: theme.colors.allColors[7],
                  "&:hover": {
                    color: "#f76707",
                  },
                })}
              >
                {isPeopleOpened && <ChevronDown></ChevronDown>}
                {!isPeopleOpened && <ChevronRight></ChevronRight>}
                People
              </Button>

              <Collapse in={isPeopleOpened}>
                <SliderGroup

                  title="Median Age"
                  propertyName="medianAge"
                  groupName=""
                  sliderLabel={(value: any) => `${value}`}
                  defaultChecked={
                    state.filterDataAlternative["medianAge"] !== undefined
                    // state.appliedFilters.minMedianAge ||
                    // state.appliedFilters.maxMedianAge
                    //   ? true
                    //   : false
                  }
                  value={[
                    state.filterData.minMedianAge,
                    state.filterData.maxMedianAge,
                  ]}
                  changeValue={[
                    state.filterDataAlternative["medianAge"] !== undefined ? state.filterDataAlternative["medianAge"].minValue : state.filterData.minMedianAge,
                    state.filterDataAlternative["medianAge"] !== undefined ? state.filterDataAlternative["medianAge"].maxValue : state.filterData.maxMedianAge,
                  ]}
                  onChangeValue={(e: [number, number]) =>
                    dispatch({ type: "setMedianAge", payload: e })
                  }
                />
                <Divider />
                <SliderGroup
                  title="Weekly Household Income"
                  propertyName="income"
                  groupName=""
                  sliderLabel={(value: any) => `$ ${value}`}
                  defaultChecked={
                    state.filterDataAlternative["income"] !== undefined
                    // state.appliedFilters.minIncome ||
                    // state.appliedFilters.maxIncome
                    //   ? true
                    //   : false
                  }
                  value={[state.filterData.minIncome, state.filterData.maxIncome]}
                  changeValue={[
                    state.filterDataAlternative["income"] !== undefined ? state.filterDataAlternative["income"].minValue : state.filterData.minIncome,
                    state.filterDataAlternative["income"] !== undefined ? state.filterDataAlternative["income"].maxValue : state.filterData.maxIncome,
                  ]}
                  onChangeValue={(e: [number, number]) =>
                    dispatch({ type: "setIncome", payload: e })
                  }
                />
                <Divider />
                <SliderGroup
                  title="Unemployment"
                  propertyName="unemployed"
                  groupName=""
                  sliderLabel={(value: any) => `${value}%`}
                  defaultChecked={
                    state.filterDataAlternative["unemployed"] !== undefined
                    // state.appliedFilters.minUnemployed ||
                    // state.appliedFilters.maxUnemployed
                    //   ? true
                    //   : false
                  }
                  value={[
                    state.filterData.minUnemployed,
                    state.filterData.maxUnemployed,
                  ]}
                  changeValue={[
                    state.filterDataAlternative["unemployed"] !== undefined ? state.filterDataAlternative["unemployed"].minValue : state.filterData.minUnemployed,
                    state.filterDataAlternative["unemployed"] !== undefined ? state.filterDataAlternative["unemployed"].maxValue : state.filterData.maxUnemployed,
                  ]}
                  onChangeValue={(e: [number, number]) =>
                    dispatch({ type: "setUnemployed", payload: e })
                  }
                />
                <Divider />
                <SliderGroup
                  title="University Degree"
                  propertyName="uniDegree"
                  groupName=""
                  sliderLabel={(value: any) => `${value}%`}
                  defaultChecked={
                    state.filterDataAlternative["uniDegree"] !== undefined
                    // state.appliedFilters.minUniDegree ||
                    // state.appliedFilters.maxUniDegree
                    //   ? true
                    //   : false
                  }
                  value={[
                    state.filterData.minUniDegree,
                    state.filterData.maxUniDegree,
                  ]}
                  changeValue={[
                    state.filterDataAlternative["uniDegree"] !== undefined ? state.filterDataAlternative["uniDegree"].minValue : state.filterData.minUniDegree,
                    state.filterDataAlternative["uniDegree"] !== undefined ? state.filterDataAlternative["uniDegree"].maxValue : state.filterData.maxUniDegree,
                  ]}
                  onChangeValue={(e: [number, number]) =>
                    dispatch({ type: "setUniDegree", payload: e })
                  }
                />
                <Divider />
                <SliderGroup
                  title="Indigenous"
                  propertyName="indigenous"
                  groupName=""
                  sliderLabel={(value: any) => `${value}%`}
                  defaultChecked={
                    state.filterDataAlternative["indigenous"] !== undefined
                    // state.appliedFilters.minRentalProperties ||
                    // state.appliedFilters.maxRentalProperties
                    //   ? true
                    //   : false
                  }
                  value={[
                    state.filterData.minIndigenous,
                    state.filterData.maxIndigenous,
                  ]}
                  changeValue={[
                    state.filterDataAlternative["indigenous"] !== undefined ? state.filterDataAlternative["indigenous"].minValue : state.filterData.minIndigenous,
                    state.filterDataAlternative["indigenous"] !== undefined ? state.filterDataAlternative["indigenous"].maxValue : state.filterData.maxIndigenous,
                  ]}
                  onChangeValue={(e: [number, number]) =>
                    dispatch({ type: "setIndigenous", payload: e })
                  }

                />
              </Collapse>
              <Divider />
              <Button onClick={() => setDwellOpen(!isDwellOpened)} variant="white"
                sx={(theme) => ({
                  color: theme.colors.allColors[7],
                  "&:hover": {
                    color: "#f76707",
                  },
                })}
              >
                {isDwellOpened && <ChevronDown></ChevronDown>}
                {!isDwellOpened && <ChevronRight></ChevronRight>}
                Dwellings
              </Button>
              <Collapse in={isDwellOpened}>
                <SliderGroup
                  title="Apartments"
                  propertyName="unitPercentage"
                  groupName=""
                  sliderLabel={(value: any) => `${value}%`}
                  defaultChecked={
                    state.filterDataAlternative["unitPercentage"] !== undefined
                    // state.appliedFilters.minUnitPercentage ||
                    // state.appliedFilters.maxUnitPercentage
                    //   ? true
                    //   : false
                  }
                  value={[
                    state.filterData.minUnitPercentage,
                    state.filterData.maxUnitPercentage,
                  ]}
                  changeValue={[
                    state.filterDataAlternative["unitPercentage"] !== undefined ? state.filterDataAlternative["unitPercentage"].minValue : state.filterData.minUnitPercentage,
                    state.filterDataAlternative["unitPercentage"] !== undefined ? state.filterDataAlternative["unitPercentage"].maxValue : state.filterData.maxUnitPercentage,
                  ]}
                  onChangeValue={(e: [number, number]) =>
                    dispatch({ type: "setUnitPercentage", payload: e })
                  }
                />
                <Divider />
                <SliderGroup
                  title="Rental Properties"
                  propertyName="rentalProperties"
                  groupName=""
                  sliderLabel={(value: any) => `${value}%`}
                  defaultChecked={
                    state.filterDataAlternative["rentalProperties"] !== undefined
                    // state.appliedFilters.minRentalProperties ||
                    // state.appliedFilters.maxRentalProperties
                    //   ? true
                    //   : false
                  }
                  value={[
                    state.filterData.minRentalProperties,
                    state.filterData.maxRentalProperties,
                  ]}
                  changeValue={[
                    state.filterDataAlternative["rentalProperties"] !== undefined ? state.filterDataAlternative["rentalProperties"].minValue : state.filterData.minRentalProperties,
                    state.filterDataAlternative["rentalProperties"] !== undefined ? state.filterDataAlternative["rentalProperties"].maxValue : state.filterData.maxRentalProperties,
                  ]}
                  onChangeValue={(e: [number, number]) =>
                    dispatch({ type: "setRentalProperties", payload: e })
                  }
                />
                <Divider />
                <SliderGroup
                  title="Dwellings Owned Outright"
                  propertyName="ownedOutright"
                  groupName=""
                  sliderLabel={(value: any) => `${value}%`}
                  defaultChecked={
                    state.filterDataAlternative["ownedOutright"] !== undefined
                    // state.appliedFilters.minRentalProperties ||
                    // state.appliedFilters.maxRentalProperties
                    //   ? true
                    //   : false
                  }
                  value={[
                    state.filterData.minOwnedOutright,
                    state.filterData.maxOwnedOutright,
                  ]}
                  changeValue={[
                    state.filterDataAlternative["ownedOutright"] !== undefined ? state.filterDataAlternative["ownedOutright"].minValue : state.filterData.minOwnedOutright,
                    state.filterDataAlternative["ownedOutright"] !== undefined ? state.filterDataAlternative["ownedOutright"].maxValue : state.filterData.maxOwnedOutright,
                  ]}
                  onChangeValue={(e: [number, number]) =>
                    dispatch({ type: "setOwnedOutright", payload: e })
                  }
                />
                <Divider />
                <SliderGroup
                  title="Dwellings Owned with Mortgage"
                  propertyName="ownedWithMortgage"
                  groupName=""
                  sliderLabel={(value: any) => `${value}%`}
                  defaultChecked={
                    state.filterDataAlternative["ownedWithMortgage"] !== undefined
                    // state.appliedFilters.minRentalProperties ||
                    // state.appliedFilters.maxRentalProperties
                    //   ? true
                    //   : false
                  }
                  value={[
                    state.filterData.minOwnedWithMortgage,
                    state.filterData.maxOwnedWithMortgage,
                  ]}
                  changeValue={[
                    state.filterDataAlternative["ownedWithMortgage"] !== undefined ? state.filterDataAlternative["ownedWithMortgage"].minValue : state.filterData.minOwnedWithMortgage,
                    state.filterDataAlternative["ownedWithMortgage"] !== undefined ? state.filterDataAlternative["ownedWithMortgage"].maxValue : state.filterData.maxOwnedWithMortgage,
                  ]}
                  onChangeValue={(e: [number, number]) =>
                    dispatch({ type: "setOwnedWithMortgage", payload: e })
                  }
                />
              </Collapse>
              <Divider />
              <Button onClick={() => setLangOpen(!isLangOpened)} variant="white"
                sx={(theme) => ({
                  color: theme.colors.allColors[7],
                  "&:hover": {
                    color: "#f76707",
                  },
                })}
              >
                {isLangOpened && <ChevronDown></ChevronDown>}
                {!isLangOpened && <ChevronRight></ChevronRight>}
                Languages
              </Button>
              <Collapse in={isLangOpened}>
                {state.filterData.languages?.length > 0 &&
                  state.filterData.languages?.map(
                    (
                      language: {
                        name: string;
                        minValue: number;
                        maxValue: number;
                      },
                      index: number
                    ) => {
                      let newMinValue;
                      let newMaxValue;
                      const updatedLangIndex: number =
                        state?.filterChangeData?.languages.findIndex(
                          (lang: Language) => lang.name === language.name
                        );
                      if (updatedLangIndex !== -1) {
                        newMinValue = state.filterDataAlternative[`${language.name}`] !== undefined ? state.filterDataAlternative[`${language.name}`].minValue : language.minValue;
                        newMaxValue = state.filterDataAlternative[`${language.name}`] !== undefined ? state.filterDataAlternative[`${language.name}`].maxValue : language.maxValue;
                      }
                      const isLanguageInFilter =
                        state.appliedFilters.languages?.find(
                          (filterLang: Language) =>
                            filterLang.name === language.name
                        );
                      return (
                        <Fragment key={index}>
                          <SliderGroup
                            title={`${language.name} Speakers`}
                            propertyName={language.name}
                            groupName="Languages"
                            sliderLabel={(value: any) => `${value}%`}
                            defaultChecked={state.filterDataAlternative[`${language.name}`] !== undefined}
                            value={[language.minValue, language.maxValue]}
                            changeValue={[newMinValue, newMaxValue]}
                            onChangeValue={(e: [number, number]) => {
                              dispatch({
                                type: "setLanguage",
                                payload: {
                                  name: language.name,
                                  minValue: e[0],
                                  maxValue: e[1],
                                },
                              });
                            }}
                          />
                          <Divider />
                        </Fragment>
                      );
                    }
                  )}
              </Collapse>

              <Divider />

              <Button onClick={() => setTravOpen(!isTravOpened)} variant="white"
                sx={(theme) => ({
                  color: theme.colors.allColors[7],
                  "&:hover": {
                    color: "#f76707",
                  },
                })}
              >
                {isTravOpened && <ChevronDown></ChevronDown>}
                {!isTravOpened && <ChevronRight></ChevronRight>}
                Travel To Work
              </Button>
              <Collapse in={isTravOpened}>
                {state.filterData.methodsOfTravel?.length > 0 &&
                  state.filterData.methodsOfTravel?.map(
                    (
                      travelMethod: {
                        name: string;
                        minValue: number;
                        maxValue: number;
                      },
                      index: number
                    ) => {
                      let newMinValue;
                      let newMaxValue;
                      const updatedTravelMethodIndex: number =
                        state?.filterChangeData?.methodsOfTravel.findIndex(
                          (travMethod: TravelMethod) => travMethod.name === travelMethod.name
                        );
                      if (updatedTravelMethodIndex !== -1) {
                        newMinValue = state.filterDataAlternative[`${travelMethod.name}`] !== undefined ? state.filterDataAlternative[`${travelMethod.name}`].minValue : travelMethod.minValue;
                        newMaxValue = state.filterDataAlternative[`${travelMethod.name}`] !== undefined ? state.filterDataAlternative[`${travelMethod.name}`].maxValue : travelMethod.maxValue;
                      }
                      const isTravelMethodInFilter =
                        state.appliedFilters.methodsOfTravel?.find(
                          (filterTravelMethod: TravelMethod) =>
                            filterTravelMethod.name === travelMethod.name
                        );
                      return (
                        <Fragment key={index * -1}>
                          <SliderGroup
                            title={`${travelMethod.name}`}
                            propertyName={travelMethod.name}
                            groupName="Travel to Work"
                            sliderLabel={(value: any) => `${value}%`}
                            defaultChecked={state.filterDataAlternative[`${travelMethod.name}`] !== undefined}
                            value={[travelMethod.minValue, travelMethod.maxValue]}
                            changeValue={[newMinValue, newMaxValue]}
                            onChangeValue={(e: [number, number]) => {
                              dispatch({
                                type: "setTravelMethod",
                                payload: {
                                  name: travelMethod.name,
                                  minValue: e[0],
                                  maxValue: e[1],
                                },
                              });
                            }}
                          />
                          <Divider />
                        </Fragment>
                      );
                    }
                  )}
              </Collapse>

              <Stack pt={65} p="lg">
                <Tooltip
                  closeDelay={1000}
                  width={disableApplyFilters ? 230 : 95}
                  arrowSize={3}
                  withArrow
                  transitionProps={{ transition: 'fade', duration: 200}}
                  label={disableApplyFilters ? 'cannot apply filters on shared maps' : tooManySA1s ? 'Too many areas selected (maximum is ' + maximumNumberOfPolygonsToFilter.toString() + ')' : 'Apply Filters'}
                >
                  <Button color="orange" variant="outline" fullWidth onClick={applyFiltersHandler} disabled={disableApplyFilters || tooManySA1s}>
                    Apply Filters
                  </Button>
                </Tooltip>
                <Button color="orange" variant="outline" mb="sm" onClick={resetFiltersHandler}>
                  Reset Filters
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Title
                order={6}
                p="lg"
                align="left"
                style={{ textTransform: "uppercase" }}
              >
                Filters
              </Title>
              <Divider />
              <Title
                order={5}
                p="lg"
                align="left"
                sx={(theme) => ({
                  color: theme.colors.allColors[7],
                })}
                style={{ textTransform: "uppercase" }}
              >
                Please Draw Shape to use filters
              </Title>
            </>
          )}
        </Navbar>
      </>
    </div>
  );
};

export default FiltersBar;
