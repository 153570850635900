import type { ReactNode } from "react";
import { createContext, useReducer } from "react";
import {
  calculateSum,
} from "../map-utils";
import { Action, Language, State, Dispatch, PopulationRecord } from "../types";

const defaultState: State = {
  map: null,
  drawingManager: null,
  mapPolygons: [],
  splittedPolygons: [],
  zoomLevel: 15,
  polygon: { isSet: false, data: {} },
  geometery: null,
  userMap: null,
  userMaps: [],
  annotations: [],
  markers: [],
  showMarkers: true,
  showBusinessLocation: false,
  showPOIs: false,
  businessLocations: [],
  showSplitPolygons: false,
  jobId: 0,
  jobName: "",
  unsplitPolygons: [],
  splitPolygonVisibility: false,
  markersData: [],
  sa1Portions: [],
  summaryData: {},
  totalSummaryData: {},
  letterBoxData: {},
  letterBoxCountPAF: {},
  businessesCount: {},
  heatmapValue: "",
  showSummaryBar: false,
  polygonsArea: 0,
  polygons: [],
  filterDataVersion : 0,
  filterDataAlternative: {},
  filterData: {},
  filterChangeData: {},
  filtersDataToSave: [],
  appliedFilters: {},
  totalFiltersRecords: [],
  totalPopulation: 0,
  summaryDataLoading: false,
  summaryDataLoadingLetterboxCounts: false,
  summaryDataLoadingDemographics: false,
  reportGenerating: false,
  listOfSALs: [],
  listOfLGAs: [],
  startTour: false,
  trialExpired: false,
  subscriptionExpired: false,
  benchmark: "state",
  viewExportMapModal: {visible: false, type: ""},
  heatMapExportSize: {width: 0, height: 0},

};

let totalLetterBoxCountPAF: any[] = [];
let totalBusinesses: any[] = [];
let totalLetterBoxes: any[] = [];
let allPolygonsArea: any[] = [];
let populationRecords: PopulationRecord[] = [];

// let summaryBarData: any[] = [];

const AppCtx = createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined
);

function appReducer(state: State, action: Action) {
  switch (action.type) {
    case "setSplitPolygonVisibility": {
      return { ...state, splitPolygonVisibility: action.payload };
    }
    case "setSummaryDataLoading": {
      return { ...state, summaryDataLoading: action.payload, summaryDataLoadingLetterboxCounts: action.payload, summaryDataLoadingDemographics: action.payload };
    }
    case "setSummaryDataLoadingLetterboxCounts": {
      let overallLoading = state.summaryDataLoadingDemographics || action.payload;
      return { ...state, summaryDataLoadingLetterboxCounts: action.payload, summaryDataLoading: overallLoading };
    }
    case "setSummaryDataLoadingDemographics": {
      let overallLoading = state.summaryDataLoadingLetterboxCounts || action.payload;
      return { ...state, summaryDataLoadingDemographics: action.payload, summaryDataLoading: overallLoading };
    }
    case "setReportGenerating": {
      return { ...state, reportGenerating: action.payload };
    }
    case "addMarker": {
      return { ...state, markersData: [...state.markersData, action.payload] };
    }
    case "changeVisibility": {
      const newMarkers = [...state.markersData];
      const indx = newMarkers.findIndex((mrk) => mrk.id === action.payload);
      newMarkers[indx].visibility = !newMarkers[indx].visibility;
      return { ...state, markersData: newMarkers };
    }
    // case "addPolygon": {
    //   return { ...state, polygons: [...state.polygons, action.payload] };
    // }
    case "removePolygon": {
      let unsplitPolygons = state.polygons.filter((p: any) => {
        return p.getPath() !== action.payload.getPath();
      });
      return { ...state, polygons: [...unsplitPolygons] };
    }
    case "emptyPolygons": {
      return { ...state, polygons: [] };
    }
    case "setFilterData": {
      let updatedData: any = { ...state.appliedFilters };
      let languages: any[] = Array.isArray(state.appliedFilters?.languages)
        ? state.appliedFilters?.languages.slice()
        : [];
      let methodsOfTravel: any[] = Array.isArray(state.appliedFilters?.methodsOfTravel)
        ? state.appliedFilters?.methodsOfTravel.slice()
        : [];
      Object.keys(action?.payload || {})?.find((key: any) => {
        if (key === "methodsOfTravel") {
          action.payload.methodsOfTravel.forEach((language: Language) => {
            if (
              !methodsOfTravel.find((lang: Language) => language.name === lang.name)
            ) {
              methodsOfTravel.push(language);
              updatedData.methodsOfTravel = methodsOfTravel;
            }
          });
        }
        if (key === "languages") {
          action.payload.languages.forEach((language: Language) => {
            if (
              !languages.find((lang: Language) => language.name === lang.name)
            ) {
              languages.push(language);
              updatedData.languages = languages;
            }
          });
        }
        if (!Object.keys(state.appliedFilters).includes(key)) {
          updatedData[key] = action.payload[key];
        }
        return null;
      });
      return {
        ...state,
        filterData: { ...action.payload },
        filterChangeData: { ...updatedData },
      };
    }
    case "setFilterChangeData": {
      return { ...state, filterChangeData: { ...action.payload } };
    }
    case "setIncome": {
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          minIncome: action.payload[0],
          maxIncome: action.payload[1],
        },
      };
    }
    case "setUnitPercentage": {
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          minUnitPercentage: action.payload[0],
          maxUnitPercentage: action.payload[1],
        },
      };
    }

    case "setMedianAge": {
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          minMedianAge: action.payload[0],
          maxMedianAge: action.payload[1],
        },
      };
    }

    case "setUnemployed": {
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          minUnemployed: action.payload[0],
          maxUnemployed: action.payload[1],
        },
      };
    }

    case "setUniDegree": {
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          minUniDegree: action.payload[0],
          maxUniDegree: action.payload[1],
        },
      };
    }

    case "setRentalProperties": {
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          minRentalProperties: action.payload[0],
          maxRentalProperties: action.payload[1],
        },
      };
    }

    case "setOwnedOutright": {
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          minOwnedOutright: action.payload[0],
          maxOwnedOutright: action.payload[1],
        },
      };
    }

    case "setOwnedWithMortgage": {
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          minOwnedWithMortgage: action.payload[0],
          maxOwnedWithMortgage: action.payload[1],
        },
      };
    }

    case "setIndigenous": {
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          minIndigenous: action.payload[0],
          maxIndigenous: action.payload[1],
        },
      };
    }

    case "setHomeInternet": {
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          minHomeInternet: action.payload[0],
          maxHomeInternet: action.payload[1],
        },
      };
    }

    case "setItalianSpeakers": {
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          minItalianSpeakers: action.payload[0],
          maxItalianSpeakers: action.payload[1],
        },
      };
    }

    case "setGreekSpeakers": {
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          minGreekSpeakers: action.payload[0],
          maxGreekSpeakers: action.payload[1],
        },
      };
    }
    case "setLanguage": {
      const languages = Array.isArray(state.filterChangeData.languages)
        ? state.filterChangeData.languages
        : [];
      const updatedLangIndex = languages.findIndex(
        (lang) => lang.name === action.payload.name
      );
      if (updatedLangIndex !== -1) {
        languages[updatedLangIndex] = action.payload; // {name: string, minValue: number, maxValue: number}
      }
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          languages: [...languages],
        },
      };
    }
    case "setTravelMethod": {
      const methodsOfTravel = Array.isArray(state.filterChangeData.methodsOfTravel)
        ? state.filterChangeData.methodsOfTravel
        : [];
      const updatedTravelMethodIndex = methodsOfTravel.findIndex(
        (travelMethod) => travelMethod.name === action.payload.name
      );
      if (updatedTravelMethodIndex !== -1) {
        methodsOfTravel[updatedTravelMethodIndex] = action.payload; // {name: string, minValue: number, maxValue: number}
      }
      return {
        ...state,
        filterChangeData: {
          ...state.filterChangeData,
          methodsOfTravel: [...methodsOfTravel],
        },
      };
    }

    // case "setSa1Portions": {
    //   return { ...state, sa1Portions: action.payload };
    // }

    case "setHeatmapValue": {
      return { ...state, heatmapValue: action.payload };
    }

    case "setCheck": {
      return {
        ...state,
        filterData: { ...state.filterData, setCheck: action.payload },
      };
    }

    case "addUnsplitPolygons": {
      return { ...state, unsplitPolygons: action.payload };
    }

    case "addJobId": {
      return { ...state, jobId: action.payload };
    }

    case "resetJobId": {
      return { ...state, jobId: 0 };
    }

    case "setJobName": {
      return { ...state, jobName: action.payload };
    }

    case "addAnnotations": {
      const existingAnnotationIndex = state.annotations.findIndex(
        (annotation) => annotation.id === action.payload.id
      );
      const updatedAnnotations = [...state.annotations];
      if (existingAnnotationIndex === -1) {
        updatedAnnotations.push(action.payload);
      } else {
        updatedAnnotations[existingAnnotationIndex] = action.payload;
      }
      return {
        ...state,
        annotations: [...updatedAnnotations],
      };
    }

    case "addMarkers": {
      return {
        ...state,
        markers: [...state.markers, action.payload],
      };
    }

    case "emptyMarkers": {
      return { ...state, markers: [] };
    }

    case "toggleMarkers": {
      if (action.payload) {
        return { ...state, showMarkers: action.payload };
      } else {
        return { ...state, showMarkers: !state.showMarkers };
      }
    }

    case "toggleBusinessLocation": {
      if (action.payload) {
        return { ...state, showBusinessLocation: action.payload };
      } else {
        return { ...state, showBusinessLocation: !state.showBusinessLocation };
      }
    }

    case "togglePOIs": {
      if (action.payload) {
        return { ...state, showPOIs: action.payload };
      } else {
        return { ...state, showPOIs: !state.showPOIs };
      }
    }

    case "addBusinessLocations": {
      return {
        ...state,
        businessLocations: [...state.businessLocations, action.payload],
      };
    }

    case "resetBusinessLocation": {
      return { ...state, businessLocations: [] };
    }

    case "setShowSplitPolygons": {
      return { ...state, showSplitPolygons: action.payload };
    }

    case "setUserMaps": {
      return { ...state, userMaps: action.payload };
    }

    case "addUserMap": {
      return {
        ...state,
        userMaps: [...state.userMaps, action.payload],
      };
    }

    case "setUserMap": {
      return { ...state, userMap: action.payload };
    }

    case "setGoogleGeometry": {
      return { ...state, geometery: action.payload };
    }

    case "setMap": {
      return { ...state, map: action.payload };
    }

    case "setDrawingManager": {
      return { ...state, drawingManager: action.payload };
    }

    case "setMapPolygons": {
      state.mapPolygons.forEach((p: any) => p.polygon.setMap(null));
      const existingUnsplitPolygonIndex = state.splittedPolygons.findIndex(
        (sp) => sp.unsplitPolygonId === action.payload.unsplitPolygonId
      );
      const updatedSplittedPolygons = [...state.splittedPolygons];

      if (existingUnsplitPolygonIndex === -1) {
        updatedSplittedPolygons.push(action.payload);
      } else {
        updatedSplittedPolygons[
          existingUnsplitPolygonIndex
        ].mapPolygons.forEach((p: any) => p.polygon.setMap(null));
        updatedSplittedPolygons[existingUnsplitPolygonIndex] = action.payload;
      }
      let flattenArrOfSplittedPolygons: any = [];
      updatedSplittedPolygons.forEach((p: any) => {
        flattenArrOfSplittedPolygons.push(...p.mapPolygons);
      });
      return {
        ...state,
        splittedPolygons: [...updatedSplittedPolygons],
        mapPolygons: action.payload?.mapPolygons,
      };
    }

    // case "removeMapPolygons": {
    //   const existingUnsplitPolygonIndex = state.splittedPolygons.findIndex(
    //     (sp) => sp.unsplitPolygonId === action.payload.unsplitPolygonId
    //   );
    //   const updatedSplittedPolygons = [...state.splittedPolygons];

    //   if (existingUnsplitPolygonIndex !== -1) {
    //     updatedSplittedPolygons[existingUnsplitPolygonIndex] = action.payload;
    //   }
    //   let flattenArrOfSplittedPolygons: any = [];
    //   updatedSplittedPolygons.forEach((p: any) => {
    //     flattenArrOfSplittedPolygons.push(...p.mapPolygons);
    //   });
    //   return {
    //     ...state,
    //     splittedPolygons: [...updatedSplittedPolygons],
    //     mapPolygons: flattenArrOfSplittedPolygons,
    //   };
    // }

    case "setZoomLevel": {
      return { ...state, zoomLevel: action.payload };
    }

    case "setSummaryData": {
      return { ...state, summaryData: action.payload };
    }

    // case "setTotalSummaryData": {
    //   let newData: SummaryDataType = {};
    //   const existingPolygonIndex = summaryBarData.findIndex(
    //     (item) => item.polygonId === action.payload.polygonId
    //   );
    //   if (existingPolygonIndex === -1) {
    //     summaryBarData.push(action.payload);
    //     if (Object.keys(state.totalSummaryData).length !== 0) {
    //       newData = sumTotal(
    //         state.totalSummaryData,
    //         action.payload.summaryBarData
    //       );
    //     } else {
    //       newData = action.payload.summaryBarData;
    //     }
    //   } else {
    //     newData = calculateDifference(
    //       state.totalSummaryData,
    //       summaryBarData[existingPolygonIndex].summaryBarData
    //     );
    //   }
    //   return { ...state, totalSummaryData: newData };
    // }

    // case "emptySummaryBarData": {
    //   return {
    //     ...state,
    //     totalSummaryData: {},
    //     summaryData: {},
    //     letterBoxData: {},
    //     businessesCount: {},
    //     letterBoxCountPAF: {},
    //   };
    // }

    case "resetAllLetterboxCounts": {
      totalLetterBoxes = [];
      totalBusinesses = [];
      totalLetterBoxCountPAF = [];
      populationRecords = [];
      return { ...state, letterBoxData: 0, businessesCount: 0, letterBoxCountPAF: 0, totalPopulation: 0 };
    }

    case "setLetterBoxData": {
      const existingPolygonIndex = totalLetterBoxes.findIndex(
        (item) => item.polygonId === action.payload.polygonId
      );
      if (existingPolygonIndex === -1) {
        totalLetterBoxes.push(action.payload);
      } else {
        totalLetterBoxes[existingPolygonIndex] = action.payload;
      }
      let totalCount: object = {};
      if (totalLetterBoxes.length > 1) {
        totalCount = {
          residential_count: calculateSum(
            totalLetterBoxes,
            "residential_count"
          ),
        };
      } else {
        totalCount = {
          residential_count: action.payload.residential_count,
        };
      }
      return { ...state, letterBoxData: totalCount };
    }

    case "showSummarBar": {
      return { ...state, showSummaryBar: action.payload };
    }

    case "setPolygonsArea": {
      const existingPolygonIndex = allPolygonsArea.findIndex(
        (item) => item.polygonId === action.payload.polygonId
      );
      if (existingPolygonIndex === -1) {
        allPolygonsArea.push(action.payload);
      } else {
        allPolygonsArea[existingPolygonIndex] = action.payload;
      }
      let totalCount: number = 0;
      if (allPolygonsArea.length > 1) {
        totalCount = calculateSum(allPolygonsArea, "area");
      } else {
        totalCount = action.payload.area;
      }
      return { ...state, polygonsArea: totalCount };
    }

    case "setBusinessesCount": {
      const existingPolygonIndex = totalBusinesses.findIndex(
        (item) => item.polygonId === action.payload.polygonId
      );
      if (existingPolygonIndex === -1) {
        totalBusinesses.push(action.payload);
      } else {
        totalBusinesses[existingPolygonIndex] = action.payload;
      }
      let totalCount: object = {};
      if (totalBusinesses.length > 1) {
        totalCount = {
          businesses_count: calculateSum(totalBusinesses, "businesses_count"),
        };
      } else {
        totalCount = {
          businesses_count: action.payload.businesses_count,
        };
      }

      return { ...state, businessesCount: totalCount };
    }

    case "setLetterBoxCountPAF": {
      const existingPolygonIndex = totalLetterBoxCountPAF.findIndex(
        (item) => item.polygonId === action.payload.polygonId
      );
      if (existingPolygonIndex === -1) {
        totalLetterBoxCountPAF.push(action.payload);
      } else {
        totalLetterBoxCountPAF[existingPolygonIndex] = action.payload;
      }
      let totalCount: object = {};
      if (totalLetterBoxCountPAF.length > 1) {
        totalCount = {
          letterbox_count_PAF: calculateSum(
            totalLetterBoxCountPAF,
            "letterbox_count_PAF"
          ),
        };
      } else {
        totalCount = {
          letterbox_count_PAF: action.payload.letterbox_count_PAF,
        };
      }
      return { ...state, letterBoxCountPAF: totalCount };
    }

    case "setPolygons": {
      if(action.payload === null)
        return { ...state, polygons: [] };
      return { ...state, polygons: [...state.polygons, action.payload] };
    }

    case "setFiltersDataToSave": {
      let set = action.payload.set;
      const existingItemIndex = state.filtersDataToSave.findIndex(
        (item) => item === action.payload.title
      );
      const updatedData = [...state.filtersDataToSave];
      if(existingItemIndex === -1) {
        if(set)
          updatedData.push(action.payload.title);
      } else {
        if(!set)
          updatedData.splice(existingItemIndex, 1);
      }
      return {
        ...state,
        filtersDataToSave: [...updatedData],
      };
    }

    case "setFilterDataAlternative": {
      let set = action.payload.set;
      const updatedData = state.filterDataAlternative;
      if(action.payload.set)
        updatedData[action.payload.title] = {name: action.payload.title, minValue: action.payload.minValue, maxValue: action.payload.maxValue, groupName: action.payload.groupName};
      else
        delete updatedData[action.payload.title];
      return {
        ...state,
        filterDataAlternative: updatedData,
        filterDataVersion: state.filterDataVersion + 1,
      };
    }

    case "setFilterDataEntire": {
      let entirePayload = action.payload;
      return {
        ...state,
        filterDataAlternative: entirePayload,
      };
    }

    case "setAppliedFilters": {
      return {
        ...state,
        appliedFilters: action.payload,
        filterChangeData: { ...action.payload },
      };
    }

    case "setTotalFiltersRecords": {
      const existingItemIndex = state.totalFiltersRecords.findIndex(
        (item) => item.polygonId === action.payload.polygonId
      );
      const updatedData = [...state.totalFiltersRecords];
      if (existingItemIndex === -1) {
        updatedData.push(action.payload);
      } else {
        updatedData.splice(existingItemIndex, 1);
      }
      return {
        ...state,
        totalFiltersRecords: [...updatedData],
      };
    }

    case "setTotalPopulation": {
      const data = action.payload;
      let totalPopulation: number | null = null;
      const existingPolygonIndex = populationRecords.findIndex(
        (item) => item.unsplitPolygonId === data.unsplitPolygonId
      );
      if (existingPolygonIndex === -1) {
        populationRecords.push(data);
        totalPopulation = calculateSum(populationRecords, "population_count");
      } else {
        if (Object.keys(data).length === 0) {
          populationRecords.splice(existingPolygonIndex, 1);
          totalPopulation = calculateSum(populationRecords, "population_count");
        } else {
          populationRecords[existingPolygonIndex] = data;
          totalPopulation = calculateSum(populationRecords, "population_count");
        }
      }
      return { ...state, totalPopulation: totalPopulation };
    }

    case "resetStates": {
      defaultState.map = state.map;
      defaultState.drawingManager = state.drawingManager;
      return defaultState;
    }

    case 'resetSummaryStats': {
      totalLetterBoxes = [];
      totalBusinesses = [];
      totalLetterBoxCountPAF = [];
      populationRecords = [];
      return {...state, summaryData: {}};
    }

    case "setSALList": {
      return { ...state, listOfSALs: action.payload };
    }

    case "setLGAList": {
      return { ...state, listOfLGAs: action.payload };
    }

    case "START": {
      return { ...state, startTour: !state.startTour };
    }

    case "setTrialExpired": {
      return { ...state, trialExpired: action.payload };
    }

    case "setSubscriptionExpired": {
      return { ...state, subscriptionExpired: action.payload };
    }

    case "setBenchmark": {
      return { ...state, benchmark: action.payload };
    }

    case "setViewExportMapModal": {
      return { ...state, viewExportMapModal: action.payload };
    }
    case "setHeatMapExportSize" : {
      return { ...state, heatMapExportSize: action.payload };
    }

    default:
      return state;
  }
}

export function StoreProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(appReducer, defaultState);

  return (
    <AppCtx.Provider value={{ state, dispatch }}>{children}</AppCtx.Provider>
  );
}

export default AppCtx;
