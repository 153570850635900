import { Grid, Group, Text, createStyles, RangeSlider, Box, Divider } from '@mantine/core';
import { useContext, useState } from 'react';
import storeContext from '../../store/store';
import fetchData from './DataReference';

type CriteriasType = {
  [key: string]: string;
};

let CriteriasPrefix: CriteriasType = {
  females: "",
  males: "",
  medianAge: "",
  income: "$",
  unemployed: "",
  uniDegree: "",
  unitPercentage: "",
  rentalProperties: "",
  ownedOutright: "",
  ownedWithMortgage: "",
  indigenous: "",
};
let CriteriasPostfix: CriteriasType = {
  females: "%",
  males: "%",
  medianAge: "",
  income: "",
  unemployed: "%",
  uniDegree: "%",
  unitPercentage: "%",
  rentalProperties: "%",
  ownedOutright: "%",
  ownedWithMortgage: "%",
  indigenous: "%",
};

let Criterias: CriteriasType = {
  females: "string",
  males: "string",
  medianAge: "string",
  income: "string",
  unemployed: "string",
  uniDegree: "string",
  unitPercentage: "string",
  rentalProperties: "string",
  ownedOutright: "string",
  ownedWithMortgage: "string",
  indigenous: "string",
};

function resolveCriteriaNameToTitle(criteria: string) {
  switch (criteria) {
    case 'females':
      return 'Females';
    case 'males':
      return 'Males';
    case 'medianAge':
      return 'Median age';
    case 'income':
      return 'Median weekly household income';
    case 'unemployed':
      return 'Unemployment';
    case 'uniDegree':
      return 'People with a university degree';
    case 'unitPercentage':
      return 'Apartments';
    case 'rentalProperties':
      return 'Rental properties';
    case 'ownedOutright':
      return 'properties owned outright';
    case 'ownedWithMortgage':
      return 'Properties owned with mortgage';
    case 'indigenous':
      return 'Indigenous population';
    default:
      return criteria;
  }
}


interface Filter {
  name: string;
  minValue: number;
  maxValue: number;
}

const useStyles = createStyles((theme) => ({
  filterMapKey: {
    backgroundColor: '#fff', // --color-white
    padding: '5px', // --padding-8xs
    borderRadius: '8px', // --br-5xs
    fontSize: '10px', // --font-size-3xs
    color: '#000', // --color-black
    textAlign: "left",
  },
  filterItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "flex-start",
    marginBottom: '5px', // --gap-8xs
  },
  filterLabel: {
    fontWeight: 300,
    fontSize: '14px', // --font-size-xs
    marginRight: '5px', // --gap-8xs
  },
  filterLabelTitle: {
    fontWeight: 700,
    fontSize: '14px', // --font-size-xs
    marginRight: '5px', // --gap-8xs
  },
  selectionText: {
    fontWeight: 500,
    fontSize: '12px', // --font-size-xs
  },
  poweredBy: {
    fontWeight: 200,
    fontSize: '14px', // --font-size-xs, adjust as necessary
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    gap: '5px', // --gap-8xs, space between the "Powered by" text and the logo
    flexShrink: 0,
  },
  logo: {
    width: 'auto',
    height: '24px',
    flexShrink: 0,
  },
  filtersApplied: {
    fontWeight: 700,
    fontSize: '16px', // --font-size-xs
    marginBottom: '10px', // --gap-3xs
  },
  sliderX: {
    marginBottom: '5px', // --gap-8xs
  },
  branding: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px', // --gap-3xs
  },
  separator: {
    marginTop: '5px', // --gap-8xs
  },
}));


const FilterMapKey = () => {
  const { classes } = useStyles();
  const { state, dispatch } = useContext<any>(storeContext);

  return (
    <Box className={classes.filterMapKey}>
      <Text className={classes.filtersApplied}>Filters Selected</Text>

      {/* Mapping filters */}
      {Object.entries(state.filterDataAlternative).map(([key, filter], index) => {
        if (filter) {

          // Cast the filter object to the Filter interface
          const typedFilter = filter as Filter;

          // Destructure from the filter object
          const { name, minValue: selectedMinValue, maxValue: selectedMaxValue } = typedFilter;

          // Use the state.filterData to get the max and min values for RangeSliders
          let maxValue = state.filterData[`max${key.charAt(0).toUpperCase() + key.slice(1)}`];
          let minValue = state.filterData[`min${key.charAt(0).toUpperCase() + key.slice(1)}`];


          if (minValue === undefined || maxValue === undefined) {

            // data is not directly available in the filterData object
            // so we need to find it in the languages or travelToWork objects within filterData
            let languages = state.filterData.languages;
            let travelToWork = state.filterData.methodsOfTravel;

            if (languages !== undefined) {
              let language = languages.find((language: any) => language.name === key);
              if (language) {
                maxValue = language.maxValue;
                minValue = language.minValue;
              }
            }
            if (travelToWork !== undefined) {
              let travelMethod = travelToWork.find((travelMethod: any) => travelMethod.name === key);
              if (travelMethod) {
                maxValue = travelMethod.maxValue;
                minValue = travelMethod.minValue;
              }
            }
          }

          let prefix = CriteriasPrefix[key];
          let postfix = CriteriasPostfix[key];

          if (prefix === undefined) {
            prefix = "";
          }
          if (postfix === undefined) {
            postfix = "%";
          }

          return (
            <Box className={classes.sliderX} key={index}>
              <Box className={classes.filterItem}>
                <Text className={classes.filterLabelTitle} component="span">
                  {resolveCriteriaNameToTitle(key)}
                </Text>
                <Text className={classes.filterLabel} component="span">
                  Selected Range {prefix}{selectedMinValue}{postfix} to {prefix}{selectedMaxValue}{postfix}
                </Text>
              </Box>
              <Box>
                <RangeSlider
                  color="orange"
                  min={minValue}
                  max={maxValue}
                  defaultValue={[selectedMinValue, selectedMaxValue]} />
              </Box>

              <Box className={classes.separator}>
                <Divider size="xs" variant="dotted"/>
              </Box>
            </Box>
          );
        }
        return null;
      })}

      {/* Branding Section */}
      <Box className={classes.branding}>
        <Text className={classes.poweredBy}>Powered by</Text>
        <img
          src="/images/scopomap-wide-light-1.svg"
          alt="ScopeMap Logo"
          className={classes.logo}
        />
      </Box>
    </Box>
  );
};

export default FilterMapKey;