import axios from "axios";
import {
  Aside,
  Title,
  Divider,
  Group,
  LoadingOverlay,
  Text,
  Grid,
  createStyles,
  ActionIcon,
  Menu,
  Button,
  Tooltip,
  Dialog,
  Textarea,
  Stack,
} from "@mantine/core";
import React, { Fragment, useContext, useState } from "react";
import {
  Mailbox,
  BuildingStore,
  Inbox,
  Users,
  ArrowBarLeft,
  ArrowBarRight,
  Download,
  FileSpreadsheet,
  FileAnalytics,
  File3d,
  ChartBubble,
} from "tabler-icons-react";
import storeContext from "../store/store";
import useElemetHeight from "../hooks/useElementHeight";
import useElementSize from "../hooks/useElementSize";
import ValueAndComparisonToAverage from "./ValueAndComparisonToAverage"
import AIChatWindow from "./AIChatWindow";
import { downloadSummary, downloadMapAsBase64 } from "../map-utils/downloadSummary"
import { useAuth } from "../hooks/useAuth";

const useStyles = createStyles((theme) => ({
  show: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      transform: "translateX(0%);",
    },
  },
  hide: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      transform: "translateX(100%);",
    },
  },
  summaryToggleBtn: {
    display: "none",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      transform: "translateX(-0%);",
      display: "block",
      height: "37px",
      position: "fixed",
      top: "37.3%",
      right: "0",
      padding: "3px ",
      width: "33px",
      zIndex: 1000,
      backgroundColor: theme.colors.allColors[2],
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
  }
}));

type Context = {
  state: any;
  dispatch: any;
}

function downloadAsCSV(context: Context, user: any) {
  downloadSummary('CSV', context, user);
}

async function downloadAsWord(context: Context, user: any, dispatch: any) {
  dispatch({ type: "setReportGenerating", payload: true });
  try {
    await downloadSummary('Word', context, user);
  }
  catch (e) {
    console.log(e);
  }
  dispatch({ type: "setReportGenerating", payload: false });
}

function downloadSA1sData(context: Context, user: any) {
  downloadSummary('sa1s', context, user);
}

const SummaryBar = () => {
  // const [summaryData, setSummaryData] = useState<any>([]);
  const [showHideOnSmScreen, setShowHideOnSmScreen] = useState<boolean>(false);
  const [interogationChatOpened, setInterogationChatOpened] = useState(false);

  const context = useContext<any>(storeContext);
  const { state, dispatch } = context;

  const elementHeight = useElemetHeight();
  // calling hook by passing element Id to get width and height of that element.
  const { height } = useElementSize("header-main");
  const { classes } = useStyles();
  const { user } = useAuth();

  const premiumTierSubscription = true;
    // user &&
    // user.subscriptionDetails &&
    // user.subscriptionDetails.subscription_name !== "Trial" &&
    // user.subscriptionDetails.subscription_name !== "Starter";

  const properties = {
    polygonList: state.mapPolygons,
    paddingPercent: [25, 25, 25, 25],
    context: { state, dispatch },
  }

  return (
    <>
      {state.showSummaryBar ? (
        <>
          <ActionIcon
            onClick={() => {
              setShowHideOnSmScreen(!showHideOnSmScreen);
            }}
            className={classes.summaryToggleBtn}
          >
            {showHideOnSmScreen ? (
              <ArrowBarRight color="#fff" />
            ) : (
              <ArrowBarLeft color="#fff" />
            )}
          </ActionIcon>
          <Aside
            className={`${showHideOnSmScreen ? classes.show : classes.hide}`}
            hiddenBreakpoint="sm"
            sx={(theme) => ({
              height: `calc(100vh  - ${height}px)`,
              top: `${height}px)`,
              overflowY: "auto",
              overflowX: "hidden",
              transition: "0.3s all ease-in-out",
              width: "100%",
              maxWidth: "350px",
              [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                position: "fixed",
                top: `${height}px !important`,
                maxWidth: "300px",
              },
              [`@media (max-width: 425px)`]: {
                maxWidth: "250px",
              },
            })}
          >
            <Grid>
              <Grid.Col span={8} mb="md">
                <Title
                  order={6}
                  p="lg"
                  align="left"
                  style={{ textTransform: "uppercase" }}
                >
                  Summary
                </Title>
              </Grid.Col>
              <Grid.Col
                span={4}
                mb="md"
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <Menu
                  styles={{
                    itemIcon: { color: "#414042" },
                    itemLabel: { color: "#414042" },
                  }}

                >
                  <Menu.Target>
                    <Tooltip
                      label="Currently in Beta"
                      position="left"
                      withArrow
                    >
                      <Button
                        color="#FF8E16"

                        sx={(theme) => ({
                          backgroundColor: theme.colors.allColors[7],
                          "&:hover": {
                            backgroundColor: "#f76707",
                          },
                        })}
                      >
                        <Download />
                      </Button>
                    </Tooltip>

                  </Menu.Target>

                  <Menu.Dropdown>

                    <Menu.Label>Download formats</Menu.Label>

                    <Menu.Item
                      icon={<File3d size={14} />}
                      onClick={() => {
                        downloadSA1sData(context, user);
                      }}
                    >
                      Detailed Report
                    </Menu.Item>
                    <Menu.Item
                      icon={<FileSpreadsheet size={14} />}
                      onClick={() => {
                        downloadAsCSV(context, user);
                      }}
                    >
                      Summary CSV
                    </Menu.Item>
                    <Menu.Item
                      icon={<FileAnalytics size={14} />}
                      onClick={() => {
                        downloadAsWord(context, user, dispatch);
                      }}
                    >
                      MS Word
                    </Menu.Item>
                    <Tooltip label={"Only available to premium subscribers"} withArrow>
                      <Menu.Item
                        icon={<ChartBubble size={14} />}
                        onClick={() => {
                          setInterogationChatOpened(true);
                        }}
                        disabled={!premiumTierSubscription}
                      >
                        AI Interaction
                      </Menu.Item>
                    </Tooltip>
                    {/*<Menu.Item 
                    icon={<Photo size={14} />}
                    onClick={() => {
                      downloadMapAsBase64(state, properties); // FOR DEBUGGING MAPS ONLY
                      console.log("Base64Test");
                    }
                    }>
                    Base64Test
                  </Menu.Item>?*/}
                  </Menu.Dropdown>

                </Menu>
                <Dialog
                  opened={interogationChatOpened && premiumTierSubscription}
                  onClose={() => setInterogationChatOpened(false)}
                  radius="md"
                  withCloseButton={true}
                  size={400}
                  position={{ top: 150, right: 350 }}
                >
                  <AIChatWindow />
                </Dialog>

              </Grid.Col>
            </Grid>

            <Divider />
            <Grid p="md" pb="lg" pt="lg" my={0}>
              <Grid.Col span={6} mb="md">
                <div>
                  <LoadingOverlay
                    visible={state.summaryDataLoading || state.reportGenerating}
                    loaderProps={{ size: "xl", color: "orange" }}
                    overlayOpacity={0.3}
                  />
                </div>

                <Group spacing="xs" align={"flex-start"}>
                  <span>
                    <Mailbox size={19} strokeWidth={1} color={"black"} />
                  </span>
                  <Stack spacing="sm">
                    <span>
                      <Text size="sm">Residential</Text>
                    </span>
                    <Title order={2}>
                      {state.letterBoxData?.residential_count || 0}
                    </Title>
                  </Stack>
                </Group>
              </Grid.Col>
              <Grid.Col span={6}>
                <Group spacing="xs" align={"flex-start"} position="right">
                  <span>
                    <BuildingStore size={19} strokeWidth={1} color={"black"} />
                  </span>
                  <Stack spacing="sm">
                    <span>
                      <Text size="sm">Businesses</Text>
                    </span>
                    <Title order={2}>
                      {state.businessesCount?.businesses_count || 0}
                    </Title>
                  </Stack>
                </Group>
              </Grid.Col>
              <Grid.Col span={6}>
                <Group spacing="xs" align={"flex-start"}>
                  <span>
                    <Inbox size={19} strokeWidth={1} color={"black"} />
                  </span>
                  <Stack spacing="sm">
                    <span>
                      <Text size="sm">Letterboxes</Text>
                    </span>
                    <Title order={2}>
                      {state.letterBoxCountPAF?.letterbox_count_PAF || 0}
                    </Title>
                  </Stack>
                </Group>
              </Grid.Col>
              <Grid.Col span={6}>
                <Group spacing="xs" align={"flex-start"} position="right">
                  <span>
                    <Users size={19} strokeWidth={1} color={"black"} />
                  </span>
                  <Stack spacing="sm">
                    <span>
                      <Text size="sm">Population</Text>
                    </span>
                    <Title order={2}>{state.totalPopulation || 0}</Title>
                  </Stack>
                </Group>
              </Grid.Col>
            </Grid>
            <Divider />
            <Grid p="md" pb="lg" pt="lg" my={0}>
              <Grid.Col span={12}>
                <h3>People</h3>
              </Grid.Col>
              <ValueAndComparisonToAverage
                title={"Median Age"}
                value={state.summaryData?.median_age}
                stateAverageDiff={state.summaryData?.median_age_diff_from_state}
                nationalAverageDiff={state.summaryData?.median_age_diff_from_national}
                prefixChar={""}
                suffixChar={""}
              />
              <ValueAndComparisonToAverage
                title={"Weekly Household Income"}
                value={state.summaryData?.weekly_household_income}
                stateAverageDiff={state.summaryData?.median_income_diff_from_state}
                nationalAverageDiff={state.summaryData?.median_income_diff_from_national}
                prefixChar={"$"}
                suffixChar={""}
              />
              <ValueAndComparisonToAverage
                title={"Unemployment"}
                value={state.summaryData?.unemployment_for_area?.toFixed(2) || state.summaryData?.unemployment_percentage?.toFixed(2)}
                stateAverageDiff={state.summaryData?.unemployed_percentage_diff_from_state}
                nationalAverageDiff={state.summaryData?.unemployed_percentage_diff_from_national}
                prefixChar={""}
                suffixChar={"%"}
              />
              <ValueAndComparisonToAverage
                title={"University Degree"}
                value={state.summaryData?.uni_degree_percentage}
                stateAverageDiff={state.summaryData?.uni_degree_percentage_diff_from_state}
                nationalAverageDiff={state.summaryData?.uni_degree_percentage_diff_from_national}
                prefixChar={""}
                suffixChar={"%"}
              />
              <ValueAndComparisonToAverage
                title={"Indigenous"}
                value={state.summaryData?.percentage_aboriginals}
                stateAverageDiff={state.summaryData?.indigenous_diff_from_state}
                nationalAverageDiff={state.summaryData?.indigenous_diff_from_national}
                prefixChar={""}
                suffixChar={"%"}
              />

              <Grid.Col span={12}>
                <h3>Dwellings</h3>
              </Grid.Col>
              <ValueAndComparisonToAverage
                title={"Apartments"}
                value={state.summaryData?.percentage_appartments}
                stateAverageDiff={state.summaryData?.apartment_percentage_diff_from_state}
                nationalAverageDiff={state.summaryData?.apartment_percentage_diff_from_national}
                prefixChar={""}
                suffixChar={"%"}
              />
              <ValueAndComparisonToAverage
                title={"Rental Properties"}
                value={state.summaryData?.percentage_rental}
                stateAverageDiff={state.summaryData?.rentals_diff_from_state}
                nationalAverageDiff={state.summaryData?.rentals_diff_from_national}
                prefixChar={""}
                suffixChar={"%"}
              />
              <ValueAndComparisonToAverage
                title={"Dwellings Owned Outright"}
                value={state.summaryData?.owned_outright_percentage}
                stateAverageDiff={state.summaryData?.owned_outright_diff_from_state}
                nationalAverageDiff={state.summaryData?.owned_outright_diff_from_national}
                prefixChar={""}
                suffixChar={"%"}
              />
              <ValueAndComparisonToAverage
                title={"Dwellings Owned with Mortgage"}
                value={state.summaryData?.owned_with_a_mortgage_percentage}
                stateAverageDiff={state.summaryData?.owned_with_a_mortgage_diff_from_state}
                nationalAverageDiff={state.summaryData?.owned_with_a_mortgage_diff_from_national}
                prefixChar={""}
                suffixChar={"%"}
              />

              <Grid.Col span={12}>
                <h3>Languages</h3>
              </Grid.Col>
              {state.summaryData.languages &&
                state.summaryData.languages.length > 0 &&
                state.summaryData.languages.map(
                  (
                    language: { name: string; aggregate: number; state_average_diff: number; national_average_diff: number },
                    index: number
                  ) => (
                    <Fragment key={index}>
                      <ValueAndComparisonToAverage
                        title={language.name + " Speakers"}
                        value={language?.aggregate?.toFixed(2)}
                        stateAverageDiff={language?.state_average_diff}
                        nationalAverageDiff={language?.national_average_diff}
                        prefixChar={""}
                        suffixChar={"%"}
                      />
                    </Fragment>
                  )
                )}

              <Grid.Col span={12}>
                <h3>Travel to Work</h3>
              </Grid.Col>
              {state.summaryData.methodsOfTravel &&
                state.summaryData.methodsOfTravel.length > 0 &&
                state.summaryData.methodsOfTravel.map(
                  (
                    methodName: { name: string; aggregate: number; state_average_diff: number; national_average_diff: number },
                    index: number
                  ) => (
                    <Fragment key={index}>
                      <ValueAndComparisonToAverage
                        title={methodName.name}
                        value={methodName?.aggregate?.toFixed(2)}
                        stateAverageDiff={methodName?.state_average_diff}
                        nationalAverageDiff={methodName?.national_average_diff}
                        prefixChar={""}
                        suffixChar={"%"}
                      />
                    </Fragment>
                  )
                )}
            </Grid>
          </Aside>
          {/* To export the unsplit polygons, without displaying the modal: */}
          {/* <ExportMapModal polygonList={state.polygons} display={false}/> */}

        </>
      ) : (
        <div
          style={{
            height: `calc(100vh - var(--mantine-header-height, 0px) - ${elementHeight}px)`,
          }}
        ></div>
      )}
    </>
  );
};

export default SummaryBar;
