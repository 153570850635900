import {
  Dispatch,
  LatLngLiteral,
  PolygonCustomType,
  PolygonMouseEvent,
  State,
} from "../types";
import { fetchLetterBoxCount } from "./fetchLetterBoxCounts";
import { getBusinessLocations } from "./getBusinessLocations";
import { getSummaryData } from "./getSummaryData";
import { handlePolygonEdit } from "./handlePolygonEdit";
import { addTextInsideShape } from "./handleTextInsideShape";
import { showPolygonActionsModal } from "./showPolygonActionsModal";
import { polygonValidOrRemove } from "./polygonValidator";

const loadKMLFile = async (
  xmlDoc: XMLDocument,
  state: State,
  dispatch: Dispatch
) => {
  let decodedPaths: LatLngLiteral[][] = [];
  let kmlPolygons: PolygonCustomType[] = [];
  let polygonsDone: number = 0;
  let allPolygons: string = "";
  //   let mapPolygons: any = [];

  let placemarks: HTMLCollectionOf<Element> = xmlDoc.getElementsByTagName("Placemark");
  let multiGeometries: HTMLCollectionOf<Element> = xmlDoc.getElementsByTagName("MultiGeometry");
  let replacementString = placemarks.length > 0? "" : "\n";
  let kmlElementsToIterate: HTMLCollectionOf<Element> = placemarks.length > 0? placemarks : multiGeometries;
  for (let i = 0; i < kmlElementsToIterate.length; i++) {
    let item = kmlElementsToIterate[i];
    let polygons: any = item?.getElementsByTagName("Polygon");
    /** POLYGONS PARSE **/
    for (let j = 0; j < polygons.length; j++) {
      let polygon = polygons[j];
      let coords = polygon
        ?.getElementsByTagName("coordinates")[0]
        ?.childNodes[0]?.nodeValue.trim();
        //coords = coords.replace(/ /g, replacementString);
      let points = coords.split(/\s+/);
      let googlePolygonsPaths: LatLngLiteral[] = [];
      for (let k = 0; k < points.length; k++) {
        let point = points[k];
        let coord = point.split(",");
        googlePolygonsPaths.push({
          lat: parseFloat(coord[1]),
          lng: parseFloat(coord[0]),
        });
      }
      decodedPaths.push(googlePolygonsPaths);
      let mapPolygon: any = new google.maps.Polygon({
        paths: googlePolygonsPaths,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });
      if(polygonValidOrRemove(mapPolygon)) {
        let newPolygonEncodedPath = google.maps.geometry.encoding.encodePath(
          mapPolygon.getPath()
        );
        mapPolygon.id = newPolygonEncodedPath;
        const sameMapModel = state.polygons.find((polygon :any) => polygon.id === newPolygonEncodedPath);
        if (sameMapModel) return;
        mapPolygon.setMap(state.map);

        kmlPolygons.push(mapPolygon);
        //   mapPolygons.push({
        //     polygon: mapPolygon,
        //     data: { sa1_code: "0" },
        //   });
        window.polygons.push(mapPolygon);
        let polygonBounds = new google.maps.LatLngBounds();
        for (let i = 0; i < mapPolygon.getPath().getLength(); i++) {
          polygonBounds.extend(mapPolygon.getPath().getAt(i));
        }
        try {
          let response = await fetchLetterBoxCount(
            newPolygonEncodedPath,
            dispatch,
            mapPolygon.id
          );
          if (response) {
            addTextInsideShape(
              mapPolygon,
              polygonBounds.getCenter(),
              state.map,
              response["Residential count"] + response["Business exact count"]
            );
          }
        } catch (err) {
          console.log("error in fetching summary data", err);
        }

        polygonsDone++;
        dispatch({ type: "setPolygons", payload: mapPolygon });
        dispatch({ type: "setCheck", payload: true });
        if (allPolygons.length > 0) allPolygons += ",";
        allPolygons += newPolygonEncodedPath;
        if (polygonsDone === polygons.length) {
          let allPolygonsStr: string = "";
          window?.polygons.forEach(async function (pol: any) {
            if (allPolygonsStr.length > 0) allPolygonsStr += ",";
            allPolygonsStr += google.maps.geometry.encoding.encodePath(pol.getPath());
          });
          getSummaryData(mapPolygon, allPolygonsStr, state.map, dispatch, state);
          getBusinessLocations(allPolygonsStr, state.map, dispatch, state);
          dispatch({ type: "showSummarBar", payload: true });
        }
      }
    }
    kmlPolygons.forEach((polygon: any) => {
      polygon.addListener("click", () => {
        if (polygon.editable && state.map) {
          handlePolygonEdit(polygon, state?.map, dispatch, state);
        }
        return;
      });

      polygon.addListener("contextmenu", (event: PolygonMouseEvent) => {
        showPolygonActionsModal(polygon, dispatch, state, event.latLng!);
      });
    });
  }
};

export { loadKMLFile };
