import {
  Breadcrumbs,
  createStyles,
  Grid,
  LoadingOverlay,
  Space,
  Table,
  Text,
  TextInput,
  Menu,
} from "@mantine/core";
import axios from "axios";

import { useLocalStorage } from "../hooks/useLocalStorage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppShellLayout from "../components/AppShellLayout";
import { useAuth } from "../hooks/useAuth";
import { fetchUserSubscriptionDetails } from "../map-utils/fetchUserSubscriptionDetails";
import { SubscriptionDetail } from "../types";
import { reactivateOrCancelSubscription } from "../map-utils/reactivateOrCancelSubscription";
import { showNotification } from "@mantine/notifications";
import { Check, X, Settings as Settings2 } from "tabler-icons-react";
import EditProfileInfo from "../components/EditProfileInfo";

const useStyles = createStyles((theme) => ({
  button: {
    backgroundColor: "#FD7E14",
    minWidth: "160px",
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      width: "100%",
    },
  },
  tableHead: {
    backgroundColor: '#F4F7FC',
  },
  tableRow: {
    border: '2px solid #ccc',
    '& > *': {
      fontSize: '15px !important',
      padding: '10px 0',
      color: '#000 !important',
    }
  },
  settingDropDown: {
    position: "absolute",
    top: '90px',
    right: '180px',
  },
  settingIcon: {
    maxWidth: '530px',
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: 'space-between',
  },
  settingIconDetails: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  settingItems: {
    fontSize: '16px',
    fontWeight: 'normal',
    padding: '12px 12px',
    '&:hover': {
      background: '#F4F7FC'
    }
  },
  iconSetting: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  detailsTitle: {
    fontSize: '24px'
  }
}));

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];


const AddressesDownloadOrgReport = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [adminViewing, setAdminViewing] = useState<boolean>(false);
  const [adminViewingAnother, setAdminViewingAnother] = useState<boolean>(false);
  const { classes } = useStyles();

  const navigate = useNavigate();
  let startPeriod = new Date()
  const endPeriod = new Date();
  const dayOfMonth = startPeriod.getDate();
  startPeriod.setDate(dayOfMonth - 30);
  const [startDate, setStartDate] = useState<Value>(startPeriod);
  const [endDate, setEndDate] = useState<Value>(endPeriod);
  const [lastMonthCounter, setLastMonthCounter] = useState<number>(0);
  const [thisFYCounter, setThisFYCounter] = useState<number>(0);
  const [lastFYCounter, setLastFYCounter] = useState<number>(0);

  let formData = new FormData();
  axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}subscriptions/address_downloads_report/`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${user.key}`,
    },
  }).then((response) => {
    setLastMonthCounter(response.data.last_month_downloads_counter)
    setThisFYCounter(response.data.this_fy_downloads_counter)
    setLastFYCounter(response.data.last_fy_downloads_counter)
    console.log(response);
  }, (error) => {
    console.log(error);
  });

  // const [addressesDownloadList, setAddressesDownloadList] = useState<AddressesDownloadOrgReport>({ address_downloads_total: 0, address_downloads_by_organisation: [] });


  return (
    <AppShellLayout>
      <LoadingOverlay
        visible={loading}
        loaderProps={{ size: "xl", color: "orange" }}
        overlayOpacity={0.7}
      />
      <Grid mt={38} className="title-row">
        <Grid.Col sm={6} className="title-col">
          <Breadcrumbs
            styles={{
              breadcrumb: { fontSize: "45px", fontWeight: "bold" },
            }}
            separator=""
          >
            <Text size="lg">
              Address Downloads Report
            </Text>
          </Breadcrumbs>
        </Grid.Col>
      </Grid>

      <h2>Address Downloads Report</h2>
      <h3>Last 30 days:</h3>
      <h4>{lastMonthCounter} addresses displayed/downloaded</h4>

      <h3>Current financial year:</h3>
      <h4>{thisFYCounter} addresses displayed/downloaded</h4>

      <h3>Previous financial year:</h3>
      <h4>{lastFYCounter} addresses displayed/downloaded</h4>

      {adminViewing && (
        <div>
          <br />
          <h2>Find a Client</h2>
        </div>
      )}
      {/* {adminViewingAnother && (
        <div>
          <br />
          <Text size="xl" className={classes.detailsTitle}>
            Requested Account - Subscription Details
          </Text>

          <Space h="xl" />
          <Space h="xl" />
          <Space h="xl" />

        </div>
      )} */}
    </AppShellLayout>
  );
};

export default AddressesDownloadOrgReport;


