import { createStyles } from "@mantine/core";
import { useRef, useEffect } from "react";
import { useStore } from "../../hooks/useStore";

const useStyles = createStyles((theme) => ({
  searchInput: {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `42px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    left: "10px",
    top: "10px",
    borderRight: "1px solid #ccc",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    [`@media (max-width: 768px)`]: {
      width: `100% !important`,
    },
  },
}));
const SearchLocationComponent = () => {
  const searchRef = useRef(null);
  const { state } = useStore();
  const { classes } = useStyles();

  useEffect(() => {
    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      types: [],
      componentRestrictions: {country: "au"}
    };
    if (searchRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(
        searchRef.current,
        options
      );
      // autocomplete.bindTo("bounds", state.map);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
        if (place.geometry.viewport) {
          state.map.fitBounds(place.geometry.viewport);
        } else {
          state.map.setCenter(place.geometry.location);
          state.map.setZoom(17);
        }
      });
    }
  }, [searchRef, state.map]);

  return (
    <input type="search" ref={searchRef} className={classes.searchInput} />
  );
};

export default SearchLocationComponent;
