import { useState, useContext, useEffect } from 'react';
import axios from "axios";
import {
  Group,
  Text,
  Button,
  Textarea,
} from "@mantine/core";
import $ from 'jquery';
import storeContext from "../store/store";
import {getMapSA1CSV, getMapSummaryCSV } from "../map-utils/downloadSummary";

const AIChatWindow = (props: any) => {
  const context = useContext<any>(storeContext);
  const [interogationInput, setInterogationInput] = useState("");
  const [interogationSession, setInterogationSession] = useState([{ "role": "system", "content": "You are an Australia urban planning consultant."}]);
  const [interogationResponse, setInterogationResponse] = useState("");

  let input = document.getElementById("ai-chat-input") as HTMLInputElement;
  let sendButton = document.getElementById("ai-chat-send") as HTMLInputElement;

  async function sendInterogationMessage() {
    let sessionSoFar : { role: string; content: string; }[] = interogationSession;
    if(sessionSoFar.length == 1) {
      let summaryCSV = getMapSummaryCSV(context);
      sessionSoFar.push({ "role": "user", "content": 'In respect to the following CSV, describing demographics in an area of Australia, please answer in at least 50 characters, and preferably 200-300 characters, and don\'t mention the data being in a CSV in your answer.\n' + summaryCSV });  
    }
    sessionSoFar.push({ "role": "user", "content": interogationInput });
    setInterogationSession(sessionSoFar);
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        "messages": sessionSoFar,
        model: 'gpt-4-1106-preview',
        max_tokens: 100,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
      }
    );
    sessionSoFar.push(response.data.choices[0].message);
    setInterogationResponse(response.data.choices[0].message.content);
    let newElement = document.getElementById("ai-chat-content");
    if(newElement) {
      let currentInnerHTML = newElement.innerHTML;
      let newInnerHTML = currentInnerHTML +
        '<p>' + interogationInput + '</p>' +
        '<p style="color: grey;"><i>' + response.data.choices[0].message.content + '</i></p>'
      newElement.innerHTML = newInnerHTML;
    }
    setInterogationInput("");
    $('#ai-chat-content').scrollTop(10000);
  }  

  function inputChanged(newInput: string) {
    setInterogationInput(newInput)
    // input?.addEventListener("keydown", (e: any) => {
    //   if (e.key === "Enter") {
    //     setInterogationInput(newInput.slice(0, -1));
    //     sendButton.click();
    //   }
    // });
  }  

  return (
    <div>
      <Text size="xl" mb="xs">
        AI Interaction
      </Text>
      <Group align="flex-end">
        <div id="ai-chat-content" className="comments-box ai-chat-box"></div>
        <Textarea id="ai-chat-input" className="ai-chat-input" placeholder="Your question..." style={{ flex: 1 }} value={interogationInput} onChange={(event) => inputChanged(event.target.value)}/>
        <Button
          id="ai-chat-send"
          onClick={() => {
            sendInterogationMessage();
          }}>Send</Button>
      </Group>
    </div>
  );
};

export default AIChatWindow;
