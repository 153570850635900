import { fetchSa1sReport } from "./fetchSa1sReport";
import { getPolygonSA1s } from "./getPolygonSA1s";
import { loadSA1sOntoMap } from "./loadSA1sOntoMap";
import { loadFilters } from "./loadFilters";
// import { getPolygonClosureUid } from "../map-utils";

export const getSummaryData = (
  polygon: any,
  allPolygons: any,
  map: any,
  dispatch: any,
  state: any
  // edited?: boolean | undefined,
  // previousPath?: string | undefined
) => {
  let polygons = [];
  polygons.push(polygon);
  let polygonsDone = 0;

  if (polygons.length) {
    polygons.forEach(async function (polygon: any) {
      // unsplitPolygons.push(polygon);
      let newPolygonEncodedPath = google.maps.geometry.encoding.encodePath(
        polygon.getPath()
      );

      // drawingManager.setDrawingMode(null);
      if (++polygonsDone === polygons.length) {
        let { sa1s, languages_aggregate, travel_to_work_aggregate, area_with_complete_coverage } = await getPolygonSA1s(
          newPolygonEncodedPath,
          allPolygons
        );

        const topLanguages = [];
        for (let key of Object.keys(languages_aggregate)) {
          if (key !== "") {
            topLanguages.push({
              name: key,
              aggregate: languages_aggregate[key]['pecentage'],
              state_average_diff: languages_aggregate[key]['state_average_diff'],
              national_average_diff: languages_aggregate[key]['national_average_diff'],
            });
          }
        }

        const sortedLanguages = topLanguages
          .sort((a, b) => b.aggregate - a.aggregate)
          .slice(0, 3);

        sa1s.forEach((section: any) => {
          sortedLanguages.forEach((lang) => {
            if (!section[lang.name]) {
              section[lang.name] = 0;
            }
          });
        });

        ///////////////////////////////////////////////////
        const topMethodsOfTravel = [];
        for (let key of Object.keys(travel_to_work_aggregate)) {
          if (key !== "") {
            topMethodsOfTravel.push({
              name: key,
              aggregate: travel_to_work_aggregate[key]['pecentage'],
              state_average_diff: travel_to_work_aggregate[key]['state_average_diff'],
              national_average_diff: travel_to_work_aggregate[key]['national_average_diff'],
            });
          }
        }

        const sortedMethodsOfTravel = topMethodsOfTravel
          .sort((a, b) => b.aggregate - a.aggregate)
          .slice(0, 5);

        sa1s.forEach((section: any) => {
          sortedMethodsOfTravel.forEach((travelMethod) => {
            if (!section[travelMethod.name]) {
              section[travelMethod.name] = 0;
            }
          });
        });
        ///////////////////////////////////////////////////

        let polygonId: string = polygon.id;
        allPolygons.split(",").forEach((polygonPath: string) => {
          // let sa1sForSpecificPolygon: any[] = [];
          // sa1s.forEach((section: any) => {
          //   if (section.parent_id === polygonPath) {
          //     sa1sForSpecificPolygon.push(section);
          //   }
          // });
          let polygonId = polygonPath;
          loadSA1sOntoMap(
            sa1s,
            map,
            dispatch,
            state,
            polygonId,
            sortedLanguages,
            sortedMethodsOfTravel
            // edited
          );
        });

        loadFilters(sa1s, dispatch, sortedLanguages, sortedMethodsOfTravel, polygonId);
        let areaPortions = [];
        for (let singlePortion of sa1s) {
          areaPortions.push([
            singlePortion.sa1_code,
            singlePortion.intersected_percentage,
          ]);
        }
        // let polygonId: number = getPolygonClosureUid(polygon);

        dispatch({
          type: "setPolygonsArea",
          payload: {
            polygonId: polygonId,
            area: Math.round(
              google.maps.geometry.spherical.computeArea(polygon.getPath())
            ),
          },
        });

        // dispatch({ type: "setSa1Portions", payload: areaPortions });
        fetchSa1sReport(area_with_complete_coverage.length? [area_with_complete_coverage] : areaPortions)
        // fetchSa1sReport(areaPortions)
          .then((data) => {
            data.languages = sortedLanguages;
            data.methodsOfTravel = sortedMethodsOfTravel;
            dispatch({ type: "setSummaryData", payload: data });

            // dispatch({
            //   type: "setTotalSummaryData",
            //   payload: { polygonId: polygonId, summaryBarData: data },
            // });
          })
          .catch((err) => console.log("error", err))
          .finally(() => {
            dispatch({ type: "setSummaryDataLoadingDemographics", payload: false });
          });
      }
    });
  }
};
