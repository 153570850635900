import {
  Group,
  Checkbox,
  createStyles,
  RangeSlider,
  NumberInput,
  Stack,
  SimpleGrid,
} from "@mantine/core";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import storeContext from "../../store/store";

const useStyles = createStyles((theme) => ({
  sliderRoot: {
    maxWidth: 260,
    width: "100%",
  },
  sliderTrack: {
    backgroundColor: "#E9EAED",
    height: 5,
    borderRadius: 3,
  },
  sliderBar: {
    backgroundColor: "#FF8E16",
  },
  sliderThumb: {
    borderColor: "#FF8E16",
  },
  checkBox: {
    "&:checked": {
      backgroundColor: theme.colors.allColors[7],
      borderColor: theme.colors.allColors[7],
    },
  },
}));

function SliderGroup(props: any) {
  const { classes } = useStyles();
  const { dispatch } = useContext<any>(storeContext);
  const { value, changeValue, onChangeValue, groupName } = props;
  const { guid } = useParams();
  let range = value[1] - value[0];
  let step = 1;
  if (range > 100) step = 10;
  if (range < 10) step = 0.5;
  if (range < 3) step = 0.1;
  if (range < 1) step = 0.01;
  if (range < 0.3) step = 0.005;

  const [check, setCheck] = useState(props.defaultChecked || false);
  return (
    <Stack p="lg" pt="md">
      <Checkbox
        classNames={{
          input: `${classes.checkBox}`,
        }}
        size="sm"
        radius={0}
        label={props.title}
        checked={check}
        onChange={(e) => {
          onChangeValue([value[0], value[1]]);
          setCheck(e.currentTarget.checked);
          dispatch({
            type: "setFiltersDataToSave",
            payload: {
              title: props.title,
              set: e.currentTarget.checked,
              min: value[0],
              max: value[1],
            },
          });
          dispatch({
            type: "setFilterDataAlternative",
            payload: {
              title: props.propertyName,
              set: e.currentTarget.checked,
              minValue: value[0],
              maxValue: value[1],
              groupName: groupName,
            },
          });
        }}
        disabled={guid ? true : false}
      />
      {check && (
        <>
          <RangeSlider
            value={changeValue}
            size={"sm"}
            classNames={{
              root: `${classes.sliderRoot}`,
              track: `${classes.sliderTrack}`,
              bar: `${classes.sliderBar}`,
              thumb: `${classes.sliderThumb}`,
            }}
            min={value[0]}
            max={value[1]}
            step={step}
            label={props.sliderLabel}
            onChange={(e) => {
              onChangeValue(e);
              dispatch({
                type: "setFiltersDataToSave",
                payload: {
                  title: props.title,
                  set: check,
                  min: e[0],
                  max: e[1],
                },
              });
              dispatch({
                type: "setFilterDataAlternative",
                payload: {
                  title: props.propertyName,
                  set: check,
                  minValue: e[0],
                  maxValue: e[1],
                  groupName: groupName,
                },
              });
            }}
            minRange={1}
            disabled={guid ? true : false}
          />

          <SimpleGrid cols={2}>
            <NumberInput
              value={changeValue[0]}
              onChange={(e) => {
                onChangeValue([e, value[1]]);
                dispatch({
                  type: "setFiltersDataToSave",
                  payload: {
                    title: props.title,
                    set: check,
                    min: e,
                    max: value[1],
                  },
                });
                dispatch({
                  type: "setFilterDataAlternative",
                  payload: {
                    title: props.propertyName,
                    set: check,
                    minValue: e,
                    maxValue: value[1],
                    groupName: groupName,
                  },
                });
              }}
              disabled={guid ? true : false}
            />
            <NumberInput
              value={changeValue[1]}
              onChange={(e) => {
                onChangeValue([value[0], e]);
                dispatch({
                  type: "setFiltersDataToSave",
                  payload: {
                    title: props.title,
                    set: check,
                    min: value[0],
                    max: e,
                  },
                });
                dispatch({
                  type: "setFilterDataAlternative",
                  payload: {
                    title: props.propertyName,
                    set: check,
                    minValue: value[0],
                    maxValue: e,
                    groupName: groupName,
                  },
                });
              }}
              disabled={guid ? true : false}
            />
          </SimpleGrid>
        </>
      )}
    </Stack>
  );
}

export default SliderGroup;
