
import { Grid, Group, Text, createStyles } from '@mantine/core';
import { useContext, useState } from 'react';
import storeContext from '../../store/store';
import fetchData from './DataReference';

type Props = {
    heatmapSelected: string;
}

let CriteriasPrefix = {
    females: "",
    males: "",
    medianAge: "",
    income: "$",
    unemployed: "",
    uniDegree: "",
    unitPercentage: "",
    rentalProperties: "",
    ownedOutright: "",
    ownedWithMortgage: "",
    indigenous: "",
};
let CriteriasPostfix = {
    females: "%",
    males: "%",
    medianAge: "",
    income: "",
    unemployed: "%",
    uniDegree: "%",
    unitPercentage: "%",
    rentalProperties: "%",
    ownedOutright: "%",
    ownedWithMortgage: "%",
    indigenous: "%",
};

let Criterias = {
    females: "string",
    males: "string",
    medianAge: "string",
    income: "string",
    unemployed: "string",
    uniDegree: "string",
    unitPercentage: "string",
    rentalProperties: "string",
    ownedOutright: "string",
    ownedWithMortgage: "string",
    indigenous: "string",
};

const HeatMapKey = ({ heatmapSelected }: Props) => {
    const { state } = useContext<any>(storeContext);
    const { languages, methodsOfTravel } = state.filterData;
    const [HeatMapLabel, setHeatMapLabel] = useState<string>('');
    const [minBarValue, setMinBarValue] = useState<number | string>(0);
    const [maxBarValue, setMaxBarValue] = useState<number | string>(0);
    const [benchmarkedAverage, setBenchmarkedAverage] = useState<number>(50);
    //const [lowEndColour, setLowEndColour] = useState<string>("#fb4b4c");
    //const [highEndColour, setHighEndColour] = useState<string>("#d9d9d9");
    let prefix = CriteriasPrefix[heatmapSelected as keyof typeof Criterias] || "";
    let postfix = CriteriasPostfix[heatmapSelected as keyof typeof Criterias] || "";


    useState(async () => {
        // set heatmap label
        setHeatMapLabel(convertSelectionToTitle(heatmapSelected));

        // set min and max bar values
        let minMax = setMinMaxBarValues();

        // set benchmarked average
        setBenchmarkedAverage(getbenchmarkedAverage(heatmapSelected, minMax));
    })

    function convertSelectionToTitle(selection: string): string {
        const radioButtonMapping: { [key: string]: string } = {
            medianAge: 'Median Age',
            income: 'Weekly Household Income',
            unemployed: 'Unemployment',
            uniDegree: 'University Degree',
            unitPercentage: 'Apartments',
            rentalProperties: 'Rental Properties',
            ownedOutright: 'Dwellings Owned Outright',
            ownedWithMortgage: 'Dwellings Owned with Mortgage',
            indigenous: 'Indigenous',
        };

        let label = radioButtonMapping[selection];

        if (!label) {
            const language = languages?.find((language: any) => language.name === selection);
            const travelMethod = methodsOfTravel.find((travelMethod: any) => travelMethod.name === selection);
            if (language) {
                label = `${language.name} Speakers`;
            } else if (travelMethod) {
                label = `${travelMethod.name}`;
            }
        }
        return label || '';
    }

    function setMinMaxBarValues() {
        // set min and max bar values

        var min = Number.MAX_SAFE_INTEGER;

        var max = Number.MIN_SAFE_INTEGER;

        console.log(state.mapPolygons);

        state.mapPolygons.forEach((item: any) => {

            const polygon = item.polygon;

            if (item.data[state.heatmapValue] < min) {
                min = item.data[state.heatmapValue];
                console.log("Min Colour " + polygon.fillColor);
            }
            if (item.data[state.heatmapValue] > max) {
                max = item.data[state.heatmapValue];
                console.log("Max Colour " + polygon.fillColor);
            }
        });

        setMinBarValue(min);
        setMaxBarValue(max);

        return {minimum : min, maximum : max}
    }

    function getbenchmarkedAverage(selection: string, minMax: any): number {

        //fetch data
        const averageData: any = fetchData(state);

        let benchmarkedAverage = 0;

        // Map selection to the corresponding label
        const radioButtonMapping: { [key: string]: string } = {
            medianAge: 'medianAge',
            income: 'weeklyHouseholdIncome',
            unemployed: 'unemployment',
            uniDegree: 'uniDegree',
            unitPercentage: 'apartments',
            rentalProperties: 'rentals',
            ownedOutright: 'ownedOutright',
            ownedWithMortgage: 'ownedWithMortgage',
            indigenous: 'indigenous',
        };

        let label = radioButtonMapping[selection];

        // If the label exists in the averageData, return the state average
        if (label && averageData[label]) {
            if (state.benchmark === "state") {
                benchmarkedAverage = parseFloat(averageData[label].stateAvg);
            } else {
                benchmarkedAverage = parseFloat(averageData[label].nationalAvg);
            }
        }

        // If selection is a property of languages or methodsOfTravel
        // Find the language/travel method and return the state average
        const language = averageData.languages?.find((language: any) => language.name === selection)
        if (language) {
            if (state.benchmark === "state") {
                benchmarkedAverage = parseFloat(language.stateAvg);
            } else {
                benchmarkedAverage = parseFloat(language.nationalAvg);
            }
        }

        const travelMethod = averageData.travelToWork?.find((travelMethod: any) => travelMethod.name === selection);
        if (travelMethod) {
            if (state.benchmark === "state") {
                benchmarkedAverage = parseFloat(travelMethod.stateAvg);
            } else {
                benchmarkedAverage = parseFloat(travelMethod.nationalAvg);
            }
        }

        console.log("Benchmarked Average " + benchmarkedAverage);

        let avgPercentage = benchmarkedAverage / minMax.maximum * 100;

        console.log("Benchmarked Average Percentage " + avgPercentage);

        // return average as a percentage of the max value and clamp value
        return (avgPercentage > 100) ? 100 : (avgPercentage < 0) ? 0 : avgPercentage;
    }

    // custom styles for this component
    const useStyle = createStyles((theme) => ({
        row: {
            paddingBottom: 0,
            paddingTop: 5,
            paddingLeft: 0,
            paddingRight: 0,
        },
        polygonSvg: {
            width: 8,
            height: 8,
        },
        polygonSvgAvg: {
            width: 8,
            height: 8,
        },
        benchmarkedStateAverage: {
            display: "flex",
            alignItems: "center",
            gap: theme.spacing.xs,
            textAlign: "right",
            fontSize: theme.fontSizes.xs,
            color: theme.colors.dimgray,
        },
    }));

    const { classes } = useStyle();

    return (
        <Grid justify="space-between" style={{ backgroundColor: 'white', padding: 5, borderRadius: 8 }}>

            <Grid.Col span={12} className={classes.row}>
                <Text size='md' weight={500} >{HeatMapLabel}</Text>
            </Grid.Col>

            <Grid.Col span={12} className={classes.row}>
                <Group position="apart">
                    <Text size='md' weight="bold">{prefix + minBarValue + postfix}</Text>
                    <Text size='md' weight="bold">{prefix + maxBarValue + postfix}</Text>
                </Group>

                <div style={{ position: 'relative', width: '100%' }}>

                    <div style={{
                        position: 'absolute',
                        top: '-20px', // Adjust this value to move the arrow up or down
                        left: `${benchmarkedAverage}%`, // Adjust this value to move the arrow left or right
                        transform: 'translateX(-50%)'
                    }}>
                        <img src="/images/indicator-black.svg" alt="" />
                    </div>

                    <div style={{
                        background: `linear-gradient(90deg, ${"#fb4b4c"}, ${"#ffffff"})`,
                        height: '12px',
                        transform: 'rotate(180deg)',
                    }}></div>

                </div>


            </Grid.Col>

            <Grid.Col span={12} className={classes.row}>
                <Group position="right" className={classes.benchmarkedStateAverage}>
                    <img src="/images/indicator-grey.svg" alt="" className={classes.polygonSvg} />
                    <Text color='#646464' weight={300}>Benchmarked {state.benchmark} average</Text>
                </Group>
            </Grid.Col>

            <Grid.Col span={12} className={classes.row}>
                <Group position="apart">
                    <Text size='md'>Powered by</Text>
                    <img src="/images/scopomap-wide-light-1.svg" alt="" height='24' />
                </Group>
            </Grid.Col>
        </Grid>
    );
};

export default HeatMapKey;