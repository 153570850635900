import {
  Header,
  Group,
  Burger,
  createStyles,
  Image,
  Button,
  Breadcrumbs,
  Anchor,
  ActionIcon,
  Modal,
  TextInput,
} from "@mantine/core";
import { FC, useState, useContext, useEffect } from "react";
import { AdjustmentsHorizontal, Flame } from "tabler-icons-react";
import storeContext from "../store/store";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import useAnalyticsEventTracker from "../hooks/useGoogleAnalyticsTracker";

const useStyles = createStyles((theme) => ({
  header: {
    padding: theme.spacing.md,
    backgroundColor: theme.colors.allColors[2],
    height: "auto",
    maxHeight: "unset",
  },

  inner: {
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      height: "auto",
      flexWrap: "wrap",
      width: "100%",
      flex: "0 0 100%",
    },
  },
  headerInner: {
    maxWidth: "430px",
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      maxWidth: "300px",
    },
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      width: "100%",
      flex: "0 0 100%",
      marginBottom: "15px",
      maxWidth: "100%",
    },
  },
  innerGroup: {
    flex: "1",
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      gap: "10px",
    },
  },
  links: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
  titleText: {
    transform: "translateX(-50%)",
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      transform: "translateX(0%)",
    },
  },
  filterWrapper: {
    paddingLeft: "90px",
    gap: "0",
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      paddingLeft: "10px",
    },
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginLeft: "auto",
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingLeft: "0px",
    },
  },
  saveBtn: {
    maxWidth: "130px",
    [theme.fn.smallerThan("md")]: {
      maxWidth: "130px",
      marginLeft: "15px",
    },
    [theme.fn.smallerThan("xs")]: {
      maxWidth: "70px",
      marginLeft: "1px",
    },
  },
  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  filterIcon: {
    backgroundColor: "#333333",
    // backgroundColor: theme.colors.allColors[7],
    color: "white",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
    width: "60px",
    height: "32px",
    "&:hover": {
      backgroundColor: "#f76707",
      color: "white",
    },
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      width: "34px",
      height: "25px",
    },
  },
  activeClass: {
    backgroundColor: "#f76707",
  },
  flameIcon: {
    backgroundColor: "#333333",
    color: "white",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    width: "60px",
    height: "32px",
    "&:hover": {
      backgroundColor: "#f76707",
      color: "white",
    },
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      width: "34px",
      height: "25px",
    },
  },
  jobName: {
    width: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

interface HeaderMainProps {
  links: { link: string; label: string }[];
  toggleNavbar: () => void;
  toggleFiltersBar: () => void;
  toggleHeatMap: () => void;
  showLinks: boolean;
}
const HeaderMain: FC<HeaderMainProps> = ({
  links,
  toggleNavbar,
  toggleFiltersBar,
  toggleHeatMap,
  showLinks,
}) => {
  const [isFiltersBarActive, setFiltersBarActive] = useState(false);
  const [isHeatMapActive, setHeatMapActive] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [saveMapName, setSaveMapName] = useState<string>("");
  const { classes } = useStyles();

  const { state, dispatch } = useContext<any>(storeContext);
  const { user, teamName } = useAuth();
  const { guid } = useParams();
  const gaEventTracker = useAnalyticsEventTracker("Home Page");
  const isDrawingNewPolygon = !(state.jobId > 0 && state.jobId.length > 0);

  useEffect(() => {
    if(!isFiltersBarActive && Object.keys(state.filterDataAlternative).length > 0) {
      setFiltersBarActive(() => true);
      setHeatMapActive(false);
      toggleFiltersBar();
    }
  }, [state.filterDataAlternative]);

  const openSaveModal = () => {
    setSaveMapName(state.jobName);
    setOpenModal(true);
  };

  const removeInfoWindowFromMapPolygons = () => {
    state.mapPolygons.forEach((polygon: any)=> {
      const pPolygon = polygon.polygon;
      delete pPolygon.infoWindow;
    })
  }

  const saveMap = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenModal(false);
    if (state.polygons.length || state.annotations.length) {
      let polygonsToSave = state.polygons;
      let encodedCoords: any[] = [];
      polygonsToSave.forEach((polygon: any) => {
        encodedCoords.push(
          google.maps.geometry.encoding.encodePath(polygon.getPath())
        );
      });

      let annotationList: any[] = [];
      state.annotations.forEach((annotation: any) => {
        let annotationCoordinates = annotation["marker"].getPosition();
        annotationList.push({
          id: 0,
          lat: annotationCoordinates.lat(),
          lng: annotationCoordinates.lng(),
          comment: annotation["comment"],
        });
      });

      try {
        let formData = new FormData();
        let filtersData: any = {};
        let languages: any[] = [];

        filtersData.languages = languages;

        Object.keys(state.filterChangeData).find((key: any) => {
          state.filtersDataToSave.forEach((item: string) => {
            let label = item.split(" ");
            let firstPart: string = label[0]?.toLowerCase();
            let secondPart: string = label[1]?.toLowerCase();
            let key1 = key.toLowerCase();
            if (item === "Weekly Income" || item === "Uni Degree") {
              if (key1.includes(secondPart)) {
                filtersData[key] = state.filterChangeData[key];
              }
              return;
            }

            if (item === "Units") {
              if (key1.includes("percentage")) {
                filtersData[key] = state.filterChangeData[key];
              }
              return;
            }

            if (key1.includes(firstPart)) {
              filtersData[key] =
                state.filterChangeData[key] ||
                state.filterChangeData?.languages[key];
            }

            if (key1 === "languages") {
              let langName = item.split(" ")[0];
              state.filterChangeData[key].forEach(
                (lang: {
                  name: string;
                  minValue: number;
                  maxValue: number;
                }) => {
                  if (lang.name === langName) {
                    languages.push(lang);
                  }
                }
              );
            }
            return null;
          });
          return null;
        });
        formData.append("job_id", state.jobId || 0);
        formData.append("job_name", saveMapName);
        formData.append("encoded_polygons", encodedCoords.join(","));
        formData.append("annotationList", JSON.stringify(annotationList));
        // formData.append("filter_data", JSON.stringify(filtersData));
        formData.append("filter_data", JSON.stringify(state.filterDataAlternative));

        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}save_job/`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${user.key}`,
          },
        });
        if(state.jobId != response.data.job_id)
          dispatch({ type: "addJobId", payload: response.data.job_id });
        try {
          let hrefParts = window.location.href.split("/");
          if (
            hrefParts?.length < 4 ||
            parseInt(hrefParts[3]) !== response.data.job_id
          )
            window.location.href = "/" + response.data.job_id.toString();
        } catch (e) {
          console.log(e);
        }

        let now = new Date();
        dispatch({
          type: "addUserMap",
          payload: {
            created: now,
            created_by: user.username,
            edited: now,
            id: response.data.job_id,
            job_name: saveMapName,
            last_edited_by: user.username,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <Modal
        opened={openModal}
        onClose={() => setOpenModal(false)}
        title="Save This Map"
      >
        <form 
          onSubmit={saveMap}
          style={{ display: "flex", width: "100%" }}
          >
          <TextInput
            value={saveMapName}
            onChange={(e) => setSaveMapName(e.target.value)}
            sx={{ width: "80%" }}
            placeholder="Map Name"
            required
          />
          <Button
            type="submit"
            color="#FF8E16"
            sx={(theme) => ({
              backgroundColor: theme.colors.allColors[7],
              "&:hover": {
                backgroundColor: "#f76707",
              },
            })}
          >
            Save
          </Button>
        </form>
      </Modal>
      <Header
        height={60}
        className={classes.header}
        fixed={false}
        id="header-main"
      >
        <div className={classes.inner}>
          <Group className={`${classes.innerGroup} ${classes.headerInner} `}>
            <Burger
              opened={false}
              onClick={() => {
                toggleNavbar();
              }}
              size="sm"
              color="white"
              id="toggle-menu"
            />
            <div className="logo-wrapper" onClick={()=>{
              if (!isDrawingNewPolygon) {
                dispatch({type: 'resetJobId'});
                dispatch({type: 'resetSummaryStats'});
              }
            }}>
              <Link to="/">
                <Image
                  src={
                    process.env.REACT_APP_PUBLIC_FOLDER +
                    "Scopomap_Wide_Dark.svg"
                  }
                  alt="scopomap-logo"
                />
              </Link>
            </div>
            {showLinks && (
              <Group className={classes.filterWrapper} position="center">
                <ActionIcon
                  className={`${classes.filterIcon}  ${
                    isFiltersBarActive ? classes.activeClass : ""
                  }`}
                  onClick={() => {
                    setFiltersBarActive(() => !isFiltersBarActive);
                    setHeatMapActive(false);
                    toggleFiltersBar();
                    gaEventTracker("toggling filters", "toggling filters");
                    removeInfoWindowFromMapPolygons();
                  }}
                >
                  <AdjustmentsHorizontal />
                </ActionIcon>
                <ActionIcon
                  className={`${classes.flameIcon}  ${
                    isHeatMapActive ? classes.activeClass : ""
                  }`}
                  onClick={() => {
                    setHeatMapActive(() => !isHeatMapActive);
                    setFiltersBarActive(false);
                    toggleHeatMap();
                  }}
                >
                  <Flame />
                </ActionIcon>
              </Group>
            )}
          </Group>
          {showLinks && (
            <>
              <Group className={classes.titleText} position="center">
                <Breadcrumbs
                  styles={{
                    // root: {
                    //   width: "100px",
                    //   overflow: "hidden",
                    //   textOverflow: "ellipsis",
                    // },
                    breadcrumb: { color: "#fff" },
                    separator: { color: "#fff" },
                  }}
                >
                  <Anchor href="/team" key="1">
                    {/* Sydney City Council */}
                    {teamName?.length > 20
                      ? teamName.slice(0, 21) + "..."
                      : teamName}
                  </Anchor>
                  <Anchor
                    className={"test"}
                    href="#"
                    key="2"
                    sx={(theme) => ({
                      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
                        width: "70px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    })}
                  >
                    {state.jobName?.length > 20
                      ? state.jobName?.slice(0, 21) + "..."
                      : state.jobName}
                    {/* Inner West Boundary Map */}
                  </Anchor>
                </Breadcrumbs>
              </Group>

              <Group
                className={`${classes.innerGroup} ${classes.saveBtn} `}
                position="right"
              >
                {guid ? null : (
                  <Button
                    type="submit"
                    id="save-btn"
                    color="#FF8E16"
                    sx={(theme) => ({
                      backgroundColor: theme.colors.allColors[7],
                      "&:hover": {
                        backgroundColor: "#f76707",
                      },
                      minWidth: "130px",
                    })}
                    onClick={() => openSaveModal()}
                  >
                    Save
                  </Button>
                )}
              </Group>
            </>
          )}
        </div>
      </Header>
    </>
  );
};

export default HeaderMain;
