import drawCircleDisplayingRadius from "../../map-utils/drawCircleDisplayingRadius";
import SearchLocationComponent from "./SearchLocationComponent";
import { useStore } from "../../hooks/useStore";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { ActionIcon, Box } from "@mantine/core";
import { Circle, MessageDots, Polygon, Line, ArrowLeftCircle } from "tabler-icons-react";
import { fetchLetterBoxCount } from "../../map-utils/fetchLetterBoxCounts";
import { getSummaryData } from "../../map-utils/getSummaryData";
import { getBusinessLocations } from "../../map-utils/getBusinessLocations";
import { MeasureTool, selectAll } from "../../map-utils/MeasureTool";
import dayjs from "dayjs";
import { addTextInsideShape } from "../../map-utils/handleTextInsideShape";
import { PolygonMouseEvent } from "../../types";
import { showPolygonActionsModal } from "../../map-utils/showPolygonActionsModal";
import { handlePolygonEdit } from "../../map-utils/handlePolygonEdit";
import { polygonValidOrRemove } from "../../map-utils/polygonValidator";
import { closePreviouslyOpenedInfowindow, createAnnotationInfoWindow, openNewlyCreatedInfowindow } from "../../map-utils/infoWindow";
import { displayListOfAnnotations, saveMapAnnotation } from "../../map-utils/mapAnnotation";
import { Polyline } from "@react-google-maps/api";
import BufferOptionsModal from "./BufferOptionsModal";
import { createBufferPolygon, smoothPolyline } from "./AdvancedToolingUtils.tsx/BufferLineUtils";
import TutorialModal from "./AdvancedToolingUtils.tsx/TutorialModal";
import { set } from "react-ga";

var relativeTime: any = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

window.prevInfowindow = false;
const markers: any = [];
let annotations: any = [];
function DrawingControlls() {
  const { state, dispatch } = useStore();
  const [circleActive, setCircleActive] = useState(false);
  const [polygonActive, setPolygonActive] = useState(false);
  const [commentActive, setCommentActive] = useState(false);
  const [rulerActive, setRulerActive] = useState(false);
  const [measureTool, setMeasureTool] = useState<any>(null);
  const [radialLineToolActive, setRadialLineToolActive] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [polylinePath, setPolylinePath] = useState<google.maps.LatLng[]>([]);
  const [polygonModalOpened, setPolygonModalOpened] = useState(false);
  const [circleModalOpened, setCircleModalOpened] = useState(false);
  const [rulerModalOpened, setRulerModalOpened] = useState(false);
  const [commentModalOpened, setCommentModalOpened] = useState(false);
  const [radialLineModalOpened, setRadialLineModalOpened] = useState(false);


  const { user } = useAuth();
  let drawListener: any;
  const map = state.map;

  const measureToolStep = useCallback(
    (e: any) => {
      let clickEvent = google.maps.event.addListener(state.map, "click", () => {
        selectAll("circle.head-circle").on("click", () => {
          if (e._geometry._nodes.length === 2) {
            e._overlay.setMap(null);
            e.end();
            google.maps.event.removeListener(clickEvent);
            e.start();
          }
        });
      });
    },
    [state.map]
  );

  useEffect(() => {
    if (state.map) {
      let measureTools = new MeasureTool(state.map, {
        unit: MeasureTool.UnitTypeId.METRIC,
        contextMenu: false,
      });

      measureTools.addListener("measure_change", () =>
        measureToolStep(measureTools)
      );

      setMeasureTool(measureTools);
    }
  }, [state.map, measureToolStep]);

  useEffect(() => {
    let bounds = new google.maps.LatLngBounds();

    state.polygons.forEach((p: any) => {
      for (let i = 0; i < p.getPath().getLength(); i++) {
        bounds.extend(p.getPath().getAt(i));
        map.fitBounds(bounds);
        map.setCenter(bounds.getCenter());
      }
    });
  }, [state.polygons, map]);

  const drawingMode = (center: any) => {
    drawCircleDisplayingRadius(
      center,
      setCircleActive,
      map,
      reset,
      state.drawingManager,
      state.polygons,
      dispatch,
      state
    );
  };

  const reset = () => {
    map.setOptions({
      draggableCursor: "",
      draggable: "true",
    });

    if (drawListener) {
      google.maps.event.removeListener(drawListener);
    }
  };

  const drawCircle = () => {
    setCircleActive(true);
    setCommentActive(false);
    setPolygonActive(false);
    setRulerActive(false);
    setRadialLineToolActive(false);

    /* if (state.drawingToolFirstTime.radius === true) {
      // if the user has already used the tool before, then we don't need to show the modal
      // and can just start drawing the circle, otherwise we show the tutorial modal
      setCircleModalOpened(true);
      dispatch({ type: "setDrawingToolFirstTime", payload: { radius: false } });
    } */

    reset();
    map.setOptions({
      draggableCursor: "crosshair",
    });
    drawListener = map.addListener("mousedown", (e: any) => drawingMode(e));
  };

  const handleCompletedShape = (shape: any) => {
    setPolygonActive(false);
    state.drawingManager.setDrawingMode(null);

    if (polygonValidOrRemove(shape)) {
      dispatch({ type: "setPolygons", payload: shape });
      dispatch({ type: "showSummarBar", payload: true });
      window.polygons.push(shape);
      let newEncodedPath = google.maps.geometry.encoding.encodePath(shape.getPath());
      shape.id = newEncodedPath;

      // code for fetching letterboxes data and adding it into polygon
      var polygonBounds = new google.maps.LatLngBounds();
      for (let i = 0; i < shape.getPath().getLength(); i++) {
        polygonBounds.extend(shape.getPath().getAt(i));
      }
      fetchLetterBoxCount(newEncodedPath, dispatch, shape.id)
        .then((response: any) => {
          if (response) {
            addTextInsideShape(
              shape,
              polygonBounds.getCenter(),
              map,
              response["Residential count"] + response["Business exact count"]
            );
          }
          return;
        })
        .catch((err) => console.log("error in fetching summary data", err));

      let allPolygons: any = newEncodedPath;
      state.polygons.forEach(async function (pol: any) {
        allPolygons += ",";
        allPolygons += google.maps.geometry.encoding.encodePath(
          pol.getPath()
        );
      });
      getSummaryData(shape, allPolygons, map, dispatch, state);
      getBusinessLocations(allPolygons, map, dispatch, state);
      // polygonCompleteHandler(e, map, state.drawingManager, dispatch);

      shape.addListener("click", () => {
        if (shape.editable) {
          handlePolygonEdit(shape, map, dispatch, state);
        }
        return;
      });

      shape.addListener("contextmenu", (event: { latLng: google.maps.LatLng; }) => {
        showPolygonActionsModal(shape, dispatch, state, event.latLng);
      });
    }
  };


  const drawPolygon = () => {
    google.maps.event.clearListeners(map, "mousedown");
    setPolygonActive(true);
    setCircleActive(false);
    setCommentActive(false);
    setRulerActive(false);
    setRadialLineToolActive(false);
    map.setOptions({
      draggableCursor: "crosshair",
    });
    state.drawingManager.setDrawingMode(
      google.maps.drawing.OverlayType.POLYGON
    );
    state.drawingManager.setOptions({
      polygonOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      }
    });


    /* if (state.drawingToolFirstTime.polygon == true) {
      // if the user has already used the tool before, then we don't need to show the modal
      setPolygonModalOpened(true);
      dispatch({ type: "setDrawingToolFirstTime", payload: { polygon: false } });
    } */


    google.maps.event.addListenerOnce(
      state.drawingManager,
      "polygoncomplete",
      (e: any) => {
        setPolygonActive(false);
        state.drawingManager.setDrawingMode(null);
        if (polygonValidOrRemove(e)) {
          dispatch({ type: "setPolygons", payload: e });
          dispatch({ type: "showSummarBar", payload: true });
          window.polygons.push(e);
          let newPolygonEncodedPath = google.maps.geometry.encoding.encodePath(
            e.getPath()
          );
          e.id = newPolygonEncodedPath;

          // code for fetching letterboxes data and adding it into polygon
          var polygonBounds = new google.maps.LatLngBounds();
          for (let i = 0; i < e.getPath().getLength(); i++) {
            polygonBounds.extend(e.getPath().getAt(i));
          }
          fetchLetterBoxCount(newPolygonEncodedPath, dispatch, e.id)
            .then((response: any) => {
              if (response) {
                addTextInsideShape(
                  e,
                  polygonBounds.getCenter(),
                  map,
                  response["Residential count"] + response["Business exact count"]
                );
              }
              return;
            })
            .catch((err) => console.log("error in fetching summary data", err));

          let allPolygons: any = newPolygonEncodedPath;
          state.polygons.forEach(async function (pol: any) {
            allPolygons += ",";
            allPolygons += google.maps.geometry.encoding.encodePath(
              pol.getPath()
            );
          });
          getSummaryData(e, allPolygons, map, dispatch, state);
          getBusinessLocations(allPolygons, map, dispatch, state);
          // polygonCompleteHandler(e, map, state.drawingManager, dispatch);
          e.addListener("click", () => {
            if (e.editable) {
              handlePolygonEdit(e, map, dispatch, state);
            }
            return;
          });

          e.addListener("contextmenu", (event: PolygonMouseEvent) => {
            showPolygonActionsModal(e, dispatch, state, event.latLng!);
          });
        }
      }
    );
    reset();
  };

  const drawMarker = () => {
    if (state.jobId <= 0 || state.jobId === undefined)
      return;
    google.maps.event.clearListeners(map, "mousedown");
    setCommentActive(true);
    setCircleActive(false);
    setPolygonActive(false);
    setRulerActive(false);
    setRadialLineToolActive(false);

    /* if (state.drawingToolFirstTime.comment === true) {
      // if the user has already used the tool before, then we don't need to show the modal
      setCommentModalOpened(true);
      dispatch({ type: "setDrawingToolFirstTime", payload: { comment: false } });
    } */

    let newAnnotation = true;
    let annotationId: number | undefined = 0;
    state.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
    google.maps.event.addListener(
      state.drawingManager,
      "markercomplete",
      (e: any) => {
        setCommentActive(false);
        let now = dayjs();
        dispatch({
          type: "addMarkers",
          payload: {
            marker: e,
          },
        });
        dispatch({
          type: "addAnnotations",
          payload: {
            id: annotationId,
            owner: user.username,
            marker: e,
            comment: "",
          },
        });
        let newElement: HTMLInputElement | null = document.getElementById(
          "annotation-input"
        ) as HTMLInputElement;
        window.sendAnnotation = async function sendAnnotation(
          currentAnnotationId?: any
        ) {
          let annotationIndex = annotations.findIndex(
            (annotation: any) => annotation.annotationId === currentAnnotationId
          );
          let commentInputs =
            window.document.querySelectorAll("#annotation-input");

          let input = commentInputs[
            commentInputs.length - 1
          ] as HTMLInputElement;

          let newCommentTexts = window.document.querySelectorAll("#new-comment-text");
          let newCommentText = newCommentTexts[newCommentTexts.length - 1] as HTMLInputElement;
          let newAnnotationTimes = window.document.querySelectorAll("#new-annotation-time");
          let newAnnotationTime = newAnnotationTimes[newAnnotationTimes.length - 1] as HTMLInputElement;

          if (state.jobId > 0 && input?.value.trim().length > 0) {
            let annotationCoordinates = e.getPosition();

            try {
              let formData = new FormData();
              formData.append(
                "annotation_id",
                JSON.stringify(
                  currentAnnotationId ? currentAnnotationId : annotationId
                )
              );
              formData.append("comment", input.value);
              formData.append(
                "owner",
                newAnnotation ? user.username : user.user_id
              );
              if (newAnnotation) {
                formData.append("job_id", state.jobId);
                formData.append("lat", annotationCoordinates.lat());
                formData.append("lng", annotationCoordinates.lng());

                const response = await axios({
                  method: "post",
                  url: `${process.env.REACT_APP_BASE_URL}save_annotation_thread/`,
                  data: formData,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `token ${user.key}`,
                  },
                });
                newCommentText.innerHTML = input.value;
                input.value = "";
                newAnnotationTime.innerHTML = "a few seconds ago";

                newAnnotation = false;
                annotationId = response?.data?.annotation_id;
                infowindow.annotationId = response?.data?.annotation_id;
                annotations.push({ annotationId: annotationId, comments: [] });
                let commentText =
                  document.getElementsByClassName("comment-text");
                if (input.value.trim().length > 0) {
                  commentText[0].textContent = input.value;
                }
                input.value = "";
              } else {
                const response = await saveMapAnnotation(formData, user.key);
                if (response.status === 200) {
                  if (input.value.trim().length > 0) {
                    let commentsHtml = "";
                    displayListOfAnnotations(user, input, annotations, annotationIndex, commentsHtml);
                  }
                }
                input.value = "";
              }
            } catch (e) {
              console.log("error", e);
            }
          }
          if (annotationIndex !== -1) {
            markers[annotationIndex].close();
          }
          infowindow.close();
        };
        let commentsHtml = "";
        let currentAnnotationId: any = 0;
        const { infowindow } = createAnnotationInfoWindow(commentsHtml, user);
        infowindow.annotationId = annotationId;
        markers.push(infowindow);
        closePreviouslyOpenedInfowindow(infowindow);
        openNewlyCreatedInfowindow(infowindow, map, e);
        google.maps.event.addListener(map, "click", () => infowindow.close());
        infowindow.addListener("domready", () => {
          newElement = document.getElementById(
            "annotation-input"
          ) as HTMLInputElement;
          let sendBtn = document.getElementById(
            "send-btn"
          ) as HTMLButtonElement;
          newElement?.addEventListener("keydown", (e) => {
            if (e.key === "Enter") {
              var clickEvent = new MouseEvent("click", {
                "view": window,
                "bubbles": true,
                "cancelable": false
              });
              sendBtn?.dispatchEvent(clickEvent);
            }
          });
          sendBtn?.addEventListener("click", () => {
            let commentInputs = window.document.querySelectorAll("#annotation-input");
            let input = commentInputs[commentInputs.length - 1] as HTMLInputElement;

            if (state.jobId > 0 && input?.value.trim().length > 0) {
              window.sendAnnotation(currentAnnotationId);
            }

            infowindow.close();
            commentsHtml = "";
          });
          annotationId = infowindow.annotationId;
        });
        window.onChangeHandler = function () {
          let commentInputs =
            window.document.querySelectorAll("#annotation-input");
          let sendButtons =
            window.document.querySelectorAll("#send-btn");

          let input = commentInputs[
            commentInputs.length - 1
          ] as HTMLInputElement;
          let sendButton = sendButtons[
            commentInputs.length - 1
          ] as HTMLInputElement;
          if (input?.value.trim().length > 0) {
            dispatch({
              type: "addAnnotations",
              payload: {
                id: annotationId,
                owner: user.username,
                marker: e,
                comment: input?.value,
              },
            });
            sendButton.disabled = false;
          }
          else
            sendButton.disabled = true;
        }
        e.addListener("click", () => {
          annotationId = infowindow.annotationId;
          currentAnnotationId = infowindow.annotationId;
          if (window.prevInfowindow) {
            window.prevInfowindow.close();
            google.maps.event.clearListeners(infowindow, "domready");
          }
          window.prevInfowindow = infowindow;
          infowindow.open({
            anchor: e,
            map,
            shouldFocus: true,
          });
        });
        state.drawingManager.setDrawingMode(null);
        google.maps.event.clearListeners(
          state.drawingManager,
          "markercomplete"
        );
      }
    );
  };


  const handleCreateBufferPolygon = (bufferRadius: any, smoothing: any) => {
    // Call the function to create the buffer polygon with additional options
    console.log('Creating buffer polygon with:', { bufferRadius, smoothing });

    // if smoothing is enabled, create a smooth polyline first and then create a buffer polygon
    if (smoothing) {
      const smoothedPolyline = smoothPolyline(polylinePath, map);
      const smoothedPath = smoothedPolyline.getPath().getArray();
      const bufferPolygon = createBufferPolygon(smoothedPath, bufferRadius, map);
      handleCompletedShape(bufferPolygon);
      setRadialLineToolActive(false);
      return;
    }

    const bufferPolygon = createBufferPolygon(polylinePath, bufferRadius, map);
    handleCompletedShape(bufferPolygon);
    setRadialLineToolActive(false);
  };


  const drawRadialLine = () => {
    // Start by deactivating any active tool and setting cursors appropriately.
    google.maps.event.clearListeners(map, "mousedown");
    setPolygonActive(false);
    setCircleActive(false);
    setCommentActive(false);
    setRulerActive(false);
    setRadialLineToolActive(true);
    map.setOptions({
      draggableCursor: "crosshair",
    });

    // check if the user has used the tool before in local storage
    if (!localStorage.getItem('radialLine')) {
      // if the user has not used the tool before, then we show the tutorial modal
      setRadialLineModalOpened(true);
      // update local storage to indicate that the user has used the tool before
      localStorage.setItem('radialLine', 'true');
    }


    // Activate the drawing manager with the polyline drawing mode.
    state.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYLINE);
    state.drawingManager.setOptions({
      polylineOptions: {
        strokeColor: "#FF0000", // Customize the appearance of the polyline.
        strokeOpacity: 0.8,
        strokeWeight: 2,
      }
    });

    // Listen for the 'polylinecomplete' event.
    google.maps.event.addListener(state.drawingManager, 'polylinecomplete', (polyline: any) => {
      // Once a polyline is drawn, you can do something with it, such as storing its path.
      const linePath = polyline.getPath().getArray();
      setPolylinePath(linePath); // You might need to adapt this for your specific state management.

      polyline.setMap(null); // Remove the polyline from the map.

      // Assuming you have a function to open a modal or perform an action after the polyline is complete.
      setModalOpened(true);

      // Clear the drawing mode to prevent continuous drawing.
      state.drawingManager.setDrawingMode(null);
    });

    reset();
  };


  const measureDistance = () => {
    google.maps.event.clearListeners(map, "mousedown");
    setRulerActive(true);
    setCircleActive(false);
    setCommentActive(false);
    setPolygonActive(false);
    setRadialLineToolActive(false);
    measureTool.start();

    /* if (state.drawingToolFirstTime.measure === true) {
      // if the user has already used the tool before, then we don't need to show the modal
      setRulerModalOpened(true);
      dispatch({ type: "setDrawingToolFirstTime", payload: { measure: false } });
    } */

  };

  return (

    <Box
      sx={{
        display: "flex",
        position: "absolute",
        top: "0.6rem",
        left: "0.6rem",
        boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
        [`@media (max-width: 768px)`]: {
          top: "0rem",
          left: "0rem",
          width: "100%",
          background: "#fff",
        },
      }}
    >

      {/* 
      <TutorialModal
        opened={polygonModalOpened}
        setOpened={setPolygonModalOpened}
        title="Polygon Tool Tutorial"
        animationSrc="/path-to-polygon-tutorial.gif"
        instructions={[
          'Click on the map to start drawing a polygon.',
          'Continue clicking to add more points.',
          'Click on the first point or double-click to complete the polygon.',
          'Once the polygon is complete, you can right-click on the polygon to see more options.'
        ]}
      />

      <TutorialModal
        opened={circleModalOpened}
        setOpened={setCircleModalOpened}
        title="Circle / Radius Tool Tutorial"
        animationSrc="/path-to-circle-tutorial.gif"
        instructions={[
          'Click on the map to place the center of the circle.',
          'Drag your cursor away from the center to expand the radius of the circle.',
          'Release the mouse button to complete the circle.',
          'Once the circle is complete, you can right-click on the circle to see more options.'
        ]}
      />

      <TutorialModal
        opened={rulerModalOpened}
        setOpened={setRulerModalOpened}
        title="Measuring Tool Tutorial"
        animationSrc="/path-to-measuring-tutorial.gif"
        instructions={[
          'Click on the starting point on the map to begin measuring.',
          'Continue clicking to add waypoints and measure the distance between them.',
          'Double-click to finish measuring a path.',
          'The distance will be displayed along the line.'
        ]}
      />

      <TutorialModal
        opened={commentModalOpened}
        setOpened={setCommentModalOpened}
        title="Comment Tool Tutorial"
        animationSrc="/path-to-comment-tutorial.gif"
        instructions={[
          'Click on the map where you want to place the comment.',
          'A comment icon will appear, and a text box will open.',
          'Type your message into the text box.',
          'This feature is available for saved maps where you can view and edit comments later.'
        ]}
      />
      */}

      <TutorialModal
        opened={radialLineModalOpened}
        setOpened={setRadialLineModalOpened}
        title="Radial Line Tool Tutorial"
        animationSrc="/path-to-radialLine-tutorial.gif"
        instructions={[
          'Click to start drawing the line from the starting point of your alignment line.',
          'Keep clicking to add more points to the line.',
          'Double-click to finish drawing the line.',
          'A box will pop up asking for the radius and other options.',
          'Enter the radius size in the input box.',
          'Check the "Apply smoothing" option if you want the software to apply smoothing to your line.',
          'Click "Confirm" to apply.'
        ]}
      />

      <BufferOptionsModal
        opened={modalOpened}
        setOpened={setModalOpened}
        onConfirm={handleCreateBufferPolygon}
      />

      <SearchLocationComponent />
      <div
        id="drawing-tools"
        style={{
          display: "flex",
          alignItems: "center",
          background: "#fff",
        }}
      >
        <div style={{ margin: " 0rem .5rem" }} onClick={drawPolygon} title={'Draw a Polygon'}>
          <ActionIcon>
            <Polygon
              size={25}
              strokeWidth={1.5}
              color={polygonActive ? "#FF8E16" : "black"}
            />
          </ActionIcon>
        </div>
        <div style={{ margin: "0rem .5rem" }} onClick={drawCircle} title={'Draw a Radius'}>
          <ActionIcon>
            <Circle
              size={25}
              strokeWidth={1.5}
              color={circleActive ? "#FF8E16" : "black"}
            />
          </ActionIcon>
        </div>
        <div style={{ margin: "0rem .5rem" }} onClick={measureDistance} title={'Distance Measuring Tool'}>
          <ActionIcon>
            <Line
              size={25}
              strokeWidth={1.5}
              color={rulerActive ? "#FF8E16" : "black"}
            />
          </ActionIcon>
        </div>
        <div style={{ margin: "0rem .5rem" }} onClick={drawMarker} title={state.jobId <= 0 ? 'Your map needs to be saved first in order to add comments' : 'Drop Comment'}>
          <ActionIcon>
            <MessageDots
              size={25}
              strokeWidth={1.5}
              color={commentActive ? "#FF8E16" : "black"}
            />
          </ActionIcon>
        </div>
        <div style={{ margin: "0rem .5rem" }} onClick={drawRadialLine} title={'Radial Line tool'}>
          <ActionIcon>
            <ArrowLeftCircle
              size={25}
              strokeWidth={1.5}
              color={radialLineToolActive ? "#FF8E16" : "black"}
            />
          </ActionIcon>
        </div>
      </div>
    </Box>
  );
}

export default DrawingControlls;
