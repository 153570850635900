export function polygonValidOrRemove(polygon: any) {
  if(polygonHasTooFewPoints(polygon))
    return false;
  if(polygonSelfIntersecting(polygon))
    return false;
  return true;
}

function polygonHasTooFewPoints(polygon: any) {
  let path = polygon.getPath();
  let pathArray: any[] = [];
  if(path !== undefined)
    pathArray = path.getArray();
  else
    return false;
  if(pathArray.length < 3) {
    alert("Polygons need to have at least three points.");
    polygon.setMap(null);
    return true;
  }
  return false;
}

function polygonSelfIntersecting(polygon: any) {
  let path = polygon.getPath();
  let pathArray: any[] = [];
  if(path !== undefined)
    pathArray = path.getArray();
  else
    return false;
  if(pathArray.length <= 3)
    return false;

  let i, x1, y1, x2, y2, maxx1, minx1, maxy1, miny1, x21, y21, x22, y22, minx2, maxx2, maxy2, miny2, j, dx1, dx2, dxx, dy1, dy2, dyy, div, mul1, mul2 : number;
  for(i = 0; i < pathArray.length - 2; i++){
    x1 = pathArray[i].lat();
    y1 = pathArray[i].lng();
    x2 = pathArray[i + 1].lat();
    y2 = pathArray[i + 1].lng();

    maxx1 = Math.max(x1, x2);
    maxy1 = Math.max(y1, y2);
    minx1 = Math.min(x1, x2);
    miny1 = Math.min(y1, y2);

    for (j = i + 2; j < pathArray.length; j++) {
      x21 = pathArray[j].lat();
      y21 = pathArray[j].lng();
      x22 = pathArray[(j + 1) == pathArray.length ? 0 : (j + 1)].lat();
      y22 = pathArray[(j + 1) == pathArray.length ? 0 : (j + 1)].lng();

      maxx2 = Math.max(x21, x22);
      maxy2 = Math.max(y21, y22);
      minx2 = Math.min(x21, x22);
      miny2 = Math.min(y21, y22);

      if ((x1 == x21 && y1 == y21) || (x2 == x22 && y2 == y22) || (x1 == x22 && y1 == y22) || (x2 == x21 && y2 == y21))
        continue;

      if (minx1 > maxx2 || maxx1 < minx2 || miny1 > maxy2 || maxy1 < miny2)
        continue;  // The moment when the lines have one common vertex...


      dx1 = x2-x1;
      dy1 = y2-y1; // The length of the projections of the first line on the x and y axes
      dx2 = x22-x21;
      dy2 = y22-y21; // The length of the projections of the second line on the x and y axes
      dxx = x1-x21;
      dyy = y1-y21;

      div = dy2 * dx1 - dx2 * dy1;
      mul1 = dx1 * dyy - dy1 * dxx;
      mul2 = dx2 * dyy - dy2 * dxx;

      if (div == 0)
        continue; // Lines are parallel...

      if (div > 0) {
        if (mul1 < 0 || mul1 > div)
          continue; // The first segment intersects beyond its boundaries...
        if (mul2 < 0 || mul2 > div)
          continue; // // The second segment intersects beyond its borders...
      }
      else{
        if (-mul1 < 0 || -mul1 > -div)
          continue; // The first segment intersects beyond its boundaries...
        if (-mul2 < 0 || -mul2 > -div)
          continue; // The second segment intersects beyond its borders...
      }
      alert("Polygon is self-intersecting!");
      polygon.setMap(null);
      return true;
    }
  }
  return false;
}