type CriteriasType = {
  [key: string]: string;
};
let infoWindows: google.maps.InfoWindow[] = [];

let Criterias: CriteriasType = {
  females: "Female Population",
  males: "Male Population",
  medianAge: "Median Age",
  income: "Weekly household income",
  unemployed: "Unemployment rate",
  uniDegree: "People with uni degree",
  unitPercentage: "Apartments proportion of dwellings",
  rentalProperties: "Proportion of rental dwellings",
  ownedOutright: "Dwellings owned outright",
  ownedWithMortgage: "Dwellings owned with a mortgage",
  indigenous: "Indigenous",
};
let CriteriasPrefix: CriteriasType = {
  females: "",
  males: "",
  medianAge: "",
  income: "$",
  unemployed: "",
  uniDegree: "",
  unitPercentage: "",
  rentalProperties: "",
  ownedOutright: "",
  ownedWithMortgage: "",
  indigenous: "",
};
let CriteriasPostfix: CriteriasType = {
  females: "%",
  males: "%",
  medianAge: "",
  income: "",
  unemployed: "%",
  uniDegree: "%",
  unitPercentage: "%",
  rentalProperties: "%",
  ownedOutright: "%",
  ownedWithMortgage: "%",
  indigenous: "%",
};

export const setHeatMap = (mapPolygons: any, heatmapValue: any, languages: any[], methodsOfTravel: any[]) => {
  let dimensionValues: any[] = [];
  let polygonsToChange: any[] = [];
  let title = "";
  let prefix = CriteriasPrefix[heatmapValue] || "";
  let postfix = CriteriasPostfix[heatmapValue] || "";


  if(heatmapValue in Criterias)
    title = Criterias[heatmapValue];
  if(prefix === "" && postfix === "") {
    languages.forEach((language: any) => {
      if(heatmapValue == language.name) {
        postfix = "%";
        title = `${heatmapValue} Speakers`;
      }
    });
  }
  if(prefix === "" && postfix === "") {
    methodsOfTravel.forEach((travelMethod: any) => {
      if(heatmapValue == travelMethod.name) {
        postfix = "%";
        title = `${heatmapValue}`;
      }
    });
  }

  mapPolygons.forEach((item: any) => {
    const { polygon } = item;

    if (typeof polygon.getPath() === "undefined") return;

    if (heatmapValue !== "") {
      let html = `<div><p class="value-p">${title} - ${prefix}${item.data[
        heatmapValue
      ]}${postfix}</p></div>`;

      if(item.data["fullData"] == false && item.data[heatmapValue] == 0)
        html = `<div><p class="value-p">This area is missing demographic data.</p></div>`;

      let polygonBounds = new google.maps.LatLngBounds();
      for (let i = 0; i < polygon.getPath().getLength(); i++) {
        polygonBounds.extend(polygon.getPath().getAt(i));
      }
      polygon.infoWindow = new google.maps.InfoWindow({
        content: html,
        ariaLabel: "sa1s-infowindow",
      });
      infoWindows.push(polygon.infoWindow);
      polygon.addListener("mouseover", function () {
        infoWindows.forEach(function (iw) {
          iw.close();
        });
        let latLng = polygonBounds.getCenter();
        polygon.infoWindow?.setPosition(latLng);
        polygon.infoWindow?.open(polygon.map);
      });
      polygon.addListener("mouseout", function () {
        infoWindows.forEach(function (iw) {
          iw.close();
        });
      });
    }

    if (item.data[heatmapValue] !== 0) {
      dimensionValues.push(item.data[heatmapValue]);
      polygonsToChange.push(item.polygon);
    } else {
      item.polygon.setOptions({ fillColor: "#ffffff" });
    }
  });

  let maximum = dimensionValues?.reduce((a, b) => {
    return Math.max(a, b);
  }, -Infinity);
  let minimum = dimensionValues.reduce((a, b) => {
    return Math.min(a, b);
  }, Infinity);
  let range = maximum - minimum;

  for (let i = 0; i < dimensionValues.length; i++) {
    let placeInRange =
      255 - Math.round(((dimensionValues[i] - minimum) * 255) / range);

    let gbColorValues = placeInRange.toString(16);
    if (gbColorValues.length === 1) gbColorValues = "0" + gbColorValues;
    let rgbColor = heatmapValue
      ? "#ff" + gbColorValues + gbColorValues
      : "#ff1010";

    polygonsToChange[i].setOptions({ fillColor: rgbColor });
  }
};
