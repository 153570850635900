/* eslint-disable eqeqeq */
import axios from "axios";
import ExportMapComponent from "../components/report-export-components/ExportMapComponents";
import { downloadAsBase64, downloadAsImage } from "../components/report-export-components/ComponentExporter";
import React, { useContext, useState } from 'react';
import { createRoot } from "react-dom/client";
import storeContext from "../store/store";

type mapProps = {
  polygonList: any;
  paddingPercent?: Array<number>;
  context?: any;
};

const getMapSA1CSV = (context : {state:any, dispatch:any}) => {
  const { state, dispatch } = context;
  const sa1LevelRawData = getSA1LevelRawData(state);
  const rawData = getRawData(state);
  var dataText = '';
  dataText = 'Summary Data\n';
  dataText += 'Powered by Scopomap\n';
  dataText += 'SA1 Code,SA1 on Scopomap,Population Included,Median Age,Weekly Household Income,Unemployment Percentage,People with University Degree,Indigenous Population,Apartments Percentage,Rental Properties Percentage,Dwellings Owner Outright Percentage,Dwellings Owned with Mortgage Percentage,' + state.summaryData.languages[0].name + ',' + state.summaryData.languages[1].name + ',' + state.summaryData.languages[2].name + ',' + state.summaryData.methodsOfTravel[0].name + ',' + state.summaryData.methodsOfTravel[1].name + ',' + state.summaryData.methodsOfTravel[2].name + ',' + state.summaryData.methodsOfTravel[3].name + ',' + state.summaryData.methodsOfTravel[4].name + '\n';
  sa1LevelRawData.forEach((sa1: any) => {
    dataText += sa1.sa1_code + ',https://app.scopomap.com.au/SA1/' + sa1.sa1_code + ',' + sa1.population_count.toString() + ',' + sa1.medianAge.toString() + ',' + sa1.income.toString() + ',' + sa1.unemployed.toString() + ',' + sa1.uniDegree.toString()
      + ',' + sa1.indigenous.toString() + ',' + sa1.unitPercentage.toString() + ',' + sa1.rentalProperties.toString() + ',' + sa1.ownedOutright.toString() + ',' + sa1.ownedWithMortgage.toString()
      + ',' + (sa1[state.summaryData.languages[0].name] || 0).toString() + ',' + (sa1[state.summaryData.languages[1].name] || 0).toString() + ',' + (sa1[state.summaryData.languages[2].name] || 0).toString()
      + ',' + (sa1[state.summaryData.methodsOfTravel[0].name] || 0).toString() + ',' + (sa1[state.summaryData.methodsOfTravel[1].name] || 0).toString() + ',' + (sa1[state.summaryData.methodsOfTravel[2].name] || 0).toString() + ',' + (sa1[state.summaryData.methodsOfTravel[3].name] || 0).toString() + ',' + (sa1[state.summaryData.methodsOfTravel[4].name] || 0).toString() + '\n';
  });
  dataText += 'Area,' + rawData.population.toString() + ',' + rawData.medianAge.area + ',' + rawData.weeklyHouseholdIncome.area.replace('$', '') + ',' + rawData.unemployment.area.replace('%', '') + ',' + rawData.uniDegree.area.replace('%', '')
  + ',' + rawData.indigenous.area.replace('%', '') + ',' + rawData.apartments.area.replace('%', '') + ',' + rawData.rentals.area.replace('%', '') + ',' + rawData.ownedOutright.area.replace('%', '') + ',' + rawData.ownedWithMortgage.area.replace('%', '')
  + ',' + rawData.languages[0].area.replace('%', '') + ',' + rawData.languages[1].area.replace('%', '') + ',' + rawData.languages[2].area.replace('%', '')
  + ',' + rawData.travelToWork[0].area.replace('%', '') + ',' + rawData.travelToWork[1].area.replace('%', '') + ',' + rawData.travelToWork[2].area.replace('%', '') + ',' + rawData.travelToWork[3].area.replace('%', '') + ',' + rawData.travelToWork[4].area.replace('%', '') + '\n';
  dataText += 'State average,N/A,' + rawData.medianAge.stateAvg.replace('%', '') + ',' + rawData.weeklyHouseholdIncome.stateAvg.replace('$', '') + ',' + rawData.unemployment.stateAvg.replace('%', '') + ',' + rawData.uniDegree.stateAvg.replace('%', '')
    + ',' + rawData.indigenous.stateAvg.replace('%', '') + ',' + rawData.apartments.stateAvg.replace('%', '') + ',' + rawData.rentals.stateAvg.replace('%', '') + ',' + rawData.ownedOutright.stateAvg.replace('%', '') + ',' + rawData.ownedWithMortgage.stateAvg.replace('%', '')
    + ',' + rawData.languages[0].stateAvg.replace('%', '') + ',' + rawData.languages[1].stateAvg.replace('%', '') + ',' + rawData.languages[2].stateAvg.replace('%', '')
    + ',' + rawData.travelToWork[0].stateAvg.replace('%', '') + ',' + rawData.travelToWork[1].stateAvg.replace('%', '') + ',' + rawData.travelToWork[2].stateAvg.replace('%', '') + ',' + rawData.travelToWork[3].stateAvg.replace('%', '') + ',' + rawData.travelToWork[4].stateAvg.replace('%', '') + '\n';
  dataText += 'National average,N/A,' + rawData.medianAge.nationalAvg.replace('%', '') + ',' + rawData.weeklyHouseholdIncome.nationalAvg.replace('$', '') + ',' + rawData.unemployment.nationalAvg.replace('%', '') + ',' + rawData.uniDegree.nationalAvg.replace('%', '')
    + ',' + rawData.indigenous.nationalAvg.replace('%', '') + ',' + rawData.apartments.nationalAvg.replace('%', '') + ',' + rawData.rentals.nationalAvg.replace('%', '') + ',' + rawData.ownedOutright.nationalAvg.replace('%', '') + ',' + rawData.ownedWithMortgage.nationalAvg.replace('%', '')
    + ',' + rawData.languages[0].nationalAvg.replace('%', '') + ',' + rawData.languages[1].nationalAvg.replace('%', '') + ',' + rawData.languages[2].nationalAvg.replace('%', '')
    + ',' + rawData.travelToWork[0].nationalAvg.replace('%', '') + ',' + rawData.travelToWork[1].nationalAvg.replace('%', '') + ',' + rawData.travelToWork[2].nationalAvg.replace('%', '') + ',' + rawData.travelToWork[3].nationalAvg.replace('%', '') + ',' + rawData.travelToWork[4].nationalAvg.replace('%', '') + '\n';
return dataText;
}

const getMapSummaryCSV = (context : {state:any, dispatch:any}) => {
  const { state, dispatch } = context;
  const rawData = getRawData(state);
  var dataText = '';
  dataText = 'Summary Data\n';
  dataText += 'Powered by Scopomap\n';

  // Header
  dataText = 'Summary Data\n';
  dataText += 'Powered by Scopomap\n';
  dataText += 'Category,Area,State Average,State Comparison,National Average,National Comparison\n';

  // At a glance
  dataText += 'Residential dwellings' + ',' + rawData.residential.toString() + '\n';
  dataText += 'Businesses' + ',' + rawData.businesses.toString() + '\n';
  dataText += 'Letterboxes' + ',' + rawData.letterboxes.toString() + '\n';
  dataText += 'Population' + ',' + rawData.population.toString() + '\n';

  // People section
  dataText += 'Median age' + ',' + rawData.medianAge.area + ',' + rawData.medianAge.stateAvg + ',' + rawData.medianAge.stateDiff + ',' + rawData.medianAge.nationalAvg + ',' + rawData.medianAge.nationalDiff + '\n';
  dataText += 'Weekly household income' + ',' + rawData.weeklyHouseholdIncome.area + ',' + rawData.weeklyHouseholdIncome.stateAvg + ',' + rawData.weeklyHouseholdIncome.stateDiff + ',' + rawData.weeklyHouseholdIncome.nationalAvg + ',' + rawData.weeklyHouseholdIncome.nationalDiff + '\n';
  dataText += 'Unemployment' + ',' + rawData.unemployment.area + ',' + rawData.unemployment.stateAvg + ',' + rawData.unemployment.stateDiff + ',' + rawData.unemployment.nationalAvg + ',' + rawData.unemployment.nationalDiff + '\n';
  dataText += 'University degree' + ',' + rawData.uniDegree.area + ',' + rawData.uniDegree.stateAvg + ',' + rawData.uniDegree.stateDiff + ',' + rawData.uniDegree.nationalAvg + ',' + rawData.uniDegree.nationalDiff + '\n';
  dataText += 'Indigenous people' + ',' + rawData.indigenous.area + ',' + rawData.indigenous.stateAvg + ',' + rawData.indigenous.stateDiff + ',' + rawData.indigenous.nationalAvg + ',' + rawData.indigenous.nationalDiff + '\n';

  // Dwellings section
  dataText += 'Apartments' +  ',' + rawData.apartments.area + ',' + rawData.apartments.stateAvg + ',' + rawData.apartments.stateDiff + ',' + rawData.apartments.nationalAvg + ',' + rawData.apartments.nationalDiff + '\n';
  dataText += 'Rental properties' + ',' + rawData.rentals.area + ',' + rawData.rentals.stateAvg + ',' + rawData.rentals.stateDiff + ',' + rawData.rentals.nationalAvg + ',' + rawData.rentals.nationalDiff + '\n';
  dataText += 'Properties owned outright' + ',' + rawData.ownedOutright.area + ',' + rawData.ownedOutright.stateAvg + ',' + rawData.ownedOutright.stateDiff + ',' + rawData.ownedOutright.nationalAvg + ',' + rawData.ownedOutright.nationalDiff + '\n';
  dataText += 'Properties owned with a mortgage' + ',' + rawData.ownedWithMortgage.area + ',' + rawData.ownedWithMortgage.stateAvg + ',' + rawData.ownedWithMortgage.stateDiff + ',' + rawData.ownedWithMortgage.nationalAvg + ',' + rawData.ownedWithMortgage.nationalDiff + '\n';

  // Languages section
  dataText += rawData.languages[0].language + ' speakers' + ',' + rawData.languages[0].area + ',' + rawData.languages[0].stateAvg + ',' + rawData.languages[0].stateDiff + ',' + rawData.languages[0].nationalAvg + ',' + rawData.languages[0].nationalDiff + '\n';
  dataText += rawData.languages[1].language + ' speakers' + ',' + rawData.languages[1].area + ',' + rawData.languages[1].stateAvg + ',' + rawData.languages[1].stateDiff + ',' + rawData.languages[1].nationalAvg + ',' + rawData.languages[1].nationalDiff + '\n';
  dataText += rawData.languages[2].language + ' speakers' + ',' + rawData.languages[2].area + ',' + rawData.languages[2].stateAvg + ',' + rawData.languages[2].stateDiff + ',' + rawData.languages[2].nationalAvg + ',' + rawData.languages[2].nationalDiff + '\n';

  // Methods of travel to work section
  dataText += rawData.travelToWork[0].method + ' speakers' + ',' + rawData.travelToWork[0].area + ',' + rawData.travelToWork[0].stateAvg + ',' + rawData.travelToWork[0].stateDiff + ',' + rawData.travelToWork[0].nationalAvg + ',' + rawData.travelToWork[0].nationalDiff + '\n';
  dataText += rawData.travelToWork[1].method + ' speakers' + ',' + rawData.travelToWork[1].area + ',' + rawData.travelToWork[1].stateAvg + ',' + rawData.travelToWork[1].stateDiff + ',' + rawData.travelToWork[1].nationalAvg + ',' + rawData.travelToWork[1].nationalDiff + '\n';
  dataText += rawData.travelToWork[2].method + ' speakers' + ',' + rawData.travelToWork[2].area + ',' + rawData.travelToWork[2].stateAvg + ',' + rawData.travelToWork[2].stateDiff + ',' + rawData.travelToWork[2].nationalAvg + ',' + rawData.travelToWork[2].nationalDiff + '\n';
  dataText += rawData.travelToWork[3].method + ' speakers' + ',' + rawData.travelToWork[3].area + ',' + rawData.travelToWork[3].stateAvg + ',' + rawData.travelToWork[3].stateDiff + ',' + rawData.travelToWork[3].nationalAvg + ',' + rawData.travelToWork[3].nationalDiff + '\n';
  dataText += rawData.travelToWork[4].method + ' speakers' + ',' + rawData.travelToWork[4].area + ',' + rawData.travelToWork[4].stateAvg + ',' + rawData.travelToWork[4].stateDiff + ',' + rawData.travelToWork[4].nationalAvg + ',' + rawData.travelToWork[4].nationalDiff + '\n';
  return dataText;  
}

const downloadSummary = async (format: string, context : {state:any, dispatch:any} , user: any) => {

  // decompose context
  const { state, dispatch } = context;

  const rawData = getRawData(state);
  const sa1LevelRawData = getSA1LevelRawData(state);

  const properties = {
    // polygonList: state.mapPolygons,
    polygonList: state.polygons,
    paddingPercent: [25, 25, 25, 25],
    context: { state, dispatch },
  }

  let mapImageString = await downloadMapAsBase64(state, properties);

  var dataText = 'Summary Data\n';

  if (format == 'sa1s') {
    // Header
    dataText += 'Powered by Scopomap\n';
    dataText += 'SA1 Code,SA1 on Scopomap,Population Included,Median Age,Weekly Household Income,Unemployment Percentage,People with University Degree,Indigenous Population,Apartments Percentage,Rental Properties Percentage,Dwellings Owner Outright Percentage,Dwellings Owned with Mortgage Percentage,' + state.summaryData.languages[0].name + ',' + state.summaryData.languages[1].name + ',' + state.summaryData.languages[2].name + ',' + state.summaryData.methodsOfTravel[0].name + ',' + state.summaryData.methodsOfTravel[1].name + ',' + state.summaryData.methodsOfTravel[2].name + ',' + state.summaryData.methodsOfTravel[3].name + ',' + state.summaryData.methodsOfTravel[4].name + '\n';
    sa1LevelRawData.forEach((sa1: any) => {
      dataText += sa1.sa1_code + ',https://app.scopomap.com.au/SA1/' + sa1.sa1_code + ',' + sa1.population_count.toString() + ',' + sa1.medianAge.toString() + ',' + sa1.income.toString() + ',' + sa1.unemployed.toString() + ',' + sa1.uniDegree.toString()
        + ',' + sa1.indigenous.toString() + ',' + sa1.unitPercentage.toString() + ',' + sa1.rentalProperties.toString() + ',' + sa1.ownedOutright.toString() + ',' + sa1.ownedWithMortgage.toString()
        + ',' + (sa1[state.summaryData.languages[0].name] || 0).toString() + ',' + (sa1[state.summaryData.languages[1].name] || 0).toString() + ',' + (sa1[state.summaryData.languages[2].name] || 0).toString()
        + ',' + (sa1[state.summaryData.methodsOfTravel[0].name] || 0).toString() + ',' + (sa1[state.summaryData.methodsOfTravel[1].name] || 0).toString() + ',' + (sa1[state.summaryData.methodsOfTravel[2].name] || 0).toString() + ',' + (sa1[state.summaryData.methodsOfTravel[3].name] || 0).toString() + ',' + (sa1[state.summaryData.methodsOfTravel[4].name] || 0).toString() + '\n';
    });
    dataText += 'Area,' + rawData.population.toString() + ',' + rawData.medianAge.area + ',' + rawData.weeklyHouseholdIncome.area.replace('$', '') + ',' + rawData.unemployment.area.replace('%', '') + ',' + rawData.uniDegree.area.replace('%', '')
      + ',' + rawData.indigenous.area.replace('%', '') + ',' + rawData.apartments.area.replace('%', '') + ',' + rawData.rentals.area.replace('%', '') + ',' + rawData.ownedOutright.area.replace('%', '') + ',' + rawData.ownedWithMortgage.area.replace('%', '')
      + ',' + rawData.languages[0].area.replace('%', '') + ',' + rawData.languages[1].area.replace('%', '') + ',' + rawData.languages[2].area.replace('%', '')
      + ',' + rawData.travelToWork[0].area.replace('%', '') + ',' + rawData.travelToWork[1].area.replace('%', '') + ',' + rawData.travelToWork[2].area.replace('%', '') + ',' + rawData.travelToWork[3].area.replace('%', '') + ',' + rawData.travelToWork[4].area.replace('%', '') + '\n';
    dataText += 'State average,N/A,' + rawData.medianAge.stateAvg.replace('%', '') + ',' + rawData.weeklyHouseholdIncome.stateAvg.replace('$', '') + ',' + rawData.unemployment.stateAvg.replace('%', '') + ',' + rawData.uniDegree.stateAvg.replace('%', '')
      + ',' + rawData.indigenous.stateAvg.replace('%', '') + ',' + rawData.apartments.stateAvg.replace('%', '') + ',' + rawData.rentals.stateAvg.replace('%', '') + ',' + rawData.ownedOutright.stateAvg.replace('%', '') + ',' + rawData.ownedWithMortgage.stateAvg.replace('%', '')
      + ',' + rawData.languages[0].stateAvg.replace('%', '') + ',' + rawData.languages[1].stateAvg.replace('%', '') + ',' + rawData.languages[2].stateAvg.replace('%', '')
      + ',' + rawData.travelToWork[0].stateAvg.replace('%', '') + ',' + rawData.travelToWork[1].stateAvg.replace('%', '') + ',' + rawData.travelToWork[2].stateAvg.replace('%', '') + ',' + rawData.travelToWork[3].stateAvg.replace('%', '') + ',' + rawData.travelToWork[4].stateAvg.replace('%', '') + '\n';
    dataText += 'National average,N/A,' + rawData.medianAge.nationalAvg.replace('%', '') + ',' + rawData.weeklyHouseholdIncome.nationalAvg.replace('$', '') + ',' + rawData.unemployment.nationalAvg.replace('%', '') + ',' + rawData.uniDegree.nationalAvg.replace('%', '')
      + ',' + rawData.indigenous.nationalAvg.replace('%', '') + ',' + rawData.apartments.nationalAvg.replace('%', '') + ',' + rawData.rentals.nationalAvg.replace('%', '') + ',' + rawData.ownedOutright.nationalAvg.replace('%', '') + ',' + rawData.ownedWithMortgage.nationalAvg.replace('%', '')
      + ',' + rawData.languages[0].nationalAvg.replace('%', '') + ',' + rawData.languages[1].nationalAvg.replace('%', '') + ',' + rawData.languages[2].nationalAvg.replace('%', '')
      + ',' + rawData.travelToWork[0].nationalAvg.replace('%', '') + ',' + rawData.travelToWork[1].nationalAvg.replace('%', '') + ',' + rawData.travelToWork[2].nationalAvg.replace('%', '') + ',' + rawData.travelToWork[3].nationalAvg.replace('%', '') + ',' + rawData.travelToWork[4].nationalAvg.replace('%', '') + '\n';

    let filename = "SA1 summary.csv";
    var blob = new Blob([dataText], { type: "text/csv" });
    downloadDataAsFile(blob, filename);
    return;
  }

  var peopleCSV = '';
  var dwellingsCSV = '';
  var headerCSV = '';

  // Header
  dataText = 'Summary Data\n';
  dataText += 'Powered by Scopomap\n';
  headerCSV += format == 'text' ? '(Area, State Average, State Comparison, National Coparison, National Comparison)\n' : 'Category,Area,State Average,State Comparison,National Average,National Comparison\n';
  dataText += headerCSV;

  // At a glance
  dataText += 'Residential dwellings' + (format == 'text' ? ': ' : ',') + rawData.residential.toString() + '\n';
  dataText += 'Businesses' + (format == 'text' ? ': ' : ',') + rawData.businesses.toString() + '\n';
  dataText += 'Letterboxes' + (format == 'text' ? ': ' : ',') + rawData.letterboxes.toString() + '\n';
  dataText += 'Population' + (format == 'text' ? ': ' : ',') + rawData.population.toString() + '\n';

  // People section
  peopleCSV += 'Median age' + (format == 'text' ? ':\n' : ',') + rawData.medianAge.area + ',' + rawData.medianAge.stateAvg + ',' + rawData.medianAge.stateDiff + ',' + rawData.medianAge.nationalAvg + ',' + rawData.medianAge.nationalDiff + '\n';
  peopleCSV += 'Weekly household income' + (format == 'text' ? ':\n' : ',') + rawData.weeklyHouseholdIncome.area + ',' + rawData.weeklyHouseholdIncome.stateAvg + ',' + rawData.weeklyHouseholdIncome.stateDiff + ',' + rawData.weeklyHouseholdIncome.nationalAvg + ',' + rawData.weeklyHouseholdIncome.nationalDiff + '\n';
  peopleCSV += 'Unemployment' + (format == 'text' ? ':\n' : ',') + rawData.unemployment.area + ',' + rawData.unemployment.stateAvg + ',' + rawData.unemployment.stateDiff + ',' + rawData.unemployment.nationalAvg + ',' + rawData.unemployment.nationalDiff + '\n';
  peopleCSV += 'University degree' + (format == 'text' ? ':\n' : ',') + rawData.uniDegree.area + ',' + rawData.uniDegree.stateAvg + ',' + rawData.uniDegree.stateDiff + ',' + rawData.uniDegree.nationalAvg + ',' + rawData.uniDegree.nationalDiff + '\n';
  peopleCSV += 'Indigenous people' + (format == 'text' ? ':\n' : ',') + rawData.indigenous.area + ',' + rawData.indigenous.stateAvg + ',' + rawData.indigenous.stateDiff + ',' + rawData.indigenous.nationalAvg + ',' + rawData.indigenous.nationalDiff + '\n';
  dataText += peopleCSV;

  // Dwellings section
  dwellingsCSV += 'Apartments' + (format == 'text' ? ':\n' : ',') + rawData.apartments.area + ',' + rawData.apartments.stateAvg + ',' + rawData.apartments.stateDiff + ',' + rawData.apartments.nationalAvg + ',' + rawData.apartments.nationalDiff + '\n';
  dwellingsCSV += 'Rental properties' + (format == 'text' ? ':\n' : ',') + rawData.rentals.area + ',' + rawData.rentals.stateAvg + ',' + rawData.rentals.stateDiff + ',' + rawData.rentals.nationalAvg + ',' + rawData.rentals.nationalDiff + '\n';
  dwellingsCSV += 'Properties owned outright' + (format == 'text' ? ':\n' : ',') + rawData.ownedOutright.area + ',' + rawData.ownedOutright.stateAvg + ',' + rawData.ownedOutright.stateDiff + ',' + rawData.ownedOutright.nationalAvg + ',' + rawData.ownedOutright.nationalDiff + '\n';
  dwellingsCSV += 'Properties owned with a mortgage' + (format == 'text' ? ':\n' : ',') + rawData.ownedWithMortgage.area + ',' + rawData.ownedWithMortgage.stateAvg + ',' + rawData.ownedWithMortgage.stateDiff + ',' + rawData.ownedWithMortgage.nationalAvg + ',' + rawData.ownedWithMortgage.nationalDiff + '\n';
  dataText += dwellingsCSV;

  // Languages section
  dataText += rawData.languages[0].language + ' speakers' + (format == 'text' ? ':\n' : ',') + rawData.languages[0].area + ',' + rawData.languages[0].stateAvg + ',' + rawData.languages[0].stateDiff + ',' + rawData.languages[0].nationalAvg + ',' + rawData.languages[0].nationalDiff + '\n';
  dataText += rawData.languages[1].language + ' speakers' + (format == 'text' ? ':\n' : ',') + rawData.languages[1].area + ',' + rawData.languages[1].stateAvg + ',' + rawData.languages[1].stateDiff + ',' + rawData.languages[1].nationalAvg + ',' + rawData.languages[1].nationalDiff + '\n';
  dataText += rawData.languages[2].language + ' speakers' + (format == 'text' ? ':\n' : ',') + rawData.languages[2].area + ',' + rawData.languages[2].stateAvg + ',' + rawData.languages[2].stateDiff + ',' + rawData.languages[2].nationalAvg + ',' + rawData.languages[2].nationalDiff + '\n';

  // Methods of travel to work section
  dataText += rawData.travelToWork[0].method + ' speakers' + (format == 'text' ? ':\n' : ',') + rawData.travelToWork[0].area + ',' + rawData.travelToWork[0].stateAvg + ',' + rawData.travelToWork[0].stateDiff + ',' + rawData.travelToWork[0].nationalAvg + ',' + rawData.travelToWork[0].nationalDiff + '\n';
  dataText += rawData.travelToWork[1].method + ' speakers' + (format == 'text' ? ':\n' : ',') + rawData.travelToWork[1].area + ',' + rawData.travelToWork[1].stateAvg + ',' + rawData.travelToWork[1].stateDiff + ',' + rawData.travelToWork[1].nationalAvg + ',' + rawData.travelToWork[1].nationalDiff + '\n';
  dataText += rawData.travelToWork[2].method + ' speakers' + (format == 'text' ? ':\n' : ',') + rawData.travelToWork[2].area + ',' + rawData.travelToWork[2].stateAvg + ',' + rawData.travelToWork[2].stateDiff + ',' + rawData.travelToWork[2].nationalAvg + ',' + rawData.travelToWork[2].nationalDiff + '\n';
  dataText += rawData.travelToWork[3].method + ' speakers' + (format == 'text' ? ':\n' : ',') + rawData.travelToWork[3].area + ',' + rawData.travelToWork[3].stateAvg + ',' + rawData.travelToWork[3].stateDiff + ',' + rawData.travelToWork[3].nationalAvg + ',' + rawData.travelToWork[3].nationalDiff + '\n';
  dataText += rawData.travelToWork[4].method + ' speakers' + (format == 'text' ? ':\n' : ',') + rawData.travelToWork[4].area + ',' + rawData.travelToWork[4].stateAvg + ',' + rawData.travelToWork[4].stateDiff + ',' + rawData.travelToWork[4].nationalAvg + ',' + rawData.travelToWork[4].nationalDiff + '\n';

  if (format == 'Word') {
    let formData = new FormData();
    formData.append("raw_data", JSON.stringify(rawData));
    formData.append("csv_header", headerCSV);
    formData.append("csv_people_data", peopleCSV);
    formData.append("csv_dwellings_data", dwellingsCSV);
    formData.append("map_name", state.jobName);
    if(typeof mapImageString === "string")
      formData.append("map_image_string", mapImageString);
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}demographics/summary_report/`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `token ${user.key}`,
      },
      responseType: 'blob',
    });
    let data = response.data;
    let filename = "summary.docx";
    var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    downloadDataAsFile(blob, filename);
    return;
  }

  if (format == 'text') {
    return;
    let filename = "summary.txt";
    var blob = new Blob([dataText], { type: "text/csv" });
    downloadDataAsFile(blob, filename);
  }
  else if (format == 'CSV') {
    let filename = "summary.csv";
    var blob = new Blob([dataText], { type: "text/csv" });
    downloadDataAsFile(blob, filename);
  }
}


function downloadDataAsFile(blob: Blob, filename: string) {
  let navigator = window.navigator as any;
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    var elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

function getSA1LevelRawData(state: any) {
  let sa1s: any[] = [];
  state?.mapPolygons?.forEach((p: any) => {
    sa1s.push(p.data);
  });
  return sa1s;
}

function getRawData(state: any) {
  const rawData = {
    residential: state.letterBoxData?.residential_count || 0,
    businesses: state.businessesCount?.businesses_count || 0,
    letterboxes: state.letterBoxCountPAF?.letterbox_count_PAF,
    population: state.totalPopulation || 0,
    area: state.polygonsArea,

    medianAge: {
      area: state.summaryData?.median_age.toString(),
      stateDiff: state.summaryData?.median_age_diff_from_state.toString(),
      nationalDiff: state.summaryData?.median_age_diff_from_national.toString(),
      stateAvg: (state.summaryData?.median_age - state.summaryData?.median_age_diff_from_state).toString(),
      nationalAvg: (state.summaryData?.median_age - state.summaryData?.median_age_diff_from_national).toString(),
      stateComparisonSign: state.summaryData?.median_age_diff_from_state >= 0,
      nationalComparisonSign: state.summaryData?.median_age_diff_from_national >= 0,
    },
    weeklyHouseholdIncome: {
      area: '$' + state.summaryData?.weekly_household_income.toString(),
      stateDiff: '$' + state.summaryData?.median_income_diff_from_state.toString(),
      nationalDiff: '$' + state.summaryData?.median_income_diff_from_national.toString(),
      stateAvg: '$' + (state.summaryData?.weekly_household_income - state.summaryData?.median_income_diff_from_state).toString(),
      nationalAvg: '$' + (state.summaryData?.weekly_household_income - state.summaryData?.median_income_diff_from_national).toString(),
      stateComparisonSign: state.summaryData?.median_income_diff_from_state >= 0,
      nationalComparisonSign: state.summaryData?.median_income_diff_from_national >= 0,
    },
    unemployment: {
      area: (state.summaryData?.unemployment_for_area?.toFixed(2) || state.summaryData?.unemployment_percentage?.toFixed(2)).toString() + '%',
      stateDiff: state.summaryData?.unemployed_percentage_diff_from_state.toString() + '%',
      nationalDiff: state.summaryData?.unemployed_percentage_diff_from_national.toString() + '%',
      stateAvg: ((state.summaryData?.unemployment_for_area?.toFixed(2) || state.summaryData?.unemployment_percentage?.toFixed(2)) - state.summaryData?.unemployed_percentage_diff_from_state).toFixed(2).toString() + '%',
      nationalAvg: ((state.summaryData?.unemployment_for_area?.toFixed(2) || state.summaryData?.unemployment_percentage?.toFixed(2)) - state.summaryData?.unemployed_percentage_diff_from_national).toFixed(2).toString() + '%',
      stateComparisonSign: state.summaryData?.unemployed_percentage_diff_from_state >= 0,
      nationalComparisonSign: state.summaryData?.unemployed_percentage_diff_from_national >= 0,
    },
    uniDegree: {
      area: state.summaryData?.uni_degree_percentage.toString() + '%',
      stateDiff: state.summaryData?.uni_degree_percentage_diff_from_state.toString() + '%',
      nationalDiff: state.summaryData?.uni_degree_percentage_diff_from_national.toString() + '%',
      stateAvg: (state.summaryData?.uni_degree_percentage - state.summaryData?.uni_degree_percentage_diff_from_state).toFixed(2).toString() + '%',
      nationalAvg: (state.summaryData?.uni_degree_percentage - state.summaryData?.uni_degree_percentage_diff_from_national).toFixed(2).toString() + '%',
      stateComparisonSign: state.summaryData?.uni_degree_percentage_diff_from_state >= 0,
      nationalComparisonSign: state.summaryData?.uni_degree_percentage_diff_from_national >= 0,
    },
    indigenous: {
      area: state.summaryData?.percentage_aboriginals.toString() + '%',
      stateDiff: state.summaryData?.indigenous_diff_from_state.toString() + '%',
      nationalDiff: state.summaryData?.indigenous_diff_from_national.toString() + '%',
      stateAvg: (state.summaryData?.percentage_aboriginals - state.summaryData?.indigenous_diff_from_state).toFixed(2).toString() + '%',
      nationalAvg: (state.summaryData?.percentage_aboriginals - state.summaryData?.indigenous_diff_from_national).toFixed(2).toString() + '%',
      stateComparisonSign: state.summaryData?.indigenous_diff_from_state >= 0,
      nationalComparisonSign: state.summaryData?.indigenous_diff_from_national >= 0,
    },
    apartments: {
      area: state.summaryData?.percentage_appartments.toString() + '%',
      stateDiff: state.summaryData?.apartment_percentage_diff_from_state.toString() + '%',
      nationalDiff: state.summaryData?.apartment_percentage_diff_from_national.toString() + '%',
      stateAvg: (state.summaryData?.percentage_appartments - state.summaryData?.apartment_percentage_diff_from_state).toFixed(2).toString() + '%',
      nationalAvg: (state.summaryData?.percentage_appartments - state.summaryData?.apartment_percentage_diff_from_national).toFixed(2).toString() + '%',
      stateComparisonSign: state.summaryData?.apartment_percentage_diff_from_state >= 0,
      nationalComparisonSign: state.summaryData?.apartment_percentage_diff_from_national >= 0,
    },
    rentals: {
      area: state.summaryData?.percentage_rental.toString() + '%',
      stateDiff: state.summaryData?.rentals_diff_from_state.toString() + '%',
      nationalDiff: state.summaryData?.rentals_diff_from_national.toString() + '%',
      stateAvg: (state.summaryData?.percentage_rental - state.summaryData?.rentals_diff_from_state).toFixed(2).toString() + '%',
      nationalAvg: (state.summaryData?.percentage_rental - state.summaryData?.rentals_diff_from_national).toFixed(2).toString() + '%',
      stateComparisonSign: state.summaryData?.rentals_diff_from_state >= 0,
      nationalComparisonSign: state.summaryData?.rentals_diff_from_national >= 0,
    },
    ownedOutright: {
      area: state.summaryData?.owned_outright_percentage.toString() + '%',
      stateDiff: state.summaryData?.owned_outright_diff_from_state.toString() + '%',
      nationalDiff: state.summaryData?.owned_outright_diff_from_national.toString() + '%',
      stateAvg: (state.summaryData?.owned_outright_percentage - state.summaryData?.owned_outright_diff_from_state).toFixed(2).toString() + '%',
      nationalAvg: (state.summaryData?.owned_outright_percentage - state.summaryData?.owned_outright_diff_from_national).toFixed(2).toString() + '%',
      stateComparisonSign: state.summaryData?.owned_outright_diff_from_state >= 0,
      nationalComparisonSign: state.summaryData?.owned_outright_diff_from_national >= 0,
    },
    ownedWithMortgage: {
      area: state.summaryData?.owned_with_a_mortgage_percentage.toString() + '%',
      stateDiff: state.summaryData?.owned_with_a_mortgage_diff_from_state.toString() + '%',
      nationalDiff: state.summaryData?.owned_with_a_mortgage_diff_from_national.toString() + '%',
      stateAvg: (state.summaryData?.owned_with_a_mortgage_percentage - state.summaryData?.owned_with_a_mortgage_diff_from_state).toFixed(2).toString() + '%',
      nationalAvg: (state.summaryData?.owned_with_a_mortgage_percentage - state.summaryData?.owned_with_a_mortgage_diff_from_national).toFixed(2).toString() + '%',
      stateComparisonSign: state.summaryData?.owned_with_a_mortgage_diff_from_state >= 0,
      nationalComparisonSign: state.summaryData?.owned_with_a_mortgage_diff_from_national >= 0,
    },

    languages: [
      {
        language: state.summaryData.languages[0].name,
        area: state.summaryData.languages[0].aggregate?.toFixed(2).toString() + '%',
        stateDiff: state.summaryData.languages[0].state_average_diff.toString() + '%',
        nationalDiff: state.summaryData.languages[0].national_average_diff.toString() + '%',
        stateAvg: (state.summaryData.languages[0].aggregate?.toFixed(2) - state.summaryData.languages[0].state_average_diff).toFixed(2).toString() + '%',
        nationalAvg: (state.summaryData.languages[0].aggregate?.toFixed(2) - state.summaryData.languages[0].national_average_diff).toFixed(2).toString() + '%',
        stateComparisonSign: state.summaryData.languages[0].state_average_diff >= 0,
        nationalComparisonSign: state.summaryData.languages[0].national_average_diff >= 0,
      },
      {
        language: state.summaryData.languages[1].name,
        area: state.summaryData.languages[1].aggregate?.toFixed(2).toString() + '%',
        stateDiff: state.summaryData.languages[1].state_average_diff.toString() + '%',
        nationalDiff: state.summaryData.languages[1].national_average_diff.toString() + '%',
        stateAvg: (state.summaryData.languages[1].aggregate?.toFixed(2) - state.summaryData.languages[1].state_average_diff).toFixed(2).toString() + '%',
        nationalAvg: (state.summaryData.languages[1].aggregate?.toFixed(2) - state.summaryData.languages[1].national_average_diff).toFixed(2).toString() + '%',
        stateComparisonSign: state.summaryData.languages[1].state_average_diff >= 0,
        nationalComparisonSign: state.summaryData.languages[1].national_average_diff >= 0,
      },
      {
        language: state.summaryData.languages[2].name,
        area: state.summaryData.languages[2].aggregate?.toFixed(2).toString() + '%',
        stateDiff: state.summaryData.languages[2].state_average_diff.toString() + '%',
        nationalDiff: state.summaryData.languages[2].national_average_diff.toString() + '%',
        stateAvg: (state.summaryData.languages[2].aggregate?.toFixed(2) - state.summaryData.languages[2].state_average_diff).toFixed(2).toString() + '%',
        nationalAvg: (state.summaryData.languages[2].aggregate?.toFixed(2) - state.summaryData.languages[2].national_average_diff).toFixed(2).toString() + '%',
        stateComparisonSign: state.summaryData.languages[2].state_average_diff >= 0,
        nationalComparisonSign: state.summaryData.languages[2].national_average_diff >= 0,
      },
    ],

    travelToWork: [
      {
        method: state.summaryData.methodsOfTravel[0].name,
        area: state.summaryData.methodsOfTravel[0].aggregate?.toFixed(2).toString() + '%',
        stateDiff: state.summaryData.methodsOfTravel[0].state_average_diff.toString() + '%',
        nationalDiff: state.summaryData.methodsOfTravel[0].national_average_diff.toString() + '%',
        stateAvg: (state.summaryData.methodsOfTravel[0].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[0].state_average_diff).toFixed(2).toString() + '%',
        nationalAvg: (state.summaryData.methodsOfTravel[0].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[0].national_average_diff).toFixed(2).toString() + '%',
        stateComparisonSign: state.summaryData.methodsOfTravel[0].state_average_diff >= 0,
        nationalComparisonSign: state.summaryData.methodsOfTravel[0].national_average_diff >= 0,
      },
      {
        method: state.summaryData.methodsOfTravel[1].name,
        area: state.summaryData.methodsOfTravel[1].aggregate?.toFixed(2).toString() + '%',
        stateDiff: state.summaryData.methodsOfTravel[1].state_average_diff.toString() + '%',
        nationalDiff: state.summaryData.methodsOfTravel[1].national_average_diff.toString() + '%',
        stateAvg: (state.summaryData.methodsOfTravel[1].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[1].state_average_diff).toFixed(2).toString() + '%',
        nationalAvg: (state.summaryData.methodsOfTravel[1].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[1].national_average_diff).toFixed(2).toString() + '%',
        stateComparisonSign: state.summaryData.methodsOfTravel[1].state_average_diff >= 0,
        nationalComparisonSign: state.summaryData.methodsOfTravel[1].national_average_diff >= 0,
      },
      {
        method: state.summaryData.methodsOfTravel[2].name,
        area: state.summaryData.methodsOfTravel[2].aggregate?.toFixed(2).toString() + '%',
        stateDiff: state.summaryData.methodsOfTravel[2].state_average_diff.toString() + '%',
        nationalDiff: state.summaryData.methodsOfTravel[2].national_average_diff.toString() + '%',
        stateAvg: (state.summaryData.methodsOfTravel[2].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[2].state_average_diff).toFixed(2).toString() + '%',
        nationalAvg: (state.summaryData.methodsOfTravel[2].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[2].national_average_diff).toFixed(2).toString() + '%',
        stateComparisonSign: state.summaryData.methodsOfTravel[2].state_average_diff >= 0,
        nationalComparisonSign: state.summaryData.methodsOfTravel[2].national_average_diff >= 0,
      },
      {
        method: state.summaryData.methodsOfTravel[3].name,
        area: state.summaryData.methodsOfTravel[3].aggregate?.toFixed(2).toString() + '%',
        stateDiff: state.summaryData.methodsOfTravel[3].state_average_diff.toString() + '%',
        nationalDiff: state.summaryData.methodsOfTravel[3].national_average_diff.toString() + '%',
        stateAvg: (state.summaryData.methodsOfTravel[3].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[3].state_average_diff).toFixed(2).toString() + '%',
        nationalAvg: (state.summaryData.methodsOfTravel[3].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[3].national_average_diff).toFixed(2).toString() + '%',
        stateComparisonSign: state.summaryData.methodsOfTravel[3].state_average_diff >= 0,
        nationalComparisonSign: state.summaryData.methodsOfTravel[3].national_average_diff >= 0,
      },
      {
        method: state.summaryData.methodsOfTravel[4].name,
        area: state.summaryData.methodsOfTravel[4].aggregate?.toFixed(2).toString() + '%',
        stateDiff: state.summaryData.methodsOfTravel[4].state_average_diff.toString() + '%',
        nationalDiff: state.summaryData.methodsOfTravel[4].national_average_diff.toString() + '%',
        stateAvg: (state.summaryData.methodsOfTravel[4].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[4].state_average_diff).toFixed(2).toString() + '%',
        nationalAvg: (state.summaryData.methodsOfTravel[4].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[4].national_average_diff).toFixed(2).toString() + '%',
        stateComparisonSign: state.summaryData.methodsOfTravel[4].state_average_diff >= 0,
        nationalComparisonSign: state.summaryData.methodsOfTravel[4].national_average_diff >= 0,
      },
    ]
  }
  return rawData;
}

/**
 * 
 *  downloadMapAsBase64(yourExportMapComponentProps)
  *.then(base64String => {
  *  // Use the base64String for whatever you need here
  *  console.log(base64String);
  *})
  *.catch(error => {
  *  // Handle the error here
  *  console.error(error);
 * });
 * 
 * @param props 
 * @returns 
 */

const downloadMapAsBase64 = async (state: any, props: mapProps): Promise<string | null> => {
  // Create a container for rendering
  const container = document.createElement('div');
  const uniqueId = 'export-map-component-container-export';
  container.id = uniqueId;
  container.style.position = 'absolute';
  container.style.top = '-999px'; // Position offscreen
  container.style.left = '-999px';
  container.style.width = '1000px';
  container.style.height = '100%';
  document.body.appendChild(container);

  // Create a root for the container
  const root = createRoot(container);

  return new Promise<string | null>((resolve, reject) => {
    // Decompose props
    const { polygonList, paddingPercent, context } = props;

    // re-compose props with callback function
    const newProps = {
      polygonList,
      paddingPercent,
      context,
      callback: async (width: number, height: number) => {
        try {
          console.log("width: " + width + " height: " + height);
          // resize container/root to state.heatMapExportSize.height and state.heatMapExportSize.width
          document.getElementById(uniqueId)?.setAttribute('style', `width: ${width}px; height: ${height}px;`);

          // Convert the map to base64
          const base64String = await downloadAsBase64("exportmapComponent");
          resolve(base64String);
          // downloadAsImage("exportmapComponent", 'image'); // comment out in production
        } catch (error) {
          reject(error);
        } finally {
          // Cleanup after the operation is complete
          root.unmount();
          document.body.removeChild(container);
        }
      }
    };

    // Render the ExportMapComponent offscreen
    root.render(<ExportMapComponent {...newProps} />);
  });
};

export { downloadSummary, downloadMapAsBase64, getMapSA1CSV, getMapSummaryCSV };