import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  // Modal,
  Table,
} from "@mantine/core";
import { FC, useContext, useEffect, useState } from "react";
import axios from "axios";

import { downloadAddressListCSV } from "../map-utils/downloadAddressesListCSV";
import { fetchUserSubscriptionDetails } from "../map-utils/fetchUserSubscriptionDetails";
import storeContext from "../store/store";
// import AddressesListSkeleton from "./AddressesListSkeleton";
import { useAuth } from "../hooks/useAuth";

const useStyles = createStyles((theme) => ({
  tableMap: {
    width: "100%",
    overflow: "auto",
    // display: "none",
  },
  tableHead: {
    display: "block",
    th: {
      minWidth: "140px",
    },
  },
  tableBody: {
    maxHeight: "350px",
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
    display: "block",
    td: {
      minWidth: "140px",
    },
  },
  addressesModalheader: {
    backgroundColor: "#fd7e14",
    borderRadius: "8px",
    padding: "10px 10px !important",
  },
  title: {
    color: "white !important",
  },
  close: {
    color: "white",
    "&:hover": {
      backgroundColor: "white",
      color: theme.colors.allColors[7],
    },
  },
}));
interface AddressModalProps {
  opened: boolean;
  setAddressesModalOpened: () => void;
  setOpenedNavbar: () => void | undefined;
}

const AddressesModal: FC<AddressModalProps> = ({
  opened,
  setAddressesModalOpened,
  setOpenedNavbar,
}) => {
  const { classes } = useStyles();
  const { state } = useContext<any>(storeContext);
  const [addressesListLoading, setAddressesListLoading] = useState(false);
  const [addressesList, setAddressesList] = useState<any[]>([]);
  const [addressCount, setAddressCount] = useState(0);
  const { user } = useAuth();

  const [remainingAddressDownloads, setRemainingAddressDownloads] = useState<number>(0);

  const handleFetchUserSubscriptionDetails = () => {
    fetchUserSubscriptionDetails(user?.key)
      .then((response) => {
        setRemainingAddressDownloads(response.addresses_download_remaining);
      })
      .catch((err) =>
        console.log("error in fetching subscription details", err)
      );
  };

  useEffect(() => {
    handleFetchUserSubscriptionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.key]);



  const generateAddressList = () => {
    if (state.polygons.length === 0) {
      return;
    }
    let totalAddresses: any[] = [];
    let polygonsDone = 0;
    state.polygons.forEach((polygon: any) => {
      var path = polygon.getPath();
      let pathStr = "";
      for (var i = 0; i < path.length; i++) {
        var point1 = path.getAt(i);
        pathStr +=
          point1.lng().toFixed(6).toString() +
          " " +
          point1.lat().toFixed(6).toString() +
          ", ";
      }
      var point = path.getAt(0);
      pathStr +=
        point.lng().toFixed(6).toString() +
        " " +
        point.lat().toFixed(6).toString();

      let formData = new FormData();
      let source_from_paf: any = true;
      formData.append("geom_str", pathStr);
      formData.append("source_from_paf", source_from_paf);

      setAddressesListLoading(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}address_list/`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${user.key}`,
        },
      }).then((response: any) => {
        console.log(response.data);
        let data = response.data;
        const mappedData: any = [];
        if(data.address_count > 0) {
          setAddressCount(data.address_count);
          let newData = data.address_list.split("<br>");
          let addressesList = newData;

          addressesList.forEach((address: any) => {
            if(address.length == 0) return;
            const splitted = address.split(",");
            const [
              unit,
              fromStreetNo,
              toStreetNo,
              streetName,
              streetType,
              suburb,
              state,
              postCode,
              deliverMailIndicator,
              nonDeliveryReasonCode,
              advertisingMailIndicator,
              residentialIndicator,
              nonResidentialIndicator,
            ] = splitted;
            mappedData.push({
              unit,
              fromStreetNo,
              toStreetNo,
              streetName,
              streetType,
              suburb,
              state,
              postCode,
              deliverMailIndicator,
              nonDeliveryReasonCode,
              advertisingMailIndicator,
              residentialIndicator,
              nonResidentialIndicator,
            });
          });
        }
        totalAddresses.push(...mappedData);
        if(++polygonsDone === state.polygons.length) {
          setAddressesList(totalAddresses);
          setAddressesListLoading(false);
        }
      });
    });
  };

  // useEffect(() => {
  //   if (opened) {
  //     generateAddressList();
  //   }
  // }, [opened, generateAddressList]);

  const handleAddressesListCSV = () => {
    let formData = new FormData();
    formData.append("address_count", addressCount.toString());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}adderss_list_enter_download_record/`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `token ${user.key}`,
      },
    }).then((response: any) => {
        downloadAddressListCSV("addresses-list-table");
        setOpenedNavbar();
        setAddressesModalOpened();
    });
  };

  // table head JSX
  const ths = (
    <tr>
      <th>Unit</th>
      <th>Street Number (from)</th>
      <th>Street Number (to)</th>
      <th>Street Name</th>
      <th>Street Type</th>
      <th>Suburb</th>
      <th>State</th>
      <th>Post Code</th>
      <th>Deliver Mail Indicator</th>
      <th>Non Delivery Reason Code</th>
      <th>Advertising Mail Indicator</th>
      <th>Residential Indicator</th>
      <th>Non Residential Indicator</th>
    </tr>
  );

  const rows =
    addressesList.length &&
    addressesList.map((address: any, index: number) => (
      <tr key={index}>
        <td>{address.unit}</td>
        <td>{address.fromStreetNo}</td>
        <td>{address.toStreetNo}</td>
        <td>{address.streetName}</td>
        <td>{address.streetType}</td>
        <td>{address.suburb}</td>
        <td>{address.state}</td>
        <td>{address.postCode}</td>
        <td>{address.deliverMailIndicator}</td>
        <td>{address.nonDeliveryReasonCode}</td>
        <td>{address.advertisingMailIndicator}</td>
        <td>{address.residentialIndicator}</td>
        <td>{address.nonResidentialIndicator}</td>
      </tr>
    ));

  return (
    // <Modal
    //   opened={opened}
    //   onClose={() => setAddressesModalOpened()}
    //   title="Addresses List"
    //   size={addressesList.length ? "60%" : "40%"}
    //   classNames={{
    //     header: classes.addressesModalheader,
    //     close: classes.close,
    //     title: classes.title,
    //   }}
    // >
    <>
      {/* Modal content */}{" "}
      <LoadingOverlay
        visible={addressesListLoading}
        loaderProps={{ size: "xl", color: "orange" }}
        overlayOpacity={0.3}
      />
      {state.polygons.length ? (
        <>
          <div id="address-list-content" className={classes.tableMap}>
            <div>
              <p>
                This area has {state.letterBoxData?.residential_count + state.businessesCount?.businesses_count}{" "}
                addresses.
              </p>
              <p>
                You have a quota of {remainingAddressDownloads}{" "}
                addresses to download remaining.
              </p>
            </div>
            {/* {addressesListLoading && <AddressesListSkeleton />} */}
            {addressesList.length > 0 && (
              <Table
                verticalSpacing="xl"
                captionSide="bottom"
                mb="xl"
                mt="sm"
                pb="lg"
                id="addresses-list-table"
              >
                <thead className={classes.tableHead}>{ths}</thead>
                <tbody className={classes.tableBody}>{rows}</tbody>
              </Table>
            )}
          </div>
          {addressesList.length > 0 ? (
            <Group position="right">
              <Button
                disabled={addressesListLoading}
                onClick={handleAddressesListCSV}
                color={"orange"}
                variant="outline"
                mt="lg"
              >
                Download as CSV
              </Button>
            </Group>
          ) : (
            <>
              <Group position="right">
                <Button
                  // disabled={addressesListLoading || remainingAddressDownloads < state?.letterBoxCountPAF?.letterbox_count_PAF}
                  onClick={generateAddressList}
                  color={"orange"}
                  variant="outline"
                  mt="lg"
                >
                  {" "}
                  Show Addresses
                </Button>
                <Button
                  style={{ minWidth: "130px" }}
                  variant="outline"
                  onClick={() => setAddressesModalOpened()}
                  mt="lg"
                >
                  Cancel
                </Button>
              </Group>
            </>
          )}
        </>
      ) : (
        <div>
          <h3>No Addresses Found!</h3>
          <p>Please add circle/polygon to view addresses!</p>
        </div>
      )}
    </>
    // </Modal>
  );
};
export default AddressesModal;
