import axios from "axios";

export const fetchSa1sReport = async (areaPortions: any[]) => {
  try {
    if (areaPortions.length) {
      let data = new FormData();
      data.append("sa1_portions", JSON.stringify(areaPortions));
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}demographics/sa1s_report`,
        data
      );

      return response.data;
    }
  } catch (err) {
    console.log("Something went wrong", err);
  }
};
