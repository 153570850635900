// import { getPolygonClosureUid } from ".";
let mapPolygons: any[] = [];
export const loadSA1sOntoMap = (
  data: any,
  map: any,
  dispatch: any,
  state: any,
  polygonId: string | undefined,
  sortedLanguages?: {}[],
  sortedMethodsOfTravel?: {}[]
  // edited?: boolean | undefined
) => {
  let count: number = 0;
  let bounds = new google.maps.LatLngBounds();
  let splittedPolygons: any[] = [];
  let id: string = "";
  data.forEach((d: any) => {
    let coords: any[] = [];
    if (d.coords.length) {
      id = d.coords[0];
      d.coords.forEach((encodedPath: any) => {
        let singlePolygonPath =
          google.maps.geometry.encoding.decodePath(encodedPath);

        coords.push(singlePolygonPath);
        singlePolygonPath.forEach((latLng: any) => {
          bounds?.extend(latLng);
        });
      });
    }
    // const existed = edited
    //   ? mapPolygons.find(
    //       (p: any) => p.data.id === id && p.data.parentId !== polygonId
    //     )
    //   : mapPolygons.find((p: any) => p.data.id === id);

    // if (existed) {
    //   return;
    // }

    let newPolygon = new google.maps.Polygon({
      paths: coords,
      strokeColor: "#A9A9A9",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "green",
      fillOpacity: 0.7,
      // title: 'Polygon',
    });
    if (state.splitPolygonVisibility) newPolygon.setMap(map);
    else newPolygon.setMap(null);
    // map.fitBounds(bounds);
    // map.setCenter(bounds.getCenter());
    let topLanguages: any = [];
    let topMethodsOfTravel: any = [];

    let polygonData: any = {
      sa1_code: d.sa1_code,
      income: d.weekly_household_income,
      unitPercentage: parseFloat(d.appartment_percentage),
      medianAge: d.median_age,
      females: parseFloat(d.female_percentage),
      males: parseFloat(d.male_percentage),
      unemployed: parseFloat(d.unemployment_percentage),
      uniDegree: parseFloat(d.uni_degree_percentage),
      rentalProperties: parseFloat(d.rental_percentage),
      ownedOutright: parseFloat(d.owned_outright_percentage),
      ownedWithMortgage: parseFloat(d.owned_with_a_mortgage_percentage),
      indigenous: parseFloat(d.aboriginal_percentage),
      homeInternet: parseFloat(d.internet_accessed_percentage),
      italianSpeakers: parseFloat(d.Italian) || 0,
      greekSpeakers: parseFloat(d.Greek) || 0,
      population_count: d.population_count || 0,
      fullData: d.full_data || false,
      id: id,
      parentId: polygonId,
    };

    if(d.parent_id === polygonId)
      count = count + d.population_count;

    sortedLanguages &&
      sortedLanguages.forEach((language: any) => {
        let obj = {
          [language.name]: d[language.name],
        };
        topLanguages.push(obj);
        polygonData[language.name] = d[language.name];
        // let language1: any = (data.language.name = d[language.name]);
      });


    sortedMethodsOfTravel &&
      sortedMethodsOfTravel.forEach((travelMethod: any) => {
        let obj = {
          [travelMethod.name]: d[travelMethod.name],
        };
        topMethodsOfTravel.push(obj);
        polygonData[travelMethod.name] = d[travelMethod.name];
        // let language1: any = (data.language.name = d[language.name]);
      });


      let completedPolygon = {
      polygon: newPolygon,
      data: polygonData,
      selected: true,
      actively_unselected: false,
      intersected_percentage: d.intersected_percentage,
      parentId: polygonId,
    };
    splittedPolygons.push(completedPolygon);
    mapPolygons.push(completedPolygon);
  });
  // let unsplitPolygonId: number = getPolygonClosureUid(polygon);
  // dispatch({ type: "setMapPolygons", payload: mapPolygons });
  dispatch({
    type: "setTotalPopulation",
    payload: { unsplitPolygonId: polygonId, population_count: count },
  });
  dispatch({
    type: "setMapPolygons",
    payload: {
      unsplitPolygonId: polygonId,
      mapPolygons: splittedPolygons,
    },
  });
};
