import {
  Divider,
  Group,
  Navbar,
  Radio,
  Text,
  Title,
  Button,
  Col,
  Grid,
  Tooltip,
  Stack,
} from "@mantine/core";
import { FC, useContext, useEffect, useState } from "react";
import storeContext from "../store/store";
import { setHeatMap } from "../map-utils/setHeatMap";
import useElementSize from "../hooks/useElementSize";
import { stat } from "fs";

type Props = {
  openHeatMap?: boolean;
  openFiltersBar?: boolean;
};

const HeatMap: FC<Props> = ({ openHeatMap, openFiltersBar }: Props) => {
  const { state, dispatch } = useContext<any>(storeContext);
  const { languages, methodsOfTravel } = state.filterData;
  const { height } = useElementSize("header-main");
  const [valueSelected, setValueSelected] = useState(false);

  useEffect(() => {
    if (!openFiltersBar && openHeatMap && state.mapPolygons.length > 0) {
      setHeatMap(state.mapPolygons, state.heatmapValue, languages, methodsOfTravel);
    }
  }, [state.heatmapValue, openHeatMap, openFiltersBar, state.mapPolygons]);

  // set storage context for export map modal to visible
  const handleExport = () => {
    console.log('handleExport');
    dispatch({ type: "setViewExportMapModal", payload: { visible: true, type: "heat" } });
  }

  // return openHeatMap ? (
  return (
    <div style={openHeatMap ? {} : { display: "none" }}>
      <Navbar
        hiddenBreakpoint="sm"
        width={{ sm: 200, md: 270, lg: 300 }}
        fixed={false}
        sx={(theme) => ({
          height: `calc(100vh  - ${height}px)`,
          top: `${height}px)`,
          overflowY: "auto",
          overflowX: "hidden",
          [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            position: "fixed",
            top: `${height}px !important`,
            maxWidth: "300px",
          },
          [`@media (max-width: 425px)`]: {
            maxWidth: "250px",
          },
        })}
      >
        {state.filterData.setCheck ? (
          <>
            <Grid grow>
              <Col span={6}>
                <Title
                  order={6}
                  p="lg"
                  align="left"
                  style={{ textTransform: 'uppercase' }}>
                  Heatmap
                </Title>
              </Col>

              <Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Tooltip
                  label="Currently in Beta"
                  position="right"
                  withArrow
                >
                  <Button
                    variant="outline"
                    color="orange"
                    compact
                    type="submit"
                    disabled={!valueSelected}
                    onClick={handleExport}>
                    Export
                  </Button>
                </Tooltip>
              </Col>
            </Grid>

            <Divider />
            <Stack p="lg">
              <Text size="md">
                Please note - the heatmap is independent of any filters currently
                selected.
              </Text>
            </Stack>
            <Radio.Group
              onChange={(e) => {
                dispatch({ type: "setHeatmapValue", payload: e });
                setValueSelected(true);
              }}
              p="lg"
              pt={0}
              size="md"
              required
            >
              <Stack spacing="md">
              <Radio value="medianAge" label={`Median Age`} />
              <Radio value="income" label={`Weekly Household Income`} />
              <Radio value="unemployed" label={`Unemployment`} />
              <Radio value="uniDegree" label={`University Degree`} />
              <Radio
                value="unitPercentage"
                label={`Apartments`}
              />
              <Radio
                value="rentalProperties"
                label={`Rental Properties`}
              />
              <Radio
                value="ownedOutright"
                label={`Dwellings Owned Outright`}
              />
              <Radio
                value="ownedWithMortgage"
                label={`Dwellings Owned with Mortgage`}
              />
              <Radio
                value="indigenous"
                label={`Indigenous`}
              />
              {languages &&
                languages.length > 0 &&
                languages.map((language: any, index: number) => (
                  <Radio
                    key={index}
                    value={language.name}
                    label={`${language.name} Speakers`}
                  />
                ))}

              {methodsOfTravel &&
                methodsOfTravel.length > 0 &&
                methodsOfTravel.map((travelMethod: any, index: number) => (
                  <Radio
                    key={index}
                    value={travelMethod.name}
                    label={`${travelMethod.name}`}
                  />
                ))}
              </Stack>
            </Radio.Group>
          </>
        ) : (
          <>
            <Title
              order={6}
              p="lg"
              align="left"
              style={{ textTransform: "uppercase" }}
            >
              HeatMap
            </Title>
            <Divider />
            <Title
              order={5}
              p="lg"
              align="left"
              sx={(theme) => ({
                color: theme.colors.allColors[7],
              })}
              style={{ textTransform: "uppercase" }}
            >
              Please Draw Shape to see heatmap
            </Title>
          </>
        )}
      </Navbar>
    </div>
  );
};

export default HeatMap;
