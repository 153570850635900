import axios from "axios";

export const getPolygonSA1s = async (encodedPolygon: any, allPolygons: any) => {
  let formData = new FormData();
  formData.append("polygon_in_request", encodedPolygon);
  formData.append("all_polygons", allPolygons);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}demographics/sa1s_for_multiple_polygons`,
      formData
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
