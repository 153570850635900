function fetchData(state: any) {

    const averageData = {
        medianAge: {
            stateAvg: (state.summaryData?.median_age - state.summaryData?.median_age_diff_from_state),
            nationalAvg: (state.summaryData?.median_age - state.summaryData?.median_age_diff_from_national),
        },
        weeklyHouseholdIncome: {
            stateAvg: (state.summaryData?.weekly_household_income - state.summaryData?.median_income_diff_from_state),
            nationalAvg: (state.summaryData?.weekly_household_income - state.summaryData?.median_income_diff_from_national),
        },
        unemployment: {
            stateAvg: ((state.summaryData?.unemployment_for_area?.toFixed(2) || state.summaryData?.unemployment_percentage?.toFixed(2)) - state.summaryData?.unemployed_percentage_diff_from_state).toFixed(2),
            nationalAvg: ((state.summaryData?.unemployment_for_area?.toFixed(2) || state.summaryData?.unemployment_percentage?.toFixed(2)) - state.summaryData?.unemployed_percentage_diff_from_national).toFixed(2),
        },
        uniDegree: {
            stateAvg: (state.summaryData?.uni_degree_percentage - state.summaryData?.uni_degree_percentage_diff_from_state).toFixed(2),
            nationalAvg: (state.summaryData?.uni_degree_percentage - state.summaryData?.uni_degree_percentage_diff_from_national).toFixed(2),
        },
        indigenous: {
            stateAvg: (state.summaryData?.percentage_aboriginals - state.summaryData?.indigenous_diff_from_state).toFixed(2),
            nationalAvg: (state.summaryData?.percentage_aboriginals - state.summaryData?.indigenous_diff_from_national).toFixed(2),
        },
        apartments: {
            stateAvg: (state.summaryData?.percentage_appartments - state.summaryData?.apartment_percentage_diff_from_state).toFixed(2),
            nationalAvg: (state.summaryData?.percentage_appartments - state.summaryData?.apartment_percentage_diff_from_national).toFixed(2),
        },
        rentals: {
            stateAvg: (state.summaryData?.percentage_rental - state.summaryData?.rentals_diff_from_state).toFixed(2),
            nationalAvg: (state.summaryData?.percentage_rental - state.summaryData?.rentals_diff_from_national).toFixed(2),
        },
        ownedOutright: {
            stateAvg: (state.summaryData?.owned_outright_percentage - state.summaryData?.owned_outright_diff_from_state).toFixed(2),
            nationalAvg: (state.summaryData?.owned_outright_percentage - state.summaryData?.owned_outright_diff_from_national).toFixed(2),
        },
        ownedWithMortgage: {
            stateAvg: (state.summaryData?.owned_with_a_mortgage_percentage - state.summaryData?.owned_with_a_mortgage_diff_from_state).toFixed(2),
            nationalAvg: (state.summaryData?.owned_with_a_mortgage_percentage - state.summaryData?.owned_with_a_mortgage_diff_from_national).toFixed(2),
        },

        languages: [
            {
                stateAvg: (state.summaryData.languages[0].aggregate?.toFixed(2) - state.summaryData.languages[0].state_average_diff).toFixed(2),
                nationalAvg: (state.summaryData.languages[0].aggregate?.toFixed(2) - state.summaryData.languages[0].national_average_diff).toFixed(2),
                name: state.summaryData.languages[0].name
            },
            {
                stateAvg: (state.summaryData.languages[1].aggregate?.toFixed(2) - state.summaryData.languages[1].state_average_diff).toFixed(2),
                nationalAvg: (state.summaryData.languages[1].aggregate?.toFixed(2) - state.summaryData.languages[1].national_average_diff).toFixed(2),
                name: state.summaryData.languages[1].name
            },
            {
                stateAvg: (state.summaryData.languages[2].aggregate?.toFixed(2) - state.summaryData.languages[2].state_average_diff).toFixed(2),
                nationalAvg: (state.summaryData.languages[2].aggregate?.toFixed(2) - state.summaryData.languages[2].national_average_diff).toFixed(2),
                name: state.summaryData.languages[2].name
            },
        ],

        travelToWork: [
            {
                stateAvg: (state.summaryData.methodsOfTravel[0].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[0].state_average_diff).toFixed(2),
                nationalAvg: (state.summaryData.methodsOfTravel[0].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[0].national_average_diff).toFixed(2),
                name: state.summaryData.methodsOfTravel[0].name
            },
            {
                stateAvg: (state.summaryData.methodsOfTravel[1].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[1].state_average_diff).toFixed(2),
                nationalAvg: (state.summaryData.methodsOfTravel[1].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[1].national_average_diff).toFixed(2),
                name: state.summaryData.methodsOfTravel[1].name
            },
            {
                stateAvg: (state.summaryData.methodsOfTravel[2].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[2].state_average_diff).toFixed(2),
                nationalAvg: (state.summaryData.methodsOfTravel[2].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[2].national_average_diff).toFixed(2),
                name: state.summaryData.methodsOfTravel[2].name
            },
            {
                stateAvg: (state.summaryData.methodsOfTravel[3].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[3].state_average_diff).toFixed(2),
                nationalAvg: (state.summaryData.methodsOfTravel[3].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[3].national_average_diff).toFixed(2),
                name: state.summaryData.methodsOfTravel[3].name
            },
            {
                stateAvg: (state.summaryData.methodsOfTravel[4].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[4].state_average_diff).toFixed(2),
                nationalAvg: (state.summaryData.methodsOfTravel[4].aggregate?.toFixed(2) - state.summaryData.methodsOfTravel[4].national_average_diff).toFixed(2),
                name: state.summaryData.methodsOfTravel[4].name
            },
        ]
    }

    return averageData;
}

export default fetchData;